import React from "react";
import { RStatus } from "../../types";
import { AppErrorView } from "../AppErrorView/AppErrorView";
import { AppLoader } from "../AppLoader/AppLoader";

interface Props {
  status: RStatus;
  errorMessage?: string | null;
}

export const FetchingLayout = (props: React.PropsWithChildren<Props>) => {
  const { children, status, errorMessage } = props;
  return (
    <>
      {status === RStatus.Pending && <AppLoader />}
      {status === RStatus.Error && <AppErrorView message={errorMessage} />}
      {status === RStatus.Got && children}
    </>
  );
};
