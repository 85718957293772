import styles from "./CardBody.module.scss";

interface Props {
  className?: string;
  children: React.ReactNode;
}

export const CardBody: React.FC<Props> = ({ children, className }) => {
  return (
    <section className={className}>
      <div className={styles.infoContainer}>{children}</div>
    </section>
  );
};
