import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);
dayjs.extend(timezone);

export const formatTicksToTime = (ticks: number, timeZone?: string) => {
  const timeZoneDiff = getTimezoneDifferenceInHours(timeZone!, dayjs.tz.guess());
  const dayAsParsed = dayjs("2021-01-01").add(ticks, "minutes");
  const convertedToCurrentTimeZone = dayAsParsed.add(timeZoneDiff, "hours");
  return convertedToCurrentTimeZone.format("hh:mm A");
};

const getTimezoneDifferenceInHours = (timezone1: string, timezone2: string) => {
  const offset1 = dayjs().tz(timezone1).utcOffset();
  const offset2 = dayjs().tz(timezone2).utcOffset();

  return (offset2 - offset1) / 60; // Difference in hours
};
