import { EntityType } from "../../common/types";
import {
  AssetCompletionStatusName,
  CompletionStatusName,
  EventCompletionStatusName,
  PdfCompletionStatusName,
} from "../types";

export const getStatusNames = (contentType?: EntityType): CompletionStatusName => {
  switch (contentType) {
    case EntityType.Event:
      return EventCompletionStatusName;
    case EntityType.Pdf:
      return PdfCompletionStatusName;
    default:
      return AssetCompletionStatusName;
  }
};
