import classNames from "classnames/bind";
import { useTranslation } from "react-i18next";
import { Icon } from "semantic-ui-react";

import { useMemo } from "react";
import styles from "./SurveyEnd.module.scss";

const cx = classNames.bind(styles);

interface Props {
  endNoteText: string | null;
  children?: React.ReactNode;
}

export const SurveyEnd: React.FC<Props> = ({ endNoteText, children }) => {
  const { t } = useTranslation("assets");
  const getEndNoteText = useMemo(() => {
    return endNoteText || t("assessments.surveyEndText"); // NOSONAR
  }, [endNoteText, t]);
  return (
    <section className={styles.root}>
      <Icon className={cx(styles.icon, "smile")} />
      <p className={styles.text}>{getEndNoteText}</p>
      {children}
    </section>
  );
};
