import { faBuildings, faCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames/bind";
import { useSelector } from "react-redux";
import { Icon } from "semantic-ui-react";
import { selectAccount } from "../../redux/account/accountSlice";
import styles from "./AccountCompany.module.scss";

const cx = classNames.bind(styles);

interface Props {
  companyClassName?: string;
  simple?: boolean;
  showIcon?: boolean;
  useSmallerCompanyIcon?: boolean;
}

export const AccountCompany: React.FC<Props> = ({ companyClassName, simple, showIcon, useSmallerCompanyIcon }) => {
  const account = useSelector(selectAccount);

  return (
    <>
      {account?.logoUri ? (
        <img
          src={account?.logoUri}
          className={cx(styles.companyLogo, { [styles.logoSpacing]: !simple })}
          alt="company-logo"
        />
      ) : (
        <span
          className={cx("fa-stack", styles.stackedIcon, {
            [styles.logoSpacing]: !simple,
            [styles.smaller]: useSmallerCompanyIcon,
          })}
        >
          <FontAwesomeIcon className={cx("fa-stack-2x", styles.icon)} icon={faCircle} style={{ color: "#686569" }} />
          <FontAwesomeIcon className={cx("fa-stack-1x fa-inverse")} icon={faBuildings} />
        </span>
      )}
      {!simple && (
        <div className={styles.companyInfo}>
          <div className={cx(styles.companyName, companyClassName)}>{account?.name}</div>
        </div>
      )}
      {showIcon && (
        <span className={styles.iconSpacer}>
          <Icon className={styles.icon} name="angle down" fitted />
        </span>
      )}
    </>
  );
};
