import React from "react";

import styles from "./NoMembersText.module.scss";

export interface Props {
  text: string;
}

export const NoMembersText: React.FC<Props> = ({ text }) => {
  return <div className={styles.root}>{text}</div>;
};
