import React from "react";
import ReactDOM from "react-dom";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { store } from "./features/Application/store/store";
import AuthRoot from "./features/Auth/components/AuthRoot/AuthRoot";
import reportWebVitals from "./reportWebVitals";

import ReactGA from "react-ga4";
import initDatadog from "./tools/datadog/init";

import "./localization/i18n";

import { BrowserRouter } from "react-router-dom";
import "./assets/fomantic/dist/semantic.css";

import "./index.scss";

if (process.env.NODE_ENV !== "production") {
  const axe = require("@axe-core/react");
  axe(React, ReactDOM, 1000, {
    rules: [
      {
        id: "color-contrast",
        enabled: false,
      },
    ],
  });
}

ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID as string);

if (process.env.NODE_ENV === "production") {
  initDatadog();
}

const App = () => (
  <Provider store={store}>
    <BrowserRouter>
      <AuthRoot />
    </BrowserRouter>
  </Provider>
);

const container = document.getElementById("root");
const root = createRoot(container as HTMLElement);

root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
