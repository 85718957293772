import videojs from "video.js";
import { VideoJsControlOptions } from "./VideoJsControlButton";
import { CustomVjsComponentNames } from "./customVjsComponentNames";

const Plugin = videojs.getPlugin("plugin") as any;

class NextButtonPlugin extends Plugin {
  constructor(player: any, options: VideoJsControlOptions) {
    //@ts-ignore
    super(player, options);
    player.ready(() => {
      player.controlBar.addChild(CustomVjsComponentNames.controlButton, {
        className: "vjs-play-next-button",
        ...options,
      });
    });
  }
}

export default NextButtonPlugin;
