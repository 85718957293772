import classNames from "classnames";
import { AssetCard } from "features/Application/components/Card/Assets/AssetCard/AssetCard";
import { CardSessionStatus } from "features/Application/components/Card/CardSessionStatus/CardSessionStatus";
import { RStatus } from "features/Application/types";
import { queryStringService } from "features/Application/utils/queryStringService";
import { Carousel } from "features/Home/components/Carousel/Carousel";
import { req, reset } from "features/Home/redux/homePageContent/homeUpcomingEventsSlice";
import { selectUpcomingEventsState } from "features/Home/redux/homePageContent/selectors";
import { UpcomingEvent } from "features/Home/types";
import { Routes } from "features/common/routes";
import { EntityType } from "features/common/types";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import styles from "./UpcomingEvents.module.scss";

const cx = classNames.bind(styles);

const emptyEvents = Array(5).fill({} as UpcomingEvent);

const eventsFilter: any = { appliedFilters: { entityType: [EntityType.Event] } };

export const UpcomingEvents: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation(["application"]);
  const eventsState = useSelector(selectUpcomingEventsState);
  const events = eventsState.status === RStatus.Got ? eventsState.items : emptyEvents;

  useEffect(() => {
    dispatch(req());
  }, [dispatch]);

  useEffect(() => {
    return () => {
      dispatch(reset());
    };
  }, [dispatch]);

  if (eventsState.status === RStatus.Error || (eventsState.status === RStatus.Got && eventsState.items.length === 0)) {
    return null;
  }
  return (
    <div className={cx(styles.root)}>
      <Carousel
        items={events}
        title="Upcoming Event Sessions"
        renderCard={item => {
          return (
            <AssetCard
              loading={eventsState.status === RStatus.Pending}
              item={item}
              to={`${Routes.Discover.event}/${item.id}`}
              extraMeta={<CardSessionStatus isRegistered={item.isRegistered} />}
            />
          );
        }}
      />
      <Link
        to={`${Routes.Discover.exact}?${queryStringService.stringify(eventsFilter)}`}
        className={cx(styles.viewAll)}
      >
        {t("common.viewAll")}
      </Link>
    </div>
  );
};
