import React, { PropsWithChildren, useMemo } from "react";
import { Message } from "../../components/Message/Message";
import { Trans, useTranslation } from "react-i18next";
import HttpCodes from "../../../types/HttpCodes";
import { TFunction } from "i18next";
import { useBackNavigation } from "../../../common/hooks/useBackNavigation";
import { Routes } from "../../../common/routes";

export enum ErrorType {
  None,
  Default,
  Forbidden,
  NotFound,
  Unavailable,
}

interface Props {
  className?: string;
  back?: boolean;
  code?: HttpCodes | ErrorType;
  message?: string;
  action?: () => void;
}

const messages: { [k in ErrorType]?: (t: TFunction) => React.ReactNode } = {
  [ErrorType.Forbidden]: (t: TFunction) => <Trans i18nKey={"httpError.restricted"} t={t} components={{ b: <b /> }} />,
  [ErrorType.Unavailable]: (t: TFunction) => (
    <Trans i18nKey={"httpError.unavailable"} t={t} components={{ b: <b /> }} />
  ),
};

export const HttpErrorView = (props: PropsWithChildren<Props>) => {
  const { code, className, children, back, message, action } = props;
  const errorCode = getErrorCode(code);
  const { t } = useTranslation("application");
  const navigateBack = useBackNavigation(Routes.Discover.exact);

  const body = useMemo(() => {
    return (
      <Message
        type="error"
        message={message || (errorCode && messages[errorCode]?.(t)) || <Trans i18nKey={"httpError.default"} t={t} />}
        action={getErrorAction(navigateBack, t, back, action)}
      />
    );
  }, [errorCode, t, navigateBack, back, message, action]);

  if (!errorCode) {
    return <>{children}</>;
  }

  return <div className={className}>{body}</div>;
};

const codesMap: { [code in HttpCodes]?: ErrorType } = {
  [HttpCodes.Forbidden]: ErrorType.Forbidden,
  [HttpCodes.NotFound]: ErrorType.NotFound,
};

const getErrorCode = (code?: HttpCodes | ErrorType): ErrorType | undefined => {
  if (!code) return undefined;
  if (code in ErrorType) return code as ErrorType;
  if (code >= 400) {
    return codesMap[code as HttpCodes] ?? ErrorType.Default;
  }
  return undefined;
};

const getErrorAction = (navigateBack: () => void, t: any, back?: boolean, action?: () => void) => {
  if (back) return { onClick: navigateBack, text: t("common.goBack") };
  if (action) return { onClick: action, text: t("common.continue") };
  return undefined;
};
