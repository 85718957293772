import { useCallback, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Icon, Popup } from "semantic-ui-react";

import { AppErrorView, AppLoader, Button, InvisibleButton } from "../../../Application/layout";
import { ConfirmationModal } from "../../../Discover/layout/ConfirmationModal/ConfirmationModal";
import { ProfileFooter } from "../ProfileFooter/ProfileFooter";

import defaultUserAvatar from "../../../../assets/images/default-user-avatar.png";
import { RStatus } from "../../../Application/types";
import {
  cancel,
  selectCurrentProfile,
  selectProfileDataChanged,
  selectProfileError,
  selectProfileOriginalImage,
  selectProfileSaving,
  selectProfileStatus,
  setImage,
  updateImage,
} from "../../redux/profile/profileSlice";

import styles from "./MyInfo.module.scss";

export const MyInfo: React.FC = () => {
  const { t } = useTranslation("account");
  const imageRef = useRef<HTMLImageElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [modalOpen, setModalOpen] = useState(false);

  const dispatch = useDispatch();
  const profile = useSelector(selectCurrentProfile);
  const profileError = useSelector(selectProfileError);
  const status = useSelector(selectProfileStatus);
  const originalImage = useSelector(selectProfileOriginalImage);
  const dataChanged = useSelector(selectProfileDataChanged);
  const saving = useSelector(selectProfileSaving);

  const cancelChanges = useCallback(() => {
    dispatch(cancel());
  }, [dispatch]);

  const saveChanges = useCallback(() => {
    dispatch(updateImage());
  }, [dispatch]);

  const closeModal = useCallback(() => {
    setModalOpen(false);
  }, []);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || e.target.files.length === 0) {
      return;
    }
    dispatch(setImage({ image: e.target.files[0] }));
  };

  const triggerFileInput = (e: React.KeyboardEvent<HTMLLabelElement>) => {
    if (e.key === "Enter") inputRef.current?.click();
  };

  const handleModalOpen = useCallback(() => {
    setModalOpen(true);
  }, []);

  const handleDeleteImage = useCallback(() => {
    dispatch(setImage({ image: null }));
    saveChanges();
    setModalOpen(false);
  }, [dispatch, saveChanges]);

  return (
    <>
      <div className={styles.root}>
        {status === RStatus.Pending && <AppLoader />}
        {status === RStatus.Error && <AppErrorView message={profileError} />}
        {status === RStatus.Got && profile && (
          <>
            <div className={styles.picture}>
              <img
                ref={imageRef}
                className={styles.circularImage}
                alt={`${profile.firstName} ${profile.lastName}`}
                src={profile.imageUrl ? profile.imageUrl : defaultUserAvatar}
              />
              <label className={styles.fileUpload} tabIndex={0} onKeyPress={triggerFileInput}>
                <input
                  ref={inputRef}
                  type="file"
                  onChange={handleFileChange}
                  accept="image/png, image/jpeg"
                  data-testid="file upload"
                />
                <Icon name="plus circle" size="large" />
                <span className={styles.uploadText}>
                  {profile?.imageUrl ? t("myInfo.replaceImage") : t("myInfo.uploadImage")}
                </span>
              </label>
              {originalImage && (
                <Popup
                  content={t("myInfo.deleteImage")}
                  position="top center"
                  mouseEnterDelay={100}
                  inverted
                  offset={[-4, 0]}
                  trigger={
                    <div className={styles.clearPhoto} data-testid="clear photo">
                      <InvisibleButton onClick={handleModalOpen} ariaLabel="Clear photo">
                        <Icon name="times circle" />
                      </InvisibleButton>
                    </div>
                  }
                />
              )}
            </div>
            <div className={styles.fields}>
              <div className={styles.label}>{t("myInfo.firstName")}</div>
              <div className={styles.inputWrapper}>
                <FakeInput text={profile.firstName} />
              </div>

              <div className={styles.label}>{t("myInfo.lastName")}</div>
              <div className={styles.inputWrapper}>
                <FakeInput text={profile.lastName} />
              </div>

              <div className={styles.jobTitleSpacing}>
                <div className={styles.label}>{t("myInfo.jobTitle")}</div>
                <div className={styles.inputWrapper}>
                  <FakeInput text={profile.title} />
                </div>
              </div>

              <div className={styles.label}>{t("myInfo.department")}</div>
              <div className={styles.inputWrapper}>
                <FakeInput text={profile.department} />
              </div>
            </div>
            <ProfileFooter
              enabled={dataChanged && !saving}
              onSave={saveChanges}
              onCancel={cancelChanges}
              saveText={saving ? t("profile.saving") : t("profile.saveChanges")}
              cancelText={t("profile.cancel")}
            />
          </>
        )}
      </div>
      <ConfirmationModal
        title={t("myInfo.deleteConfirmation")}
        body={t("myInfo.deleteConfirmationBody")}
        isOpen={modalOpen}
        handleClose={closeModal}
        confirmButton={<Button onClick={handleDeleteImage}>{t("myInfo.delete")}</Button>}
      />
    </>
  );
};

const FakeInput = ({ text }: { text: string }) => {
  return (
    <div className={styles.fakeInput}>
      <span className={styles.fakeInputInner}>{text}</span>
    </div>
  );
};
