export enum EntityType {
  Flow = "Flow",
  Group = "Group",
  Event = "ExternalEvent",
  UpcomingEvent = "UpcomingEvent",
  Asset = "Asset",
  Video = "Video",
  Pdf = "Pdf",
  Assessment = "Assessment",
  Message = "Message",
  Survey = "Survey",
  Html = "Html",
  Email = "Email",
}

export enum FlowEntityType {
  FlowEnd = "FlowEnd",
  UnavailableAsset = "UnavailableAsset",
}

export type FlowEntity = FlowEntityType | EntityType;
