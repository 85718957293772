import React from "react";
import styles from "./Segments.module.scss";

export interface Props {
  hideForSingleSegment?: boolean;
  children: React.ReactNode;
}

export const Segments: React.FC<Props> = ({ children, hideForSingleSegment = false }) => {
  return hideForSingleSegment && React.Children.count(children) < 2 ? null : (
    <div className={styles.segmentControl} data-testid="segments">
      {children}
    </div>
  );
};
