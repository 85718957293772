import { datadogRum } from "@datadog/browser-rum";
import config from "../../environmentConfig";

/* istanbul ignore next */
const init = () => {
  datadogRum.init({
    applicationId: "d238482f-a82a-461a-83d6-9db3bdc7c992",
    clientToken: "pubec5b47588058e43a70e403bb60110279",
    site: "us3.datadoghq.com",
    service: "saasy-user",
    env: config.env,
    // Specify a version number to identify the deployed version of your application in Datadog
    version: process.env.REACT_APP_VERSION ?? "default",
    trackFrustrations: true,
    sampleRate: 100,
    trackInteractions: true,
    defaultPrivacyLevel: "mask-user-input",
    allowedTracingOrigins: [config.apiUrl, config.stsUrl],
  });

  datadogRum.startSessionReplayRecording();
};

export default init;
