import { Route, Routes } from "react-router-dom";

import { AppAuth } from "../AppAuth/AppAuth";
import { SignoutCallback } from "../SignoutCallback/SignoutCallback";

const AuthRoot: React.FC = () => (
  <>
    <Routes>
      <Route path="/signout-callback" element={<SignoutCallback />} />
      <Route path="*" element={<AppAuth />} />
    </Routes>
  </>
);

export default AuthRoot;
