import { FunctionComponent, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { ErrorType, HttpErrorView } from "../../../Application/layout/HttpErrorView/HttpErrorView";
import { FixedHeightAppPageWrapper } from "../../../Application/layout";
import { FetchingLayout } from "../../../Application/layout/FetchingLayout/FetchingLayout";
import { SendPageView } from "../../../Application/services/realTimeNotification/googleAnalytics";
import {
  hasAccessSelector,
  req as reqExternalEventInfo,
  selectEvent,
  selectEventError,
  selectEventStatus,
} from "../../redux/event/eventSlice";
import { EventsPage } from "../EventsPage/EventsPage";
import styles from "./EventDetailsPage.module.scss";

interface EventDetailsPageProps {}

export const EventDetailsPage: FunctionComponent<EventDetailsPageProps> = () => {
  const { eventId } = useParams();
  const dispatch = useDispatch();

  const event = useSelector(selectEvent);
  const status = useSelector(selectEventStatus);
  const errorMessage = useSelector(selectEventError);
  const hasAccess = useSelector(hasAccessSelector);

  useEffect(() => {
    const id = Number(eventId);
    dispatch(reqExternalEventInfo({ id }));
  }, [dispatch, eventId]);

  useEffect(() => {
    if (event?.title) {
      document.title = event.title;
      SendPageView({
        title: "Event Details",
      });
    }
  }, [event?.title]);

  return (
    <FixedHeightAppPageWrapper>
      <FetchingLayout status={status} errorMessage={errorMessage}>
        <div className={styles.root}>
          {hasAccess && event ? <EventsPage event={event} /> : <HttpErrorView code={ErrorType.Forbidden} back />}
        </div>
      </FetchingLayout>
    </FixedHeightAppPageWrapper>
  );
};

export default EventDetailsPage;
