import { useEffect, useRef } from "react";

export enum SwipeDirection {
  Up,
  Down,
  Left,
  Right,
}

interface Props {
  handler: (dir: SwipeDirection) => void;
  children: React.ReactNode;
}

export const SwipeableArea: React.FC<Props> = ({ children, handler }) => {
  const ref = useRef<HTMLDivElement>(null);
  // Using "useRef" to reuse these values inside
  // of useEffect
  const xDown = useRef<number | null>(null);
  const yDown = useRef<number | null>(null);

  useEffect(() => {
    const refCopy = ref.current as HTMLDivElement;

    const handleTouchStart = (e: TouchEvent) => {
      const firstTouch = e.touches[0];
      xDown.current = firstTouch.clientX;
      yDown.current = firstTouch.clientY;
    };

    const handleTouchSwipe = (e: TouchEvent) => {
      if (xDown.current == null || yDown.current == null) return;

      const xUp = e.touches[0].clientX;
      const yUp = e.touches[0].clientY;

      const xDiff = xDown.current - xUp;
      const yDiff = yDown.current - yUp;

      // Is up/down or left/right more significant
      if (Math.abs(xDiff) > Math.abs(yDiff)) {
        if (xDiff > 0) {
          handler(SwipeDirection.Left);
        } else {
          handler(SwipeDirection.Right);
        }
      } else if (Math.abs(yDiff) > Math.abs(xDiff)) {
        if (yDiff > 0) {
          handler(SwipeDirection.Down);
        } else {
          handler(SwipeDirection.Up);
        }
      }

      xDown.current = null;
      yDown.current = null;
    };

    refCopy.addEventListener("touchstart", handleTouchStart, {
      passive: true,
    });
    refCopy.addEventListener("touchmove", handleTouchSwipe, {
      passive: true,
    });

    return () => {
      refCopy.removeEventListener("touchstart", handleTouchStart);
      refCopy.removeEventListener("touchmove", handleTouchSwipe);
    };
  }, [handler]);
  return <div ref={ref}>{children}</div>;
};
