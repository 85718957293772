import { Route, Routes } from "react-router-dom";
import { GroupDetails } from "../GroupDetails/GroupDetails";
import { GroupsPage } from "../GroupsPage/GroupsPage";

export const GroupPageRoutes = () => {
  return (
    <Routes>
      <Route path=":groupId/*" element={<GroupDetails />} />
      <Route index element={<GroupsPage />} />
    </Routes>
  );
};
