import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import environmentConfig from "../../../environmentConfig";

export default function addApplicationInsights() {
  const aiInstumentationKeyConfigPlaceholder = "#{ApplicationInsights--InstrumentationKey}#";
  const aiInstumentationKey = environmentConfig.aiInstrumentationKey;
  if (aiInstumentationKey && aiInstumentationKey !== aiInstumentationKeyConfigPlaceholder) {
    const appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: aiInstumentationKey,
      },
    });
    appInsights.loadAppInsights();
    appInsights.trackPageView();
    window.appInsights = appInsights;
  }
}
