import ReactGA from "react-ga4";

type PageViewParams = {
  title?: string;
  path?: string;
};

export function SendPageView(
  { title, path }: PageViewParams = {
    title: document.title,
    path: window.location.pathname,
  },
) {
  ReactGA.send({
    hitType: "pageview",
    title,
    page: path,
  });
}
