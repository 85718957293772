import classNames from "classnames/bind";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Icon } from "semantic-ui-react";
import { OnClickOutside } from "../../../Account/components/OnClickOutside/OnClickOutside";

import styles from "./Dropdown.module.scss";

const cx = classNames.bind(styles);

export type DropdownOption = {
  key: string;
  value: string | number;
  text: string;
};

export interface Props {
  options: DropdownOption[];
  defaultValue?: string | number;
  onChange?: (newValue: DropdownOption) => void;
  fluid?: boolean;
}

const findDefaultOption = (
  options: DropdownOption[],
  value: string | number | undefined,
): DropdownOption | undefined => {
  if (!value) {
    return undefined;
  }

  return options.find(o => o.value === value);
};

export const Dropdown: React.FC<Props> = ({ options, defaultValue, onChange, fluid }) => {
  const [selectedOption, setSelectedOption] = useState(findDefaultOption(options, defaultValue));
  const [expanded, setExpanded] = useState(false);
  const [openUpward, setOpenUpward] = useState(false);
  const [measured, setMeasured] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const optionsRef = useRef<HTMLDivElement>(null);

  const getHeight = useCallback((ele: Element) => {
    // Create a copy of our "display: none" element
    // for measuring
    const dup = document.importNode(ele, true);
    (dup as HTMLElement).style.position = "absolute";
    (dup as HTMLElement).style.left = "-10000px";
    (dup as HTMLElement).style.top = "-10000px";
    (dup as HTMLElement).style.display = "block";
    const appended = document.body.appendChild(dup);
    const measuredHeight = appended.clientHeight;
    document.body.removeChild(appended);
    return measuredHeight;
  }, []);

  // Which direction to open the dropdown (in case at bottom of screen)
  useEffect(() => {
    const root = ref.current;
    const optionsContainer = optionsRef.current;
    if (!root || !optionsContainer) return;
    const dropdownRect = root.getBoundingClientRect();
    const menuHeight = getHeight(optionsContainer);
    const spaceAtTheBottom =
      document.documentElement.clientHeight - dropdownRect.top - dropdownRect.height - menuHeight;
    const spaceAtTheTop = dropdownRect.top - menuHeight;
    const upward = spaceAtTheBottom < 0 && spaceAtTheTop > spaceAtTheBottom;
    setOpenUpward(upward);
    setMeasured(true);
  }, [expanded, getHeight]);

  const toggleOpen = useCallback(() => {
    setExpanded(e => !e);
    setMeasured(false);
  }, []);

  const selectOption = useCallback(
    (option: DropdownOption) => {
      setSelectedOption(option);
      toggleOpen();
      ref.current?.focus();
      onChange?.(option);
    },
    [toggleOpen, onChange],
  );

  const onEnterPress = (e: React.KeyboardEvent, callback: () => void) => {
    if (e.key === "Enter") {
      e.stopPropagation();
      callback();
    }
  };

  const handleClickOutside = useCallback(() => {
    setExpanded(false);
  }, []);

  return (
    <OnClickOutside handler={handleClickOutside}>
      <div
        className={cx(styles.root, {
          [styles.expanded]: expanded,
          [styles.upward]: openUpward,
          [styles.fluid]: fluid,
        })}
        role="listbox"
        ref={ref}
        tabIndex={0}
        onKeyPress={e => onEnterPress(e, toggleOpen)}
      >
        <div className={styles.currentOption} onClick={toggleOpen}>
          <span>{selectedOption?.text}</span>
          <div className={styles.iconSpacing}>
            <Icon name={expanded ? "angle up" : "angle down"} fitted />
          </div>
        </div>
        <div
          className={cx(styles.options, {
            [styles.visible]: expanded,
            [styles.nonTransparent]: expanded && measured,
          })}
          ref={optionsRef}
        >
          {options.map(o => (
            <option
              className={cx(styles.item, {
                [styles.activeItem]: selectedOption?.value === o.value,
              })}
              key={o.key}
              value={o.value}
              onClick={() => selectOption(o)}
              tabIndex={0}
              onKeyPress={e => onEnterPress(e, () => selectOption(o))}
            >
              {o.text}
            </option>
          ))}
        </div>
      </div>
    </OnClickOutside>
  );
};
