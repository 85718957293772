import React from "react";
import { useTranslation } from "react-i18next";

import styles from "./NoMembersSearchResult.module.scss";
import { NoMembersText } from "./NoMembersText";

export interface Props {}

export const NoMembersSearchResult: React.FC<Props> = () => {
  const { t } = useTranslation("groups");

  return (
    <div className={styles.root}>
      <h5 className={styles.header}>{t("noSearchResults")}</h5>
      <NoMembersText text={t("noSearchResultsDescription")} />
    </div>
  );
};
