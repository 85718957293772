import { PropsWithChildren, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { skip, startTransition } from "../../redux/flow/flowSlice";
import { selectUpdateStatus } from "../../redux/flow/flowSelectors";
import { RtnStatus } from "../../../Application/types";
import { AssetWithProgressFields } from "features/Flows/types";
import { FlowEntityType } from "features/common/types";

interface Props {
  isViewed: boolean;
  asset: AssetWithProgressFields;
}

export const SkipObserver = (props: PropsWithChildren<Props>) => {
  const { isViewed, asset, children } = props;
  const updateStatus = useSelector(selectUpdateStatus);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isViewed && updateStatus === RtnStatus.Idle && asset.type === FlowEntityType.UnavailableAsset) {
      dispatch(startTransition({ assetOrder: asset.order }));
      dispatch(skip({ assetId: asset.id, assetType: asset.originType }));
    }
  }, [isViewed, asset, dispatch, updateStatus]);

  return <>{children}</>;
};
