import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RootState } from "../../../Application/store/store";
import { RStatus } from "../../../Application/types";
import { Group, SortType } from "../../types";

export interface GroupsState {
  status: RStatus;
  items: Group[];
  count: number;
  reachedEnd: boolean;
  sortType: SortType;
  errorMessage: string | undefined;
}

export const initialState: GroupsState = {
  status: RStatus.Idle,
  items: [],
  count: 0,
  reachedEnd: false,
  sortType: SortType.NameAz,
  errorMessage: undefined,
};

const groupsSlice = createSlice({
  name: "groups",
  initialState: initialState,
  reducers: {
    req(state) {
      return { ...state, status: RStatus.Pending };
    },
    got(state, action: PayloadAction<{ items: GroupsState["items"]; count: number }>) {
      const items = state.items.concat(action.payload.items);
      const end = items.length >= action.payload.count;
      return {
        ...state,
        status: RStatus.Got,
        items: items,
        count: action.payload.count,
        reachedEnd: end,
      };
    },
    endOfResults(state) {
      state.reachedEnd = true;
    },
    err(state, action: PayloadAction<{ errorMessage: GroupsState["errorMessage"] }>) {
      return {
        ...state,
        status: RStatus.Error,
        errorMessage: action.payload.errorMessage,
      };
    },
    replace(state, action: PayloadAction<{ items: GroupsState["items"]; count: number }>) {
      return {
        ...state,
        items: action.payload.items,
        count: action.payload.count,
      };
    },
    updateSort(state, action: PayloadAction<{ value: SortType }>) {
      return {
        ...state,
        sortType: action.payload.value,
        items: [],
        status: RStatus.Pending,
      };
    },
    updateGroupInfo(state, _action: PayloadAction<{ groupId: number }>) {
      return state;
    },
    reset() {
      return initialState;
    },
  },
});

// actions
export const { req, got, err, endOfResults, replace, updateSort, updateGroupInfo, reset } = groupsSlice.actions;

// selectors
export const selectGroupsState = (state: RootState) => state.groups.groups;
export const selectGroups = (state: RootState) => state.groups.groups.items;
export const selectGroupsCount = (state: RootState) => state.groups.groups.count;
export const selectGroupsStatus = (state: RootState) => state.groups.groups.status;
export const selectGroupsEndOfResults = (state: RootState) => state.groups.groups.reachedEnd;
export const selectGroupsError = (state: RootState) => state.groups.groups.errorMessage;
export const selectGroupsSorting = (state: RootState) => state.groups.groups.sortType;

// reducers
export default groupsSlice.reducer;
