import { useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export const useBackNavigation = (defaultUrl?: string) => {
  const location = useLocation();
  const navigate = useNavigate();

  const cb = useCallback(() => {
    return location.key !== "default" ? navigate(-1) : navigate(defaultUrl ?? "");
  }, [location, navigate, defaultUrl]);

  return cb;
};
