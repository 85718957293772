import classNames from "classnames/bind";
import styles from "./PageHeader.module.scss";

const cx = classNames.bind(styles);

interface Props {
  noPadding?: boolean;
  children: React.ReactNode;
}

export const PageHeader: React.FC<Props> = ({ children, noPadding }) => {
  return <section className={cx(styles.header, { noPadding })}>{children}</section>;
};
