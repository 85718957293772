import { ClosedCaptions, VideoStreamManifest } from "../../types";
import { VideoDetails } from "../VideoAssets/VideoDetails/VideoDetails";
import { VideoPlayer } from "../VideoAssets/VideoPlayer/VideoPlayer";

import styles from "./AssetVideoPlayer.module.scss";

export interface Props {
  title: string;
  description?: string;
  manifests: VideoStreamManifest[] | null;
  closedCaptions: ClosedCaptions[];
  id: number;
}

export const AssetVideoPlayer: React.FC<Props> = ({ title, description, manifests, closedCaptions, id }) => {
  return (
    <section className={styles.root}>
      {manifests && <VideoPlayer sources={manifests} closedCaptions={closedCaptions} id={id} />}
      <VideoDetails title={title} description={description ?? ""} />
    </section>
  );
};
