import classNames from "classnames/bind";
import { MutableRefObject, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { AssessmentViewer } from "../../../Assets/components";
import { QuestionsAnswers } from "../../../Assets/types";
import { completeAssessmentAsset, nextAsset } from "../../redux/flow/flowSlice";
import { assetStatusSelector, selectEvaluatedAssessment } from "../../redux/flow/flowSelectors";
import { FlowAssessmentFooter } from "./FlowAssessmentFooter/FlowAssessmentFooter";

import { markAssessmentAsStarted } from "../../services/flowsService";
import { FlowAssessmentAsset, ProgressStatus } from "../../types";
import styles from "./FlowAssessment.module.scss";

const cx = classNames.bind(styles);

export interface Props {
  order: number;
  assessment: FlowAssessmentAsset;
  hidden?: boolean;
  resetAssetRef?: MutableRefObject<(() => void) | undefined>;
}

export const FlowAssessment: React.FC<Props> = ({ order, assessment, hidden = false, resetAssetRef }) => {
  const dispatch = useDispatch();
  const evaluatedAssessment = useSelector(selectEvaluatedAssessment);
  const assetStatus = useSelector(assetStatusSelector);
  const { flowId } = useParams();
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [answers, setAnswers] = useState<QuestionsAnswers>({});
  const [reset, setReset] = useState(false);

  const resetAssessment = () => {
    setCurrentQuestion(0);
    setAnswers({});
    setReset(true);
  };

  useEffect(() => {
    if (reset) {
      setReset(false);
    }
  }, [reset]);

  useEffect(() => {
    markAssessmentAsStarted(Number(flowId), assessment.id).catch(e => console.error(e));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (resetAssetRef) {
      resetAssetRef.current = resetAssessment;
    }
  }, [resetAssetRef]);

  const nextQuestion = useCallback(() => {
    setCurrentQuestion(prev => prev + 1);
  }, []);

  const onContinue = useCallback(() => {
    dispatch(nextAsset({ assetOrder: order }));
    setCurrentQuestion(0);
    setAnswers({});
  }, [dispatch, order]);

  const completeAssessment = useCallback(
    (data: QuestionsAnswers) => {
      dispatch(
        completeAssessmentAsset({
          flowId: Number(flowId),
          assetId: assessment.id,
          data: data,
        }),
      );
    },
    [assessment.id, dispatch, flowId],
  );

  const onRetakeAssessment = useCallback(() => {
    setCurrentQuestion(0);
    setAnswers({});
  }, []);

  return (
    <div className={cx(styles.root, { [styles.hidden]: hidden })}>
      <AssessmentViewer
        assessment={assessment}
        currentQuestion={currentQuestion}
        onNextQuestion={nextQuestion}
        answers={answers}
        updateAnswers={setAnswers}
        complete={completeAssessment}
        evaluatedAssessment={evaluatedAssessment}
        reset={reset}
      >
        <FlowAssessmentFooter
          onRetakeAssessment={onRetakeAssessment}
          onContinue={onContinue}
          isDisabled={assetStatus !== ProgressStatus.Completed}
        />
      </AssessmentViewer>
    </div>
  );
};
