import { Group } from "../types";
import { Access, Awareness, Enrollment, Visibility } from "../types/enums";

export interface GroupActionArgument {
  isWithAutoEnroll: Group["isWithAutoEnroll"];
  isAware: Group["isAware"];
  isVisibleToAll: Group["isVisibleToAll"];
  isOpen: Group["isOpen"];
}

export function groupActionButtonShouldBeAvailable(groupInfo: GroupActionArgument) {
  const { isWithAutoEnroll, isAware, isVisibleToAll, isOpen } = groupInfo;
  return (
    isWithAutoEnroll !== Enrollment.Automatic &&
    isAware === Awareness.Aware &&
    isOpen === Access.Open &&
    isVisibleToAll === Visibility.VisibleToAll
  );
}
