import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";
import { Icon } from "semantic-ui-react";
import styles from "./FlowProgressItem.module.scss";
import classNames from "classnames/bind";
import { durationSecondsToTime } from "../../../../Application/utils/dateTime";
import { InvisibleButton } from "../../../../Application/layout";
import { FlowProgressLine } from "../FlowProgressLine/FlowProgressLine";
import { FlowProgressBagHint } from "../FlowProgressBagHint/FlowProgressBagHint";
import { EntityType, FlowEntity, FlowEntityType } from "../../../../common/types";
import { AssetWithProgressFields, FlowAssetMeta } from "../../../types";
import EventSessionMetadata from "../../../../Events/components/EventSessionMetadata/EventSessionMetadata";
import { getRecurrenceMetadata } from "../../../../Events/utils/getRecurrenceMetadata";
import { useCallback, useMemo } from "react";
import { TextTruncator } from "../../../../Application/layout/TextTruncator/TextTruncator";
import { ReactComponent as OctagonCheckFill } from "../../../../../assets/images/octagon-check-fill.svg";
import { ReactComponent as OctagonCheckLine } from "../../../../../assets/images/octagon-check-line.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconDefinition } from "@fortawesome/sharp-solid-svg-icons";
import { faMessage as faMessageSolid, faTriangleExclamation } from "@fortawesome/pro-solid-svg-icons";
import { faMessage as faMessageRegular } from "@fortawesome/pro-regular-svg-icons";
const cx = classNames.bind(styles);

export interface Props {
  asset: AssetWithProgressFields;
  collapsed: boolean;
  completed: boolean;
  selected: boolean;
  error: boolean;
  onClickItem: () => void;
}

function getIconByType(type: FlowEntity, selected: boolean) {
  const getIcon = (iconName: string) => <Icon className={cx(styles.icon, iconName, { outline: !selected })} />;
  const getIconV2 = (iconDefinition: IconDefinition, style: string) => {
    return <FontAwesomeIcon className={style} icon={iconDefinition} />;
  };

  switch (type) {
    case EntityType.Video:
      return getIcon("video");
    case EntityType.Assessment:
      return getIcon("list ol");
    case EntityType.Survey:
      return getIcon("list ul");
    case EntityType.Event:
      return getIcon("calendar alternate outline");
    case EntityType.Email:
      return getIcon("mail");
    case EntityType.Message:
      return getIconV2(selected ? faMessageSolid : faMessageRegular, styles.icon);
    case EntityType.Pdf:
      return getIcon("file alternate");
    case FlowEntityType.FlowEnd:
      return selected ? <OctagonCheckFill /> : <OctagonCheckLine />;
    case FlowEntityType.UnavailableAsset:
      return getIconV2(faTriangleExclamation, styles.iconRed);
    default:
      return getIcon(type);
  }
}

function getPublisher(asset: AssetWithProgressFields) {
  switch (asset.type) {
    case EntityType.Video:
    case EntityType.Survey:
    case EntityType.Assessment:
    case EntityType.Event:
    case EntityType.Pdf:
      return asset.publisher;
    default:
      return "";
  }
}

function getMeta(asset: FlowAssetMeta, t: TFunction<("events, flows" | "assets")[], undefined>) {
  switch (asset.type) {
    case EntityType.Video:
      return durationSecondsToTime(asset.duration);
    case EntityType.Assessment:
    case EntityType.Survey:
      return t("assessments.questionCount", {
        ns: "assets",
        count: asset.questionsCount,
      });
    case EntityType.Event: {
      return <EventSessionMetadata event={asset} />;
    }
    default:
      return "";
  }
}

export const FlowProgressItem: React.FC<Props> = ({ asset, collapsed, selected, completed, error, onClickItem }) => {
  const { t } = useTranslation(["assets", "events, flows"]);
  const { title, type, bag, isDelayTimeNotExpired, disabled } = asset;
  const currentTitle = useMemo(() => {
    switch (type) {
      case FlowEntityType.FlowEnd:
        return t("flowEnd.base.finished", { ns: "flows" });
      case FlowEntityType.UnavailableAsset:
        return t("common.unavailableAsset", { ns: "assets" });
      default:
        return title;
    }
  }, [type, title, t]);

  const publisher = getPublisher(asset);

  const handleClick = useCallback(() => {
    if (!disabled) {
      onClickItem();
    }
  }, [disabled, onClickItem]);

  const flowProgressItemAriaLabel = useMemo(() => {
    let eventSessionMetadataText: string = "";

    if (asset.type === EntityType.Event) {
      const { singleSessionsCount, recurrenceSession } = asset;

      if (singleSessionsCount > 0) {
        eventSessionMetadataText = t("info.session", {
          ns: "events",
          count: singleSessionsCount,
        });
      } else {
        if (recurrenceSession !== undefined) {
          eventSessionMetadataText = getRecurrenceMetadata(recurrenceSession);
        }
      }
    } else {
      eventSessionMetadataText = getMeta(asset, t) as string;
    }

    return `${currentTitle} ${publisher} ${eventSessionMetadataText}`;
  }, [currentTitle, publisher, asset, t]);

  return (
    <div className={styles.root} data-testid="flow-progress-item">
      <div
        className={cx(styles.checkmark, {
          [styles.halfHeightCheckmark]: [EntityType.Email, EntityType.Message, FlowEntityType.FlowEnd].includes(type),
        })}
      >
        <InvisibleButton onClick={handleClick} ariaLabel={`asset ${currentTitle}`}>
          {!(disabled || isDelayTimeNotExpired) && completed ? (
            <div className={error ? styles.buttonIconRed : styles.buttonIcon}>
              <Icon className={error ? "exclamation circle" : "check circle"} data-testid="check-mark" fitted />
            </div>
          ) : (
            <div
              className={cx(styles.buttonIcon, {
                [styles.selected]: selected,
                [styles.disabled]: !selected,
              })}
              data-testid="check-circle"
            ></div>
          )}
        </InvisibleButton>
        <FlowProgressLine isFirst={false} disabled={disabled || isDelayTimeNotExpired} />
      </div>
      <div
        className={cx(styles.stripe, {
          [styles.hidden]: collapsed,
          [styles.selected]: selected,
          [styles.error]: error,
        })}
      ></div>
      <div
        className={cx(styles.tile, {
          [styles.hidden]: collapsed,
          [styles.selected]: selected,
          [styles.error]: error,
          [styles.disabled]: (disabled || isDelayTimeNotExpired) && !selected,
          [styles.halfHeightTile]: [EntityType.Email, EntityType.Message, FlowEntityType.FlowEnd].includes(type),
        })}
        onClick={handleClick}
        aria-label={flowProgressItemAriaLabel}
      >
        <div className={styles.title} aria-hidden="true">
          {getIconByType(error ? FlowEntityType.UnavailableAsset : type, selected)}

          <TextTruncator tooltipContent={currentTitle} clamp={1} aria-label={currentTitle} tabIndex={0}>
            <div className={styles.text}>{currentTitle}</div>
          </TextTruncator>
          {!completed && <FlowProgressBagHint bag={bag} selected={selected} asset={asset} />}
        </div>
        <div className={styles.meta} aria-hidden="true">
          <div tabIndex={0} aria-label={publisher?.toLocaleUpperCase()} className={styles.publisher}>
            <TextTruncator
              key={publisher?.toLocaleUpperCase()}
              tooltipContent={publisher?.toLocaleUpperCase()}
              clamp={1}
              aria-label={publisher?.toLocaleUpperCase()}
            >
              {publisher?.toLocaleUpperCase()}
            </TextTruncator>
          </div>
          <div tabIndex={0} className={styles.description}>
            {getMeta(asset, t)}
          </div>
        </div>
      </div>
    </div>
  );
};
