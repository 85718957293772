import { useTranslation } from "react-i18next";

import { Button } from "../../../../Application/layout";
import { AssessmentFooter } from "../../../../Assets/components";

interface Props {
  onRetakeAssessment: () => void;
  onContinue: () => void;
  isDisabled: boolean;
}

export const FlowAssessmentFooter: React.FC<Props> = ({ onRetakeAssessment, onContinue, isDisabled }) => {
  const { t } = useTranslation("assets");

  return (
    <AssessmentFooter>
      <Button onClick={onRetakeAssessment}>{t("assessments.retake")}</Button>
      <Button onClick={onContinue} disabled={isDisabled}>
        {t("assessments.continue")}
      </Button>
    </AssessmentFooter>
  );
};
