import React from "react";
import { useTranslation } from "react-i18next";
import { EmptyState } from "../EmptyState/EmptyState";
import { ReactComponent as Restricted } from "../../../../assets/images/restricted-access.svg";

import styles from "./AccessRestricted.module.scss";
import classNames from "classnames";

const cx = classNames.bind(styles);

interface Props {
  description: string;
  className?: string;
}

export const AccessRestricted = (props: React.PropsWithChildren<Props>) => {
  const { description, children, className } = props;
  const { t } = useTranslation("application");

  return (
    <div className={cx(styles.root, className)}>
      <EmptyState
        icon={<Restricted className={styles.icon} />}
        title={t("common.accessRestricted")}
        description={description}
        fullWidth
      >
        {children}
      </EmptyState>
    </div>
  );
};
