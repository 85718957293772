import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../Application/store/store";
import { LazyLoadingState, RStatus } from "../../../Application/types";
import { DiscoveryItem } from "../../../Discover/types";
import { defaultGroupsTake } from "../../services/groupsService";
import { Group, GroupMember } from "../../types";

export interface MembersState extends LazyLoadingState {
  term?: string;
  filtered: GroupMember[];
}

export interface GroupState {
  status: RStatus;
  current?: Group;
  members: MembersState;
  errorMessage: string | undefined;
  content: ContentState;
}

export interface ContentState extends LazyLoadingState {
  items: DiscoveryItem[];
}

export const initialState: GroupState = {
  status: RStatus.Idle,
  current: undefined,
  members: {
    term: "",
    status: RStatus.Idle,
    filtered: [],
    inlineCount: 0,
    errorMessage: undefined,
    top: defaultGroupsTake,
    activePage: 1,
    reachedEnd: false,
  },
  errorMessage: undefined,
  content: {
    status: RStatus.Idle,
    items: [],
    inlineCount: 0,
    errorMessage: undefined,
    top: defaultGroupsTake,
    activePage: 1,
    reachedEnd: false,
  },
};

const groupSlice = createSlice({
  name: "group",
  initialState: initialState,
  reducers: {
    req(state, _: PayloadAction<{ id: number }>) {
      return { ...state, status: RStatus.Pending };
    },
    got(state, action: PayloadAction<{ current: GroupState["current"] }>) {
      return { ...state, status: RStatus.Got, current: action.payload.current };
    },
    err(state, action: PayloadAction<{ errorMessage: GroupState["errorMessage"] }>) {
      return {
        ...state,
        status: RStatus.Error,
        errorMessage: action.payload.errorMessage,
      };
    },
    reqMembers(state, action: PayloadAction<{ id: number; term?: string }>) {
      state.members.filtered = [];
      state.members.reachedEnd = false;
      state.members.activePage = 1;
      state.members.inlineCount = 0;
      state.members.status = RStatus.Pending;
      state.members.term = action.payload.term;
    },
    updateMembersStatus(state, action: PayloadAction<{ status: RStatus }>) {
      return {
        ...state,
        members: { ...state.members, status: action.payload.status },
      };
    },
    gotMembers(state, action: PayloadAction<{ members: GroupMember[]; count: number }>) {
      state.members.filtered = action.payload.members;
      state.members.inlineCount = action.payload.count;
      state.members.status = RStatus.Got;
    },
    errMembers(state, action: PayloadAction<{ errorMessage: MembersState["errorMessage"] }>) {
      state.members.errorMessage = action.payload.errorMessage;
      state.members.status = RStatus.Error;
    },
    fetchMembersMore(state, action: PayloadAction<{ term?: string }>) {
      if (!state.members.reachedEnd) {
        state.members.activePage += 1;
      }
      state.members.term = action.payload.term;
      return state;
    },
    outOfMembersResults(state) {
      state.members.reachedEnd = true;
    },
    reqContent(state, action: PayloadAction<{ id: number }>) {
      state.content.reachedEnd = false;
      state.content.status = RStatus.Pending;
    },
    updateContentStatus(state, action: PayloadAction<{ status: RStatus }>) {
      return {
        ...state,
        content: { ...state.content, status: action.payload.status },
      };
    },
    gotContent(state, action: PayloadAction<DiscoveryItem[]>) {
      state.content.items = action.payload;

      state.content.status = RStatus.Got;
    },
    errContent(state, action: PayloadAction<{ errorMessage: ContentState["errorMessage"] }>) {
      state.content.errorMessage = action.payload.errorMessage;
      state.content.status = RStatus.Error;
    },
    updateStatus(state, action: PayloadAction<{ status: RStatus }>) {
      return {
        ...state,
        status: action.payload.status,
      };
    },
    fetchContentMore(state) {
      if (!state.content.reachedEnd) {
        state.content.activePage += 1;
      }
      return state;
    },
    outOfContentResults(state) {
      state.content.reachedEnd = true;
    },
    updateSort(state, action: PayloadAction<{ id: number }>) {
      state.content.activePage = initialState.content.activePage;
    },
    reset: () => initialState,
  },
});

// actions
export const {
  req,
  got,
  err,
  reqMembers,
  updateMembersStatus,
  gotMembers,
  errMembers,
  fetchMembersMore,
  outOfMembersResults,
  reset,
  reqContent,
  updateContentStatus,
  gotContent,
  errContent,
  fetchContentMore,
  outOfContentResults,
  updateStatus,
  updateSort,
} = groupSlice.actions;

// selectors
export const selectGroup = (state: RootState) => state.groups.group.current;
export const selectGroupStatus = (state: RootState) => state.groups.group.status;
export const selectGroupError = (state: RootState) => state.groups.group.errorMessage;
export const selectMembersState = (state: RootState) => state.groups.group.members;
export const selectFilteredMembers = (state: RootState) => state.groups.group.members.filtered;
export const selectMembersInlineCount = (state: RootState) => state.groups.group.members.inlineCount;
export const selectMembersStatus = (state: RootState) => state.groups.group.members.status;
export const selectMembersSearchTerm = (state: RootState) => state.groups.group.members.term;
export const selectMembersError = (state: RootState) => state.groups.group.members.errorMessage;
export const selectMembersResultsReachedEnd = (state: RootState) => state.groups.group.members.reachedEnd;
export const selectContentState = (state: RootState) => state.groups.group.content;
export const selectContent = (state: RootState) => state.groups.group.content.items;
export const selectContentStatus = (state: RootState) => state.groups.group.content.status;
export const selectContentError = (state: RootState) => state.groups.group.content.errorMessage;
export const selectContentResultsReachedEnd = (state: RootState) => state.groups.group.content.reachedEnd;

// reducers
export default groupSlice.reducer;
