import { createSlice } from "@reduxjs/toolkit";

interface AppConfigState {}

const initialState: AppConfigState = {};

const appConfigSlice = createSlice({
  name: "appConfig",
  initialState,
  reducers: {},
});

export default appConfigSlice.reducer;
