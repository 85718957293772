import classNames from "classnames/bind";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { Icon, Loader } from "semantic-ui-react";
import { makeObjectUrl } from "../../../Assets/services/assetsService";
import { completePdfAsset, startTransition } from "../../redux/flow/flowSlice";
import styles from "./FlowPdf.module.scss";
import { FlowPdfAsset } from "features/Flows/types";

const cx = classNames.bind(styles);

export interface Props {
  order: number;
  pdf: FlowPdfAsset;
  hidden: boolean;
  completed: boolean;
}

export const FlowPdf: React.FC<Props> = ({ order, pdf, hidden, completed }) => {
  const dispatch = useDispatch();
  const { flowId } = useParams();
  const { t } = useTranslation("assets");
  const [pdfLoaded, setPdfLoaded] = useState(false);

  const [pdfUrl, setPdfUrl] = useState<string>();

  const getData = async (asset: FlowPdfAsset) => {
    const results = await makeObjectUrl(asset.pdfDocumentUrl);
    setPdfUrl(results);
  };

  const markAsComplete = () => {
    if (flowId && pdf.id) {
      dispatch(
        startTransition({
          assetOrder: order,
        }),
      );
      dispatch(
        completePdfAsset({
          flowId: +flowId,
          assetId: pdf.id,
          assetOrder: order,
        }),
      );
    }
  };

  useEffect(() => {
    const getPDFData = async () => {
      await getData(pdf);
    };
    getPDFData().catch(e => console.error(e));
  }, [pdf]);

  const pdfLoadingFinished = useCallback(() => {
    setPdfLoaded(true);
  }, []);

  return (
    <div className={cx(styles.root, { [styles.hidden]: hidden })}>
      <div className={styles.root}>
        <section className={styles.content}>
          {!pdfLoaded && <Loader active />}
          {pdfUrl && (
            <iframe
              onLoad={pdfLoadingFinished}
              data-testid="pdfIframe"
              title="PDF viewer"
              src={pdfUrl}
              height="100%"
              width="100%"
            />
          )}
        </section>
        {pdfLoaded && (
          <div className={styles.markComplete}>
            {completed ? (
              <div>
                <Icon name="check circle" color="green" />
                <div>{t("pdfs.completed")}</div>
              </div>
            ) : (
              <button onClick={markAsComplete}>{t("common.next")}</button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
