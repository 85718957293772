import classNames from "classnames/bind";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { BreakpointContext } from "../../../Application/components/context/BreakpointContext";

import styles from "./GroupListHeader.module.scss";

const cx = classNames.bind(styles);

interface Props {
  groupCount: number;
  dropdown: React.ReactNode;
}

export const GroupListHeader: React.FC<Props> = ({ groupCount, dropdown }) => {
  const { t } = useTranslation(["groups"]);
  const bp = useContext(BreakpointContext);

  return (
    <div className={styles.root}>
      <div>{t("group", { count: groupCount })}</div>
      <div className={styles.filter}>
        <span
          className={cx(styles.sortText, {
            [styles.hidden]: bp.isMobileScreen,
          })}
        >
          {t("sortBy")}
        </span>
        {!bp.isMobileScreen && dropdown}
      </div>
    </div>
  );
};
