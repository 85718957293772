import React, { useCallback } from "react";
import classNames from "classnames/bind";
import styles from "./Segment.module.scss";
import { NavLink } from "react-router-dom";

const cx = classNames.bind(styles);

interface Props {
  to: string;
  label: string;
  className?: string;
  onClick?: () => void;
  disabled?: boolean;
}

export const Segment: React.FC<Props> = ({ to, label, className, onClick, disabled = false }) => {
  const cn = cx(styles.segment, className);

  const active = useCallback(
    ({ isActive }: { isActive: boolean }) => {
      return cx(cn, {
        [styles.activeSegment]: isActive,
      });
    },
    [cn],
  );

  return (
    <>
      {disabled ? (
        <span className={cx(cn, styles.disabled)} data-testid="segment-disabled">
          {label}
        </span>
      ) : (
        <NavLink to={to} className={active} onClick={onClick} data-testid="segment">
          {label}
        </NavLink>
      )}
    </>
  );
};
