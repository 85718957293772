import classNames from "classnames/bind";
import { PriorityLevel } from "../../../Discover/types";
import { HomePageContentItem } from "../../types";
import { CategoriesStacks } from "../CategoriesStacks/CategoriesStacks";
import styles from "./ProgressBarsSection.module.scss";

const cx = classNames.bind(styles);

export interface Props {
  title: string;
  priorityLevel: PriorityLevel;
  items?: HomePageContentItem[];
  showBorder: boolean;
  className?: string;
}

export const ProgressBarsSection: React.FC<Props> = ({ title, priorityLevel, showBorder, className, items }) => {
  const toDisplay = items ? items.slice(0, 4) : [];
  return (
    <div
      className={cx(styles.root, className, {
        [styles.hidden]: !items || items.length === 0,
      })}
      data-testid="progress bar root"
    >
      <h2 className={styles.title} tabIndex={0}>
        {title}
      </h2>
      <div className={styles.content}>
        <CategoriesStacks items={toDisplay} showBorder={showBorder} priorityLevel={priorityLevel} />
      </div>
    </div>
  );
};
