import React from "react";
import { Route, Routes } from "react-router-dom";
import EventDetailsPage from "../EventDetailsPage/EventDetailsPage";
import { RedirectToExternalEvent } from "../RedirectToExternalEvent/RedirectToExternalEvent";

export const EventRoutes = () => {
  return (
    <Routes>
      <Route path={":eventId/redirect"} element={<RedirectToExternalEvent />} />
      <Route path={":eventId"} element={<EventDetailsPage />} />
    </Routes>
  );
};
