import { useAuth } from "oidc-react";
import { useCallback } from "react";
import { useLocation } from "react-router-dom";

import App from "../../../Application/components/App/App";
import { AppLoader } from "../../../Application/layout";
import { TrackSource } from "./TrackSource";
import { useSignout } from "./useSignout";

export const LoggedInApp = () => {
  const auth = useAuth();
  const [signOut] = useSignout();
  const location = useLocation();

  const onSignout = useCallback(() => {
    return signOut(location);
  }, [signOut, location]);

  if (auth.userData && !auth.userData.expired) {
    return (
      <TrackSource>
        <App onSignout={onSignout} />
      </TrackSource>
    );
  }

  return <AppLoader />;
};
