import React, { useCallback, useEffect, useState } from "react";
import { Loader } from "semantic-ui-react";
import { Button } from "../../../../Application/layout";
import { RegistrationOptions } from "./RegistrationOptions/SessionRegistrationOptions";

import styles from "./SessionRegistration.module.scss";

interface Props {
  wasRegistered: boolean;
  disabled?: boolean;
  onRegistration?: (registrationType: number) => void;
  onUnregistration?: () => void;
}

export const SessionRegistration = (props: Props) => {
  const { wasRegistered, disabled, onRegistration, onUnregistration } = props;
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    setShowLoader(false);
  }, [wasRegistered]);

  const handleOnRegistration = useCallback(() => onRegistration?.(RegistrationOptions.Email), [onRegistration]);

  const unregister = useCallback(() => {
    setShowLoader(true);
    onUnregistration?.();
  }, [onUnregistration]);

  const register = useCallback(() => {
    setShowLoader(true);
    handleOnRegistration();
  }, [handleOnRegistration]);

  const unregisterButtonContents = showLoader ? (
    <div className={styles.unRegisterLoader}>
      <Loader active inline size="tiny" />
    </div>
  ) : (
    <div className={styles.unRegisterButtonLabel}>Unregister</div>
  );

  const registerButtonContents = showLoader ? (
    <div className={styles.loader}>
      <Loader active inline size="tiny" />
    </div>
  ) : (
    <div className={styles.buttonLabels}>Register Now</div>
  );

  return (
    <div className={styles.sessionRegistration}>
      <span className={styles.actionButton}>
        {wasRegistered ? (
          <Button secondaryButton onClick={unregister} data-testid="unregister-button" disabled={disabled}>
            {unregisterButtonContents}
          </Button>
        ) : (
          <Button fullWidth onClick={register} data-testid="register-button" disabled={disabled}>
            {registerButtonContents}
          </Button>
        )}
      </span>
    </div>
  );
};
