// should be reused for trivial reducers logic
export enum RStatus {
  Idle = "idle",
  Pending = "pending",
  Got = "got",
  Error = "error",
}

export enum SortDirection {
  Descending = "desc",
  Ascending = "asc",
}

export enum Sizes {
  Mini = "mini",
  Tiny = "tiny",
  Small = "small",
  Large = "large",
  Fullscreen = "fullscreen",
}

export enum RtnStatus {
  Idle = "idle",
  Pending = "pending",
}

export interface LazyLoadingState {
  top: number;
  activePage: number;
  reachedEnd: boolean;
  status: RStatus;
  inlineCount: number;
  errorMessage?: string;
}
