import { memo, useContext, useEffect } from "react";
import FiltersToggler from "../../Filters/FiltersToggler";
import SortToggler from "../../Sorting/SortToggler";
import { BreakpointContext } from "../../../../Application/components/context/BreakpointContext";

interface Props {
  filtersCount: number;
  showFilters: boolean;
  sortOpen: boolean;
  setShowFilters: (show: boolean) => void;
  setSortOpen: (open: boolean) => void;
}

export const FilteringControlls = (props: Props) => {
  const { filtersCount, setShowFilters, setSortOpen, showFilters, sortOpen } = props;
  const breakpoint = useContext(BreakpointContext);

  const shouldHideFilters = breakpoint.isMobileScreen || breakpoint.isTabletPortraitScreen;

  useEffect(() => {
    setShowFilters(false);
  }, [setShowFilters, shouldHideFilters]);

  const shouldHideSort = breakpoint.isMobileScreen;
  // Don't persist the dropdown state when changing from mobile to larger (or vice versa)
  useEffect(() => {
    setSortOpen(false);
  }, [setSortOpen]);

  // Prevents filter and dropdown from being open at the same time
  useEffect(() => {
    if (showFilters) setSortOpen(false);
  }, [showFilters, setSortOpen]);

  useEffect(() => {
    if (sortOpen) setShowFilters(false);
  }, [sortOpen, setShowFilters]);

  return (
    <>
      {shouldHideFilters && (
        <FiltersToggler filtersCount={filtersCount} toggleFilters={() => setShowFilters(!showFilters)} />
      )}
      {shouldHideSort && <SortToggler includeTextOnToggle={false} toggleSort={() => setSortOpen(!sortOpen)} />}
    </>
  );
};

export default memo(FilteringControlls);
