import { useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { userManager } from "../../config/userManager";
import {
  ACCOUNT_REDIRECT_EMAIL_PARAM_NAME,
  ACCOUNT_REDIRECT_ID_PARAM_NAME,
  DESTINATION_ACCOUNT_ID_PARAM_NAME,
} from "./constants";
import { useSignout } from "./useSignout";

export const useAccountRedirectObserver = () => {
  const location = useLocation();
  const [signOut] = useSignout();
  const [initialized, setInitialized] = useState<boolean>(false);
  const setSearchParams = useSearchParams()[1];
  useEffect(() => {
    handleAccountRedirect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAccountRedirect = async () => {
    const user = await userManager.getUser();
    const params = new URLSearchParams(location.search);

    if (!params.has(DESTINATION_ACCOUNT_ID_PARAM_NAME)) {
      setInitialized(true);
      return;
    }

    const destinationAccountId = params.get(DESTINATION_ACCOUNT_ID_PARAM_NAME);
    params.delete(DESTINATION_ACCOUNT_ID_PARAM_NAME);

    if (user && !user.expired) {
      const { OriginAccountId: accountId, name: email } = user.profile;
      if (destinationAccountId !== accountId) {
        params.set(ACCOUNT_REDIRECT_EMAIL_PARAM_NAME, email ?? "");
        params.set(ACCOUNT_REDIRECT_ID_PARAM_NAME, destinationAccountId!);

        const locationWithRedirectParams = { ...location, search: params.toString() };

        await signOut(locationWithRedirectParams);
        return;
      }
    }

    setSearchParams(params.toString(), { replace: true });
    setInitialized(true);
  };

  return [initialized];
};
