import classNames from "classnames/bind";
import React, { ReactChild, ReactNode, useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Icon, Modal, TransitionablePortal } from "semantic-ui-react";
import { BreakpointContext } from "../../../Application/components/context/BreakpointContext";
import { InvisibleButton } from "../../../Application/layout/InvisibleButton/InvisibleButton";
import { Sizes } from "../../../Application/types";
import { Flyout } from "../Flyout/Flyout";

import styles from "./ConfirmationModal.module.scss";

const cx = classNames.bind(styles);

export interface Props {
  isOpen: boolean;
  activeElement?: Element | null;
  title: ReactNode;
  body: ReactNode;
  /**
   * An override to the normal cancel button.
   * This will not be wrapped in a button, so it is your
   * responsibility to add an onClick handler for handleClose
   */
  cancelButton?: ReactChild;
  handleClose: () => void;
  /**
   * An override to the normal confirm button.
   * This will not be wrapped in a button, so it is your
   * responsibility to add an onClick handler for handleConfirm
   */
  confirmButton?: ReactChild;
  handleConfirm?: () => void;
  className?: string;
  size?: Sizes;
}

export const ConfirmationModal: React.FC<Props> = props => {
  const { handleClose, isOpen, activeElement, className, size } = props;
  const [showFlyoutBody, setShowFlyoutBody] = useState(false);
  const { isMobileScreen } = useContext(BreakpointContext);

  const cleanUp = useCallback(() => {
    if (activeElement) {
      (activeElement as HTMLElement).focus();
    }
  }, [activeElement]);

  // Close on screen size change
  useEffect(() => {
    handleClose();
  }, [isMobileScreen, handleClose]);

  // Close modals on escape press
  useEffect(() => {
    const handler = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        handleClose();
      }
    };

    if (isOpen) {
      window.addEventListener("keyup", handler);
      return () => window.removeEventListener("keyup", handler);
    }
  }, [isOpen, handleClose]);

  useEffect(() => {
    if (isOpen) {
      setShowFlyoutBody(true);
    }
  }, [isOpen]);

  if (isMobileScreen) {
    return (
      <Flyout open={isOpen} height={30}>
        {showFlyoutBody && <ConfirmationModalBody {...props} />}
      </Flyout>
    );
  }
  return (
    <TransitionablePortal open={isOpen}>
      <Modal open onClose={handleClose} size={size ?? Sizes.Small} onUnmount={cleanUp} className={className}>
        <ConfirmationModalBody {...props} />
      </Modal>
    </TransitionablePortal>
  );
};

const ConfirmationModalBody: React.FC<Props> = ({
  title,
  body,
  cancelButton,
  confirmButton,
  handleClose,
  handleConfirm,
}) => {
  const { t } = useTranslation("discover");
  return (
    <div className={cx(styles.root, "confirm-modal-body")} data-testid="confirm-modal-body">
      <section className={styles.header}>
        <h1 className={styles.title}>{title}</h1>
        <InvisibleButton onClick={handleClose}>
          <span className={styles.icon}>
            <Icon className="thin" name="times" fitted size="large" />
          </span>
        </InvisibleButton>
      </section>
      <section className={cx(styles.content, "confirm-modal-content")}>{body}</section>
      <section className={styles.actions}>
        <div className={styles.cancelContainer}>
          {cancelButton ?? (
            <button className={styles.cancelButton} onClick={handleClose} data-testid="confirm-modal-cancel">
              {t("modals.cancel")}
            </button>
          )}
        </div>
        <div className={styles.confirmContainer}>
          {confirmButton ?? (
            <button className={styles.confirmButton} onClick={handleConfirm} data-testid="confirm-modal-confirm">
              {t("modals.confirm")}
            </button>
          )}
        </div>
      </section>
    </div>
  );
};
