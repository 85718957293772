import { useAuth } from "oidc-react";
import { useCallback } from "react";
import { useLocation } from "react-router-dom";

export const useSignout = () => {
  const auth = useAuth();

  const signOut = useCallback(
    (location: ReturnType<typeof useLocation>) => {
      localStorage.removeItem("privacy policy viewed");
      return auth.signOutRedirect({ state: location });
    },
    [auth],
  );

  return [signOut];
};

export default useSignout;
