import { To } from "react-router-dom";
import { mapCompletionStatus } from "../../common/mapperServices/mapCompletionStatus";
import { EntityType } from "../../common/types";
import { HomeItem, HomePageContentItem } from "../types";

export const toHomePageContentItems = (homeItems: HomeItem[]): HomePageContentItem[] =>
  homeItems.map(toHomePageContentItem);

const toHomePageContentItem = (homeItem: HomeItem): HomePageContentItem => ({
  id: parseInt(homeItem.id),
  title: homeItem.title,
  entityType: homeItem.entityType,
  priority: homeItem.priority,
  applications: homeItem.applications,
  dueDate: homeItem.dueDate,
  thumbnailUrl: homeItem.bag?.thumbnailUrl,
  description: homeItem.description,
  completionStatus: mapCompletionStatus(homeItem),
  expired: homeItem.expired,
});

export const toItemLink = (id: number, entityType: EntityType): To => {
  switch (entityType) {
    case EntityType.Flow:
      return `/discover/flows/${id}`;
    case EntityType.Survey:
      return `/discover/survey/${id}`;
    case EntityType.Video:
      return `/discover/asset/${id}`;
    case EntityType.Event:
      return `/discover/event/${id}`;
    case EntityType.Assessment:
      return `/discover/assessment/${id}`;
    case EntityType.Pdf:
      return `/discover/pdf/${id}`;
    default:
      return "/";
  }
};
