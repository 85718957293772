import React, { memo, useContext } from "react";
import { DiscoveryItem } from "../../types";
import { BreakpointContext } from "../../../Application/components/context/BreakpointContext";
import styles from "./ContentItem.module.scss";
import { EntityType } from "../../../common/types";
import { ProgressAssetCard } from "features/Application/components/Card/Assets/ProgressAssetCard/ProgressAssetCard";
import { Routes } from "features/common/routes";

interface Props {
  item: DiscoveryItem;
  interactive?: boolean;
}

export const NavigatableContentItem: React.FC<Props> = ({ item, interactive }) => {
  const { isMobileScreen } = useContext(BreakpointContext);

  let baseItemDetailsPath = "";
  switch (item.entityType) {
    case EntityType.Assessment:
      baseItemDetailsPath = Routes.Discover.assessment;
      break;
    case EntityType.Survey:
      baseItemDetailsPath = Routes.Discover.survey;
      break;
    case EntityType.Event:
      baseItemDetailsPath = Routes.Discover.event;
      break;
    case EntityType.Flow:
      baseItemDetailsPath = Routes.Discover.flows;
      break;
    case EntityType.Pdf:
      baseItemDetailsPath = Routes.Discover.pdf;
      break;
    default:
      baseItemDetailsPath = Routes.Discover.asset;
  }

  return (
    <div className={styles.cardWrapper}>
      <ProgressAssetCard
        orientation={isMobileScreen ? "vertical" : "horizontal"}
        item={item}
        to={`${baseItemDetailsPath}/${item.id}`}
        interactive={interactive}
      />
    </div>
  );
};

export default memo(NavigatableContentItem);
