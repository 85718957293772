import { useTranslation } from "react-i18next";

import { FlowAssetHeader } from "../../../Flows/components/FlowAssetHeader/FlowAssetHeader";
import {
  AnsweredSurveyQuestion,
  SurveyAnswers,
  SurveyQuestion,
  SurveyQuestionAnswer,
  SurveyQuestionsAnswers,
} from "../../types";
import { SurveyQuestionForm } from "./SurveyQuestionForm/SurveyQuestionForm";

import { useCallback } from "react";
import styles from "./SurveyViewer.module.scss";

function getFormattedData(answers: SurveyQuestionsAnswers, questions: SurveyQuestion[]) {
  let data = {
    answeredQuestions: [] as AnsweredSurveyQuestion[],
  };
  for (let questionOrder in answers) {
    const currentQuestion = questions[questionOrder];
    const currentAnswer = answers[questionOrder];
    data.answeredQuestions.push({
      id: currentQuestion.id,
      otherOptionAnswer: currentAnswer.selected[currentQuestion.answers.length]
        ? {
            value: currentAnswer.otherText,
            isChecked: true,
          }
        : null,
      answers: currentQuestion.answers
        .map((a, i) => ({
          id: a.id,
          value: currentAnswer.selected[i],
        }))
        .filter(a => a.value),
    });
  }

  return data;
}

type AnswersMap = { [x: number]: SurveyQuestionAnswer };

export interface Props {
  survey: {
    title: string;
    questionsCount: number;
    questions: SurveyQuestion[];
  };
  currentQuestion: number;
  onNextQuestion: () => void;
  onPrevQuestion: () => void;
  answers: SurveyQuestionsAnswers;
  updateAnswers: (answers: AnswersMap) => void;
  complete: (data: SurveyAnswers) => void;
  reset?: boolean;
  children?: React.ReactNode;
}

export const SurveyViewer: React.FC<Props> = ({
  survey,
  currentQuestion,
  onNextQuestion,
  onPrevQuestion,
  answers,
  updateAnswers,
  complete,
  reset,
  children,
}) => {
  const { t } = useTranslation("assets");
  const { questionsCount, questions, title } = survey;
  const question = questions[currentQuestion ?? 0];

  const getOnSubmit = useCallback(
    (callback?: (newAnswers: AnswersMap) => void) => (answer?: SurveyQuestionAnswer) => {
      if (currentQuestion !== null && answer) {
        const newAnswers = { ...answers, [currentQuestion]: answer };
        updateAnswers(newAnswers);
        callback?.(newAnswers);
      }
    },
    [answers, currentQuestion, updateAnswers],
  );

  const onSubmitAnswer = useCallback(
    (answer?: SurveyQuestionAnswer) => {
      const onSubmit = getOnSubmit();
      onSubmit(answer);
    },
    [getOnSubmit],
  );

  const onLastSubmit = useCallback(
    (answer?: SurveyQuestionAnswer) => {
      const onSubmit = getOnSubmit(newAnswers => {
        complete(getFormattedData(newAnswers, questions));
      });
      onSubmit(answer);
    },
    [complete, getOnSubmit, questions],
  );

  let header, body;
  if (currentQuestion < questionsCount) {
    header = `${t("assessments.question")} ${currentQuestion + 1} ${t("assessments.of")} ${questionsCount}`;
    body = (
      <SurveyQuestionForm
        question={question}
        answer={answers[currentQuestion]}
        prevQuestion={onPrevQuestion}
        nextQuestion={onNextQuestion}
        submitAnswer={onSubmitAnswer}
        submitLastAnswer={onLastSubmit}
        isFirstQuestion={currentQuestion === 0}
        isLastQuestion={currentQuestion === questionsCount - 1}
        questionOrder={currentQuestion + 1}
        reset={reset}
      />
    );
  } else {
    /* show custom content when final question is completed */
    header = null;
    body = children;
  }
  return (
    <div className={styles.root}>
      <FlowAssetHeader title={title}>{header}</FlowAssetHeader>
      {body}
    </div>
  );
};
