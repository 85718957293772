import { Icon } from "semantic-ui-react";
import classnames from "classnames/bind";

import styles from "./GroupImage.module.scss";

export interface Props {
  imageUrl: string | null;
  altText?: string;
}

const cx = classnames.bind(styles);

const NoImage = () => (
  <div className={styles.noImage} role="img" title="Generic Group Image">
    <Icon className={cx("users", styles.usersIcon)} />
  </div>
);

export const GroupImage: React.FC<Props> = ({ imageUrl, altText = "group image" }) => {
  return (
    <div className={styles.root}>
      <div className={styles.stretchContent}>
        {imageUrl ? <img className={styles.groupImage} src={imageUrl} alt={altText} /> : <NoImage />}
      </div>
    </div>
  );
};
