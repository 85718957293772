import classNames from "classnames/bind";
import styles from "./CardsRow.module.scss";

const cx = classNames.bind(styles);

export interface Props<T> {
  items: T[];
  renderCard: (item: T) => React.ReactNode;
  rowSize: number;
  currentIndex: number;
}

export const CardsRow = <T,>(props: Props<T>) => {
  const { items, rowSize, currentIndex } = props;
  let bottomAccessibleRange = rowSize * currentIndex;
  let topAccessibleRange = bottomAccessibleRange + rowSize - 1;

  return (
    <div className={styles.root} data-testid="cards-row">
      {items.map((card, idx) => {
        const isHidden = idx < bottomAccessibleRange || idx > topAccessibleRange;
        return (
          <div
            key={idx}
            className={cx(styles.cardContainer, {
              // Class is for testing purposes
              visible: !isHidden,
            })}
            data-testid={`carousel-card-${idx}`}
          >
            {/* Action will be replaced by the link later */}
            {props.renderCard(card)}
          </div>
        );
      })}
    </div>
  );
};
