import styles from "./QuestionForm.module.scss";

interface Props {
  question: React.ReactNode;
  buttons: React.ReactNode;
}

export const QuestionForm: React.FC<Props> = ({ question, buttons }) => {
  return (
    <div className={styles.root}>
      <div className={styles.question}>{question}</div>
      <div className={styles.footer}>{buttons}</div>
    </div>
  );
};
