import classNames from "classnames/bind";
import { debounce, escapeRegExp } from "lodash";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { Icon, Search, SearchProps, SearchResultData } from "semantic-ui-react";
import { usePageChange } from "../../../Application/hooks/usePageChange";
import { getParamValue } from "../../../Application/utils/routing";

import { useDispatch } from "react-redux";
import { Routes } from "../../../common/routes";
import { SEARCH_TERM } from "../../constants";
import { updateTerm } from "../../redux/searchResults/searchResultsSlice";
import { fetchSearchResultsDiscover } from "../../services/searchResultsService";
import styles from "./AppSearch.module.scss";

const cx = classNames.bind(styles);

interface Props {
  placeholder: string;
}

// Semantic ui react's search component expects options in a particular fashion
// Once we decide to populate the search with options, we can include this value
// perhaps as a state inside the AppSearch below
// const availableOptions: SearchProps[] = [];

const resultRenderer = ({ title, all }: SearchProps) => {
  if (all) {
    return <span className={styles.allResults} dangerouslySetInnerHTML={{ __html: title }} />;
  }
  return <span dangerouslySetInnerHTML={{ __html: title }} />;
};

export const AppSearch: React.FC<Props> = ({ placeholder }) => {
  const { t } = useTranslation("application");
  const searchParams = useSearchParams()[0];
  const navigate = useNavigate();

  const ref = useRef<HTMLInputElement>(null);
  const [query, setQuery] = useState("");
  const [results, setResults] = useState<SearchProps[]>([]);
  const [focused, setFocused] = useState(false);

  const dispatch = useDispatch();
  const { pathname } = useLocation();

  usePageChange(
    // I wrap this in useCallback, otherwise the function
    // is remade on every render and thus calls useEffect in the hook
    useCallback(() => setQuery(""), []),
    "discover",
  );

  /**
   * Sets results object based off search string
   */
  const updateResults = useCallback(
    async (search: string) => {
      const resultsCount = 5;
      const searchedResults = await fetchSearchResultsDiscover(search, {}, undefined, undefined, resultsCount);
      const re = new RegExp(escapeRegExp(search), "i");
      const newOptions: any[] = [];
      for (const value of Object.values(searchedResults)) {
        newOptions.push({ title: value.title, display: value.title });
      }

      let matches = newOptions.map(option => {
        return re.exec(option.display);
      });
      let updatedResults: SearchProps[] = [];
      for (const [index, match] of matches.entries()) {
        if (match) {
          updatedResults.push({
            key: `${index}-${searchedResults[index].title}`,
            display: searchedResults[index].title,
            title: match.input.replace(match[0], `<strong>${match[0]}</strong>`),
          });
        }
      }
      updatedResults.push({
        display: search,
        title: `${t("search.searchedResults")} "<strong>${search}</strong>"`,
        // SearchProps are required to be strings
        all: "true",
      });
      setResults(updatedResults);
    },
    [setResults, t],
  );

  const debouncedSearchResults = useMemo(() => {
    return debounce(async () => {
      await updateResults(query);
    }, 300);
  }, [query, updateResults]);

  useEffect(() => {
    return () => {
      debouncedSearchResults.cancel();
    };
  }, [debouncedSearchResults]);

  useEffect(() => {
    (async () => {
      if (query) {
        await debouncedSearchResults()?.catch(e => console.error(e));
      }
    })();
  }, [query, debouncedSearchResults]);

  const updateQuery = useCallback(
    (newQuery: string) => {
      if (pathname === Routes.Discover.exact) {
        dispatch(updateTerm(newQuery));
      } else {
        const params = new URLSearchParams();
        params.append(SEARCH_TERM, newQuery);
        navigate({
          pathname: Routes.Discover.exact,
          search: params.toString(),
        });
      }

      // After an option is selected (or enter pressed), don't focus the input element anymore
      ref.current?.blur();
      // Sets "all results" to be the newly selected query
    },
    [dispatch, navigate, pathname],
  );

  useEffect(() => {
    if (searchParams) {
      const queryValue = getParamValue(searchParams.toString(), SEARCH_TERM);
      setQuery(queryValue);
    }
  }, [searchParams]);

  useEffect(() => {
    const handleSubmit = (e: KeyboardEvent) => {
      if (e.key === "Enter" && focused) {
        updateQuery(query);
      }
    };

    document.addEventListener("keydown", handleSubmit);
    return () => document.removeEventListener("keydown", handleSubmit);
  }, [focused, query, updateQuery]);

  const handleClear = useCallback(() => {
    setQuery("");
    dispatch(updateTerm(""));
    setResults([]);
    ref.current?.focus();
  }, [dispatch]);

  const handleSearchChange = useCallback(
    (_: any, data: SearchProps) => {
      const value = data.value as string;
      if (!value) {
        handleClear();
      } else {
        setResults(prevState => {
          const prevResults = [...prevState];
          if (prevResults.length >= 1) {
            prevResults.splice(-1, 1);
          }
          prevResults.push({
            display: value,
            title: `${t("search.searchedResults")} "<strong>${value}</strong>"`,
            // SearchProps are required to be strings
            all: "true",
          });
          return [...prevResults];
        });
      }
    },
    [t, handleClear],
  );

  const handleResultSelect = useCallback(
    (_: any, data: SearchResultData) => {
      setQuery(data.result.display);
      updateQuery(data.result.display);
    },
    [updateQuery],
  );

  return (
    <Search
      input={
        <div className={styles.inputWrapper}>
          <input
            className={cx(styles.searchInput, { [styles.focused]: focused })}
            ref={ref}
            value={query}
            onChange={e => setQuery(e.target.value)}
            onFocus={() => setFocused(true)}
            onBlur={() => setFocused(false)}
            placeholder={placeholder}
            title="Search"
            aria-label="Search"
          />
          <span className={styles.iconWrapper}>
            <Icon className={cx(styles.leftIcon, { [styles.active]: focused })} fitted name="search" />
          </span>
          {(focused || query !== "") && (
            <button className={styles.iconWrapper} onClick={handleClear} aria-label={t("search.clearButtonAlt")}>
              <Icon className={cx(styles.rightIcon, { [styles.active]: focused })} fitted name="times circle" />
            </button>
          )}
        </div>
      }
      fluid
      onSearchChange={handleSearchChange}
      onResultSelect={handleResultSelect}
      resultRenderer={resultRenderer}
      value={query}
      results={results}
      showNoResults={false}
    />
  );
};
