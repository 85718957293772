import { AppLoader } from "../AppLoader/AppLoader";

import styles from "./PageContent.module.scss";

interface Props {
  showLoader?: boolean;
  children: React.ReactNode;
}

export const PageContent: React.FC<Props> = ({ showLoader, children }) => {
  return showLoader ? (
    <AppLoader />
  ) : (
    <section data-testid="page-content-section" className={styles.mainContent}>
      {children}
    </section>
  );
};
