import { Icon } from "semantic-ui-react";
import classNames from "classnames/bind";
import styles from "./CarouselHeader.module.scss";
import { InvisibleButton } from "../../../../Application/layout/InvisibleButton/InvisibleButton";
import { useTranslation } from "react-i18next";

const cx = classNames.bind(styles);

export interface Props {
  title: string;
  onPrevious: () => void;
  onNext: () => void;
  canPrev: boolean;
  canNext: boolean;
}

export const CarouselHeader: React.FC<Props> = ({ title, onPrevious, onNext, canPrev, canNext }) => {
  const { t } = useTranslation("home");
  return (
    <div className={styles.header}>
      <h2 className={styles.title} tabIndex={0}>
        {title}
      </h2>
      <div className={styles.icons}>
        <InvisibleButton onClick={onPrevious} disabled={!canPrev} ariaLabel={t("carousel.goBack")}>
          <Icon link size="big" className="thin" data-testid="previous-page" name="angle left" disabled={!canPrev} />
        </InvisibleButton>
        <InvisibleButton onClick={onNext} disabled={!canNext} ariaLabel={t("carousel.goForward")}>
          <Icon
            link
            size="big"
            className={cx("thin", styles.rightIcon)}
            data-testid="next-page"
            name="angle right"
            disabled={!canNext}
          />
        </InvisibleButton>
      </div>
    </div>
  );
};
