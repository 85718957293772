import classNames from "classnames/bind";
import { SyntheticEvent, createRef, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./VideoDetails.module.scss";

const cx = classNames.bind(styles);

export interface Props {
  title: string;
  description: string;
}

type Tabs = "overview" | "relatedContent";

interface TabContent {
  [key: string]: React.ReactNode;
}

const tabContent = ({ title, description }: Props): TabContent => ({
  overview: (
    <div id="panel-1" role="tabpanel" tabIndex={0} aria-labelledby="tab-1">
      <div className={styles.title}>{title}</div>
      <div className={styles.description}>{description}</div>
    </div>
  ),
  resources: <div id="panel-2" role="tabpanel" tabIndex={0} aria-labelledby="tab-2"></div>,
});

export const VideoDetails: React.FC<Props> = ({ title, description }) => {
  const { t } = useTranslation(["flows"]);
  const defaultActiveTab = "overview";
  const [activeTab, setActiveTab] = useState<Tabs>(defaultActiveTab);

  const onTabClick = (e: SyntheticEvent) => {
    const tab = e.currentTarget.getAttribute("data-name") as Tabs;
    setActiveTab(tab || defaultActiveTab);
  };

  /* Accessibility stuff */
  let tabOverviewRef = createRef<HTMLDivElement>();
  let tabResourcesRef = createRef<HTMLDivElement>();
  const onKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.code === "ArrowRight") {
      setActiveTab("relatedContent");
      tabResourcesRef.current?.focus();
    }
    if (e.code === "ArrowLeft") {
      setActiveTab("overview");
      tabOverviewRef.current?.focus();
    }
  };

  return (
    <div className={styles.root}>
      <div className={styles.tabs} role="tablist">
        <div
          role="tab"
          tabIndex={activeTab === "overview" ? 0 : -1}
          aria-selected={activeTab === "overview"}
          aria-controls="panel-1"
          id="tab-1"
          data-name="overview"
          className={cx(styles.tab, {
            [styles.active]: activeTab === "overview",
          })}
          onClick={onTabClick}
          onKeyDown={onKeyDown}
          ref={tabOverviewRef}
        >
          {t("overview")}
        </div>
      </div>
      <section>{tabContent({ title, description })[activeTab]}</section>
    </div>
  );
};
