import { useEffect, useState } from "react";

import { VideoStreamManifest } from "../../../../types";
import { Stream } from "../utils/dashParser";
import { getSupportedStream } from "../utils/streamingUtils";

const useStreamingSettings = (manifests: VideoStreamManifest[]) => {
  const [settings, setSettings] = useState<Stream>();

  useEffect(() => {
    setSettings(getSupportedStream(manifests));
  }, [manifests]);

  return settings;
};

export default useStreamingSettings;
