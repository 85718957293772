import { noop } from "lodash";

export function loadState<T>(key: string): T | null {
  try {
    const serializedState = localStorage.getItem(key);
    if (!serializedState) return null;
    return JSON.parse(serializedState);
  } catch (_) {
    return null;
  }
}
export function saveState<T>(key: string, state: T): void {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem(key, serializedState);
  } catch (_) {
    noop();
  }
}
