import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";
import { Icon } from "semantic-ui-react";

import { BreakpointContext } from "../../../Application/components/context/BreakpointContext";
import { selectProfileError, selectProfileSaving } from "../../redux/profile/profileSlice";

import { FixedHeightAppPageWrapper } from "../../../Application/layout";
import { InvisibleButton } from "../../../Application/layout/InvisibleButton/InvisibleButton";
import { Segment } from "../../../Application/layout/Segments/Segment/Segment";
import { Segments } from "../../../Application/layout/Segments/Segments";
import { Toast, ToastType } from "../../../Application/layout/Toast/Toast";
import { Flyout } from "../../../Discover/layout/Flyout/Flyout";

import { MyData } from "../MyData/MyData";
import { MyInfo } from "../MyInfo/MyInfo";
import { Privacy } from "../Privacy/Privacy";
import { Standing } from "../Standing/Standing";
import { Terms } from "../Terms/Terms";

import { SendPageView } from "../../../Application/services/realTimeNotification/googleAnalytics";
import { CheckCircle } from "../../layout/shared";

import styles from "./Profile.module.scss";

export const Profile: React.FC = () => {
  const { t } = useTranslation("account");
  const { isMobileScreen } = useContext(BreakpointContext);
  // Prevents first load of component displaying toast notification
  const componentInitialized = useRef(false);
  const [displayToast, setDisplayToast] = useState(false);
  const saving = useSelector(selectProfileSaving);
  const errorMessage = useSelector(selectProfileError);

  useEffect(() => {
    document.title = "My Profile";
    SendPageView();
  }, []);

  useEffect(() => {
    if (!componentInitialized.current) {
      componentInitialized.current = true;
      return;
    }
    if (!saving && !errorMessage) setDisplayToast(true);
  }, [saving, errorMessage]);

  useEffect(() => {
    setDisplayToast(false);
  }, [isMobileScreen]);

  const clearToast = useCallback(() => {
    setDisplayToast(false);
  }, []);

  return (
    <FixedHeightAppPageWrapper reactStrictMode={false}>
      <div className={styles.root}>
        <h1 className={styles.header}>{t("profile.profile")}</h1>
        <div className={styles.body}>
          <Segments>
            <Segment to={"info"} label={t("profile.myInfo")} className={styles.segment} />
            <Segment to={"data"} label={t("profile.myData")} className={styles.segment} />
          </Segments>
          <div className={styles.contentContainer}>
            <div className={styles.content}>
              <Routes>
                <Route path="*" element={<Navigate to="info" replace />} />
                <Route path={"info"} element={<MyInfo />} />
                <Route path={"standing"} element={<Standing />} />
                <Route path={"privacy"} element={<Privacy />} />
                <Route path={"data"} element={<MyData />} />
                <Route path={"terms"} element={<Terms />} />
              </Routes>
            </div>
          </div>
        </div>
      </div>
      {isMobileScreen ? (
        <Flyout open={displayToast} absoluteSize={false} height={30}>
          <div className={styles.flyoutRoot}>
            <div className={styles.flyoutTitle}>
              <span>
                <CheckCircle saved={t("profile.saved")} />
              </span>
              <InvisibleButton onClick={clearToast}>
                <Icon name="times" />
              </InvisibleButton>
            </div>
            <div className={styles.toastBody}>{t("profile.saveSuccessful")}</div>
            <div className={styles.continueWrapper}>
              <button className={styles.continueButton} onClick={clearToast}>
                {t("profile.continue")}
              </button>
            </div>
          </div>
        </Flyout>
      ) : (
        <Toast
          visible={displayToast}
          type={ToastType.Success}
          body={<div className={styles.toastBody}>{t("profile.saveSuccessful")}</div>}
          onClose={clearToast}
          autoDismiss
        />
      )}
    </FixedHeightAppPageWrapper>
  );
};
