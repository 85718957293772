import { CompletionStatus } from "features/Flows/types/enums";
import { EntityType } from "features/common/types";
import { isUndefined } from "lodash";
import React from "react";
import { CardProgress } from "../../CardProgress/CardProgress";
import { AssetCard, AssetCardProps } from "../AssetCard/AssetCard";
import { CardItem } from "../AssetCard/types";

export const ProgressAssetCard = <T extends CardItem & { completionStatus?: CompletionStatus }>(
  props: AssetCardProps<T>,
) => {
  const {
    item: { completionStatus, entityType: type },
    wideBars,
  } = props;

  return (
    <AssetCard
      {...props}
      extraMeta={
        // Temporary hide events progress bar due to: https://brainstorm.atlassian.net/browse/SAAS-27005
        type !== EntityType.Event &&
        !isUndefined(completionStatus) && (
          <CardProgress completionStatus={completionStatus} wideBars={wideBars} contentType={type} />
        )
      }
    />
  );
};
