import { createAction, createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import { RootState } from "../../../Application/store/store";
import { RStatus } from "../../../Application/types";
import { isSessionExpiredInLocalTime } from "../../components/EventViewer/helpers/sessionDateFormatter";
import { EventReminder, EventSession, SessionRegistrationSuccess, SessionUnregistrationSuccess } from "../../types";

export interface EventSessionsState {
  eventId: number;
  status: RStatus;
  items: EventSession[];
  errorMessage: string | undefined;
  code: number;
  fromDateUtc: string;
  top: number;
  activePage: number;
  reachedEnd: boolean;
  reminders: EventReminder[];
}

export const initialState: EventSessionsState = {
  eventId: 0,
  items: [],
  status: RStatus.Idle,
  code: 0,
  errorMessage: undefined,
  fromDateUtc: dayjs().toISOString(),
  top: 10,
  activePage: 1,
  reachedEnd: false,
  reminders: [],
};

const name = "eventSessions";

const eventSessionsSlice = createSlice({
  name,
  initialState: initialState,
  reducers: {
    req(state, action: PayloadAction<{ id: number }>) {
      state.reachedEnd = false;
      state.items = [];
      state.status = RStatus.Pending;
      state.eventId = action.payload.id;
    },
    got(
      state,
      action: PayloadAction<{
        items: EventSessionsState["items"];
        reminders: EventReminder[];
      }>,
    ) {
      state.status = RStatus.Got;
      state.items = action.payload.items;
      state.reminders = action.payload.reminders;
    },
    fetchMore(state) {
      if (!state.reachedEnd) {
        state.status = RStatus.Pending;
        state.activePage = state.activePage + 1;
      }
    },
    outOfResults(state) {
      state.reachedEnd = true;
    },
    updateStatus(state, action: PayloadAction<{ status: RStatus }>) {
      state.status = action.payload.status;
    },
    registerOnSession(
      state,
      _: PayloadAction<{
        flowId?: number;
        eventId: number;
        sessionId: string;
        registrationType: number;
        sessionStartDate: string;
      }>,
    ) {
      return state;
    },
    registerOnSessionSuccess(state, action: PayloadAction<SessionRegistrationSuccess>) {
      const { sessionId, sessionStartDate } = action.payload;
      const id = getSessionId(state.items, sessionId, sessionStartDate);
      if (id >= 0) {
        state.items[id].wasRegistered = true;
        state.items[id].shouldRemind = true;
      }
    },
    unregisterFromSession(
      state,
      _: PayloadAction<{
        eventId: number;
        sessionId: string;
        sessionStartDateTime: string;
      }>,
    ) {
      return state;
    },
    unregisterFromSessionSuccess(state, action: PayloadAction<SessionUnregistrationSuccess>) {
      const { sessionId, sessionStartDate } = action.payload;
      const id = getSessionId(state.items, sessionId, sessionStartDate);
      state.items[id].wasRegistered = false;
      state.items[id].shouldRemind = false;
    },
    err(
      state,
      action: PayloadAction<{
        errorMessage: string | undefined;
        code: number | undefined;
      }>,
    ) {
      state.status = RStatus.Error;
      state.code = action.payload.code ?? 0;
      state.errorMessage = action.payload.errorMessage;
    },
    reset() {
      return initialState;
    },
  },
});

const getSessionId = (items: EventSession[], id: string, startDate: string) =>
  items.findIndex(x => x.sessionId === id && x.startDateTime === startDate);

export const {
  req,
  got,
  fetchMore,
  outOfResults,
  updateStatus,
  registerOnSession,
  registerOnSessionSuccess,
  unregisterFromSession,
  unregisterFromSessionSuccess,
  err,
  reset,
  remindMe,
} = {
  ...eventSessionsSlice.actions,
  remindMe: createAction<{
    shouldRemind: boolean;
    sessionId: string;
    sessionStartDate: string;
  }>(`${name}/remind-me`),
};

export const selectSessionsState = (state: RootState) => state.events.sessions;
export const selectSessions = createSelector(
  (state: RootState) => state.events.sessions.items,
  sessions => {
    const browserTimeZone = dayjs.tz.guess();
    return sessions.filter(session => !isSessionExpiredInLocalTime(session.endDateTimeUtc, browserTimeZone));
  },
);
export const selectSessionsCount = (state: RootState) => selectSessions(state).length;
export const selectSessionsStatus = (state: RootState) => state.events.sessions.status;
export const selectSessionsError = (state: RootState) => state.events.sessions.errorMessage;
export const selectSessionsStatusCode = (state: RootState) => state.events.sessions.code;
export const selectSessionsActivePage = (state: RootState) => state.events.sessions.activePage;
export const selectSessionsReachedEnd = (state: RootState) => state.events.sessions.reachedEnd;
export const selectSessionsReminders = (state: RootState) => state.events.sessions.reminders;
export const selectAreAllSessionsUnavailable = (state: RootState) =>
  state.events.sessions.status === RStatus.Got && selectSessionsCount(state) === 0 && selectSessionsReachedEnd(state);

export default eventSessionsSlice.reducer;
