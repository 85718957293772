import dayjs from "dayjs";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import weekOfYear from "dayjs/plugin/weekOfYear";

dayjs.extend(isSameOrBefore);
dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.extend(weekOfYear);

export const WEEK_LENGTH = 7;

export function getFormattedDate(date: Date) {
  const day = date.getDate().toString();
  const formattedDay = day.length === 1 ? `0${day}` : day;
  return `${date.getMonth() + 1}/${formattedDay}/${date.getFullYear()}`;
}

export const ticksToMoment = (dateTime: string, tz: string = "UTC"): number => {
  dayjs.tz.setDefault(tz);
  return dayjs(dateTime, tz).diff(dayjs());
};

export const isTicksBefore = (dateTime: string, tz: string = "UTC") => {
  dayjs.tz.setDefault(tz);
  return dayjs(dateTime, tz).isBefore(dayjs());
};

export const getWeekNumber = (date: string): number => {
  return Math.floor(dayjs(date).date() / WEEK_LENGTH);
};

export const getWeekDayName = (date: string): string => {
  return new Date(date).toLocaleString("en-us", { weekday: "long" });
};

export const isLastWeekInMonth = (date: string): boolean => {
  const weekInMs = 604800000;
  return new Date(new Date(date).getTime() + weekInMs).getDate() === 1;
};

export const getMonthName = (date: string): string => {
  return new Date(date).toLocaleString("en-us", { month: "long" });
};

export const checkDateIsBeforeNow = (date: string): boolean => {
  if (!date || !dayjs(date).isValid()) return false;
  return dayjs(date).isSameOrBefore(dayjs());
};
