import axios from "axios";
import http from "../../Application/http/http";
import {
  Event,
  EventReminder,
  EventSession,
  GetEventAppointmentRequest,
  GetEventAppointmentResponse,
  RemindMeRequest,
  SessionRegistrationRequest,
  TrackActivityRequest,
} from "../types";

const v1 = "/api/client/external-events";
const v4 = "/api/v4/client/external-events";

export async function fetchEvent(id: number) {
  return http.get<Event>(`${v4}/${id}`);
}

export async function fetchEventReminders(id: number) {
  const resp = await axios.get<EventReminder[]>(`${v4}/${id}/reminders`);
  return resp.data;
}

export async function fetchExternalEventSessionsById(
  id: number,
  fromDateUtc: string,
  skip: number = 0,
  top: number = 10,
) {
  const config = {
    params: {
      fromDateUtc,
      top,
      skip,
    },
  };

  const resp = await axios.get<{ sessions: EventSession[] }>(`${v4}/${id}/sessions/appointments`, config);

  return resp.data;
}

export async function registerOnEventSessionV4(request: SessionRegistrationRequest) {
  const payload = {
    sessionStartDate: request.sessionStartDate,
    registrationType: request.registrationType,
  };

  await axios.post(`${v4}/${request.eventId}/sessions/${request.sessionId}/registrations`, {
    ...payload,
  });
}

export async function unregisterFromEventSessionV4(eventId: number, sessionId: string, sessionStartDate: string) {
  const data = {
    sessionStartDateTime: sessionStartDate,
  };

  await axios.delete(`${v4}/${eventId}/sessions/${sessionId}/registrations`, { data: data });
}

export function fetchAppointmentInfo(payload: GetEventAppointmentRequest) {
  const { eventId, sessionId, sessionStartDate } = payload;
  return http.get<GetEventAppointmentResponse>(
    `${v1}/${eventId}/sessions/${sessionId}/link?sessionStartDate=${sessionStartDate}`,
  );
}

export async function trackAttendance(request: TrackActivityRequest) {
  const { sessionId, sessionStartDate, attendedDate } = request;
  await axios.post(`${v1}/sessions/${sessionId}/attendance`, {
    sessionStartDate,
    attendedDate,
  });
}

export async function remindMe(request: RemindMeRequest) {
  const { sessionId, sessionStartDate, shouldRemind } = request;

  await axios.post(`${v1}/sessions/${sessionId}/reminder`, {
    shouldRemind,
    sessionStartDate,
  });
}
