import {
  DefaultHttpClient,
  HubConnection,
  HubConnectionBuilder,
  IHttpConnectionOptions,
  LogLevel,
} from "@microsoft/signalr";
import RtnHandler from "./rtnHandler";

export const receiveMessage = "ReceiveMessage";

class RtnLogger {
  log(logLevel: LogLevel, message: string) {
    if (logLevel === LogLevel.Error) {
      this.error(message);
    } else {
      this.trace(message);
    }
  }

  trace(message: string) {
    console.log(message);
    if (window.appInsights) {
      window.appInsights.trackTrace({ message: message });
    }
  }

  error(message: string) {
    console.error(message);
    if (window.appInsights) {
      window.appInsights.trackException({ exception: new Error(message) });
    }
  }
}

export default class RealTimeNotificationClient {
  private logger;
  private rtnHandler;
  private connectionBuilder;
  private setSignalRState;
  private connection?: HubConnection;

  constructor(
    hubUrl: string,
    getAccessToken: () => Promise<string | undefined>,
    rtnHandler: ReturnType<typeof RtnHandler>,
    setSignalRState: (isConnected: boolean) => void,
  ) {
    this.logger = new RtnLogger();
    this.setSignalRState = setSignalRState;
    this.rtnHandler = rtnHandler;
    this.connectionBuilder = new HubConnectionBuilder()
      .withUrl(hubUrl, {
        logger: LogLevel.Error,
        accessTokenFactory: () => {
          this.logger.log(LogLevel.Trace, "Token accessed");
          return getAccessToken();
        },
        httpClient: new DefaultHttpClient(this.logger),
      } as IHttpConnectionOptions)
      .withAutomaticReconnect({
        nextRetryDelayInMilliseconds: () => 2000,
      })
      .configureLogging(this.logger);
  }

  subscribe(connection: HubConnection) {
    connection.on(receiveMessage, (type, payload) => {
      this.logger.log(LogLevel.Trace, `Message received. Type: ${type}. Payload: ${payload}`);
      this.rtnHandler.handle(`notification:${type}`, payload);
    });

    connection.onclose(error => {
      this.setSignalRState(false);
      (async () => {
        if (error) {
          this.logger.log(LogLevel.Error, `Got disconnected from SignalR hub: ${error.message}.`);
        }
        await connection.start();
      })();
    });
  }

  async connect() {
    this.connection = this.connectionBuilder.build();
    this.subscribe(this.connection);

    await this.start(this.connection);
  }

  async disconnect(reconnect = false) {
    try {
      if (!reconnect) {
        this.setSignalRState(false);
      }
      await this.connection?.stop();
      this.logger.log(LogLevel.Trace, "SignalR hub disconnected successfully.");
    } catch (error: any) {
      this.logger.log(LogLevel.Error, `SignalR disconnect failed: ${error.message}.`);
    }
  }

  private async start(connection: HubConnection) {
    try {
      await connection.start();
      this.setSignalRState(true);
      this.logger.log(LogLevel.Trace, "SignalR hub connection started successfully.");
    } catch (error: any) {
      this.setSignalRState(false);
      this.logger.log(LogLevel.Error, `SignalR hub connection failed to start: ${error.message}.`);
    }
  }
}
