import { useSelector } from "react-redux";
import { Progress } from "../types";
import { selectSignalRStatus } from "../../Application/slices/signalRSlice";
import { useEffect, useRef } from "react";
import { fetchFlowProgress } from "../services/flowsService";
import environmentConfig from "../../../environmentConfig";

export const useProgressPolling = (flowId: number | undefined, cb: (payload: Progress) => void) => {
  const signalRConnected = useSelector(selectSignalRStatus);
  const intervalId = useRef<undefined | NodeJS.Timeout>();
  const prevStatus = useRef<undefined | boolean>();

  useEffect(() => {
    if (!flowId) return;

    const updateStatus = async () => {
      const progress = await fetchFlowProgress(flowId);
      cb(progress);
    };

    if (signalRConnected !== prevStatus.current) {
      if (!signalRConnected) {
        clearInterval(intervalId.current);
        const id = setInterval(updateStatus, environmentConfig.flowPollingInterval);
        intervalId.current = id;
      } else {
        // Calls once to update progress in case some rtns were lost
        // e.g. we start flow before a connection was established
        updateStatus();
        clearInterval(intervalId.current);
        intervalId.current = undefined;
      }
      prevStatus.current = signalRConnected;
    }

    return () => {
      clearInterval(intervalId.current);
    };
  }, [cb, flowId, signalRConnected]);
};
