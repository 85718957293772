import { AppNavigationLink } from "./AppNavigationLink";
import classNames from "classnames/bind";

import styles from "./AppNavigation.module.scss";

const cx = classNames.bind(styles);

export interface NavigationLink {
  to: string;
  label: string;
  disabled?: boolean;
}

interface Props {
  isMobileView: boolean;
  links: NavigationLink[];
}

export const AppNavigation: React.FC<Props> = ({ links, isMobileView }) => {
  const className = cx(styles.root, {
    [styles.isMobile]: isMobileView,
  });
  return (
    <nav className={className} role="navigation">
      <ul className={styles.list}>
        {links.map(l => (
          <li key={l.to}>
            <AppNavigationLink link={l} disabled={l.disabled} />
          </li>
        ))}
      </ul>
    </nav>
  );
};
