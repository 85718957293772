import { call, put, select, takeLatest } from "redux-saga/effects";
import { req, got, err, updateImage, ProfileState, setImage, done, selectProfile } from "./profileSlice";
import { Profile } from "../../types";
import { fetchProfile, ReadImage, UpdateImageOnServer, DeleteImageOnServer } from "../../services/profileService";

export function* handleFetchProfile() {
  try {
    const profile: Profile = yield call(fetchProfile);
    yield put(got({ profile }));
  } catch (error: any) {
    yield put(err({ errorMessage: error?.message }));
  }
}

export function* handleSetImage(action: ReturnType<typeof setImage>) {
  const state: ProfileState = yield select(selectProfile);
  if (!state.current) return;
  let image: string | null = null;
  if (action.payload.image !== null) {
    image = yield call(ReadImage, action.payload.image);
  }
  yield put(
    got({
      profile: {
        ...state.current,
        imageUrl: image,
      },
    }),
  );
}

export function* handleUpdateImage() {
  const state: ProfileState = yield select(selectProfile);
  if (!state.current) {
    yield put(done({ errorMessage: "Failed to retrieve profile." }));
    return;
  }

  try {
    if (state.imageFile) {
      yield call(UpdateImageOnServer, state.imageFile);
    } else {
      yield call(DeleteImageOnServer);
    }
    yield put(done({}));
  } catch (error: any) {
    yield put(err({ errorMessage: error?.message }));
  }
}

export function* profileWatcherSaga() {
  yield takeLatest(req.type, handleFetchProfile);
  yield takeLatest(setImage, handleSetImage);
  yield takeLatest(updateImage, handleUpdateImage);
}
