import styles from "./FlowCourseHeader.module.scss";
import classNames from "classnames/bind";
import { FlowProgressLine } from "../FlowProgressLine/FlowProgressLine";
import { ReactComponent as SectionHeaderIcon } from "../../../../../assets/images/section-header-icon.svg";
import { Icon } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { TextTruncator } from "features/Application/layout/TextTruncator/TextTruncator";

const cx = classNames.bind(styles);

export interface Props {
  id: string;
  name: string;
  isFirst: boolean;
  description: string;
  disabled: boolean;
  onClick: () => void;
  collapsedNotMobile: boolean;
  opened: boolean;
  coursePassed: boolean;
}

export const FlowCourseHeader: React.FC<Props> = ({
  name,
  disabled,
  isFirst,
  onClick,
  collapsedNotMobile,
  opened,
  coursePassed,
}) => {
  const { t } = useTranslation(["flows"]);
  return (
    <div
      className={styles.root}
      onClick={onClick}
      aria-label={t(opened ? "hideCourseElements" : "openCourseElements")}
      data-testid="course-header"
      tabIndex={0}
    >
      <div className={styles.bulletBox}>
        <div
          className={cx(styles.square, {
            [styles.coursePassed]: coursePassed,
            [styles.disabled]: !isFirst && disabled,
          })}
        ></div>
        <FlowProgressLine isFirst={isFirst} disabled={disabled} />
      </div>
      <div
        className={cx(styles.stripe, {
          [styles.hidden]: collapsedNotMobile,
        })}
      ></div>
      <div
        className={cx(styles.tile, {
          [styles.hidden]: collapsedNotMobile,
        })}
      >
        <SectionHeaderIcon height={24} width={24} />
        <div aria-label={name} tabIndex={0} className={styles.title}>
          <TextTruncator clamp={3} tooltipContent={name}>
            {name}
          </TextTruncator>
        </div>
        <Icon className={styles.dropdownIcon} name={opened ? "angle up" : "angle down"} fitted />
      </div>
    </div>
  );
};
