import { EntityType } from "../types";
import { CompletionStatus } from "../../Flows/types/enums";

export interface Record {
  entityType: EntityType;
  completionStatus?: CompletionStatus;
}

export const mapCompletionStatus = (record: Record): CompletionStatus | undefined => {
  const entitiesWithCompletionStatus = [
    EntityType.Flow,
    EntityType.Video,
    EntityType.Survey,
    EntityType.Assessment,
    EntityType.Event,
    EntityType.Pdf,
  ];

  return record && entitiesWithCompletionStatus.includes(record.entityType) ? record.completionStatus : undefined;
};
