import { ChangeEvent, useCallback, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { Icon, Input, InputOnChangeData } from "semantic-ui-react";

import { BreakpointContext } from "../../../Application/components/context/BreakpointContext";
import { InvisibleButton } from "../../../Application/layout";
import { RStatus } from "../../../Application/types";
import { Group, GroupMember as Member } from "../../types";
import { GroupMembersList } from "./GroupMembersList";
import { NoMembersSearchResult } from "./NoMembersSearchResult";
import { NoMembersText } from "./NoMembersText";

import styles from "./GroupMembers.module.scss";

type InputChangeHandler = (e: ChangeEvent, data: InputOnChangeData) => void;

export interface Props {
  group: Group;
  handleFilterChange: (value: string) => void;
  handleClose: () => void;
  fetchMore: () => void;
  status: RStatus;
  reachedEnd: boolean;
  inlineCount: number;
  error?: string;
  membersToShow?: Member[];
  searchTerm?: string;
}

export const GroupMembers: React.FC<Props> = ({
  group,
  membersToShow,
  handleFilterChange,
  handleClose,
  fetchMore,
  status,
  reachedEnd,
  error,
  searchTerm,
  inlineCount = 0,
}) => {
  const { t } = useTranslation("groups");
  const { isLargeScreen } = useContext(BreakpointContext);
  const [searchInput, setSearchInput] = useState(searchTerm ?? "");

  const handleChange: InputChangeHandler = useCallback(
    (_, { value }) => {
      setSearchInput(value);
      handleFilterChange(value);
    },
    [handleFilterChange],
  );

  if (group.isOpen && group.membersCount === 0) {
    return (
      <div className={styles.noMembers}>
        <NoMembersText text={t("noOtherMembers")} />
      </div>
    );
  }

  return (
    <>
      <div className={styles.memberCountContainer}>
        <div className={styles.memberCountHeaderWrapper}>
          <Icon name="group" fitted />
          <h2 className={styles.memberCount}>{t("member_plural_after", { count: group.membersCount })}</h2>
        </div>
        {!isLargeScreen && (
          <div>
            <InvisibleButton
              ariaLabel={t("closeMembersList")}
              data-testid="Close Members Section"
              onClick={handleClose}
            >
              <Icon name="times" />
            </InvisibleButton>
          </div>
        )}
      </div>
      <div className={styles.inputWrapper}>
        <Input
          data-testid="Member List Filter"
          fluid
          icon={<Icon name="search" />}
          iconPosition="left"
          value={searchInput}
          onChange={handleChange}
          placeholder={t("searchForPeople")}
          disabled={!group.isOpen && !group.joinedDate}
          className="member-list-filter"
        />
      </div>
      {group.membersCount > 0 && inlineCount === 0 && !!searchTerm && status !== RStatus.Pending ? (
        <NoMembersSearchResult />
      ) : (
        <GroupMembersList
          status={status}
          reachedEnd={reachedEnd}
          error={error}
          members={membersToShow}
          isGroupPrivate={!group.isOpen}
          fetchMore={fetchMore}
        />
      )}
    </>
  );
};
