export const withAbortSignal = <T extends Array<any>, TResult>(func: (...args: [...T, AbortSignal]) => TResult) => {
  let abortController = new AbortController();
  return (...args: T): TResult => {
    abortController.abort();
    abortController = new AbortController();
    return func(...args, abortController.signal);
  };
};

export default withAbortSignal;
