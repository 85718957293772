import React, { StrictMode } from "react";
import styles from "./FlowPageWrapper.module.scss";

interface Props {
  children: React.ReactNode;
}

export const FlowPageWrapper: React.FC<Props> = ({ children }) => {
  return (
    <StrictMode>
      <div className={styles.root}>{children}</div>
    </StrictMode>
  );
};
