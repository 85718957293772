import { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { DropdownItemProps, Icon } from "semantic-ui-react";
import { CustomDropdown } from "../components/CustomDropdown/CustomDropdown";
import { InvisibleButton } from "../../Application/layout/InvisibleButton/InvisibleButton";
import { BreakpointContext } from "../../Application/components/context/BreakpointContext";

import styles from "./useSort.module.scss";
interface Props {
  dropdownOptions: DropdownItemProps[];
  onChange?: (newValue: any) => void;
  defaultValue?: string | number;
  includeTextOnToggle?: boolean;
}

const useSort = ({ dropdownOptions, onChange, defaultValue, includeTextOnToggle = true }: Props) => {
  const breakpoint = useContext(BreakpointContext);
  const { t } = useTranslation(["discover"]);
  const [showSort, setShowSort] = useState(false);

  // Don't persist the dropdown state when changing from mobile to larger (or vice versa)
  useEffect(() => {
    setShowSort(false);
  }, [breakpoint.isMobileScreen]);

  const toggleSort = useCallback(() => {
    setShowSort(!showSort);
  }, [showSort]);

  const toggler = breakpoint.isMobileScreen && (
    <InvisibleButton onClick={toggleSort} ariaLabel={t("sort.label")} data-testid="sort button">
      <span className={styles.container}>
        <Icon name="sort amount down" />
        {includeTextOnToggle && <label className={styles.sortLabel}>{t("sort.label")}</label>}
      </span>
    </InvisibleButton>
  );

  const dropdown = (
    <CustomDropdown
      options={dropdownOptions}
      isMobile={breakpoint.isMobileScreen}
      isVisible={showSort}
      toggleVisible={toggleSort}
      onChange={onChange}
      defaultValue={defaultValue}
    />
  );

  return { toggler, dropdown, sortOpen: showSort, setSortOpen: setShowSort };
};

export default useSort;
