import { Dispatch } from "redux";

import RealTimeNotificationClient from "./realTimeNotificationClient";
import RtnEventsEmitter, { createRtnHandler } from "./rtnEventsEmitter";

import environmentConfig from "../../../../environmentConfig";

import { AsyncOperationType } from "../../slices/asyncOperationSlice";
import eventTypes from "./events";
import createMapping from "./handlersMapping";
import { accessTokenFactory } from "../../../Auth/config/userManager";

export default function initializeHubClient(
  dispatchCallToStore: Dispatch,
  asyncActionsProvider: () => AsyncOperationType,
  setSignalRState: (isConnected: boolean) => void,
) {
  // extend handlers mapping to have access to store dispatch
  // as there will be actions dispatched to store
  const handlersMapping = createMapping(dispatchCallToStore);
  RtnEventsEmitter.subscribeFromMapping(eventTypes, handlersMapping);
  return new RealTimeNotificationClient(
    environmentConfig.realTimeNotificationUrl,
    accessTokenFactory,
    createRtnHandler(asyncActionsProvider, dispatchCallToStore),
    setSignalRState,
  );
}
