import React from "react";
import { useTranslation } from "react-i18next";

import { ReactComponent as TeamsMessage } from "../../../../assets/images/teams-message.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector } from "react-redux";

import { assetStatusSelector } from "../../redux/flow/flowSelectors";
import { ProgressStatus } from "../../types";
import styles from "./flowMessage.module.scss";
import { faOctagonExclamation } from "@fortawesome/pro-solid-svg-icons";

export interface Props {
  isBranched: boolean;
}

export const FlowMessage: React.FC<Props> = ({ isBranched }) => {
  const { t } = useTranslation(["assets"]);
  const assetStatus = useSelector(assetStatusSelector);

  const messageMap: { [key in ProgressStatus | "default"]?: string } = {
    [ProgressStatus.Active]: t(isBranched ? "messages.placeholders.branched" : "messages.placeholders.default"),
    [ProgressStatus.Completed]: t("messages.placeholders.completed"),
    default: "",
  };

  return (
    <div className={styles.root}>
      <TeamsMessage />
      <div className={styles.placeholder}>
        {isBranched && <FontAwesomeIcon className={styles["placeholder-icon"]} icon={faOctagonExclamation} />}
        <span className={styles["placeholder-text"]}>{messageMap[assetStatus ?? "default"]}</span>
      </div>
    </div>
  );
};
