import { useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";

import { AssessmentQuestion as Question } from "../../../../Assets/types";
import { AssessmentQuestion } from "../AssessmentQuestion/AssessmentQuestion";
import { QuestionForm } from "../QuestionForm/QuestionForm";
import { Button } from "../../../../Application/layout";

interface Props {
  onSubmitAnswer: (a: boolean[]) => void;
  question?: Question;
  isLastQuestion: boolean;
  reset?: boolean;
}

export const AssessmentQuestionForm: React.FC<Props> = ({ question, onSubmitAnswer, isLastQuestion, reset }) => {
  const { t } = useTranslation("assets");
  const [selectedAnswers, setSelectedAnswers] = useState<boolean[]>([]);

  const submitAnswer = useCallback(() => {
    onSubmitAnswer(selectedAnswers);
    setSelectedAnswers([]);
  }, [onSubmitAnswer, selectedAnswers]);

  useEffect(() => {
    if (reset) {
      setSelectedAnswers?.([]);
    }
  }, [reset]);

  if (!question) return null;

  return (
    <QuestionForm
      question={
        <AssessmentQuestion
          key={question.text}
          question={question}
          setSelectedAnswers={setSelectedAnswers}
          selectedAnswers={selectedAnswers}
        />
      }
      buttons={
        <Button onClick={submitAnswer} disabled={!selectedAnswers.some(a => a)}>
          {isLastQuestion ? t("assessments.finish") : t("assessments.submit")}
        </Button>
      }
    />
  );
};
