import classNames from "classnames/bind";
import { Fragment } from "react";
import { Icon, Placeholder, PlaceholderLine, PlaceholderParagraph } from "semantic-ui-react";
import styles from "./CardMeta.module.scss";

interface Props {
  stretch?: boolean;
  stacked?: boolean;
  light?: boolean;
  children?: React.ReactNode;
  extra?: React.ReactNode;
  loading?: boolean;
}

const IconSpacer = () => (
  <span className={styles.iconContainer} data-testid="spacer">
    <Icon name="circle" size="mini" fitted />
  </span>
);

export const CardMeta: React.FC<Props> = ({ children, stretch, stacked, light, extra, loading }) => {
  const cx = classNames.bind(styles);

  const getPlaceholder = () => (
    <Placeholder>
      <PlaceholderParagraph>
        <PlaceholderLine />
      </PlaceholderParagraph>
    </Placeholder>
  );

  const getContent = () =>
    Array.isArray(children)
      ? children.map((child, idx) => {
          // Need to have a spacing circle after all elements (except final)
          return (
            <Fragment key={idx}>
              {child}
              {idx !== children.length - 1 ? <IconSpacer /> : null}
            </Fragment>
          );
        })
      : children;

  return (
    <div
      className={cx(styles.root, {
        [styles.stacked]: stacked,
      })}
    >
      <div
        className={cx(styles.meta, {
          [styles.stretch]: stretch,
          [styles.light]: light,
        })}
      >
        {loading ? getPlaceholder() : getContent()}
      </div>
      <div className={cx(styles.extra)}>
        {loading ? <div className={cx(styles.placeholder)}>{getPlaceholder()}</div> : extra}
      </div>
    </div>
  );
};
