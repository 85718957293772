import React, { Key, useEffect, useState } from "react";
import { useLDClient, withLDProvider } from "launchdarkly-react-client-sdk";
import { useSelector } from "react-redux";
import environmentConfig from "../../environmentConfig";

import { selectAccount } from "../../features/Account/redux/account/accountSlice";
import { selectCurrentProfile } from "../../features/Account/redux/profile/profileSlice";
import { AppLoader } from "../../features/Application/layout";

type LdIdentityProps = {
  children?: React.ReactNode;
};

/* istanbul ignore next */
const LdIdentity = (props: LdIdentityProps) => {
  const client = useLDClient();
  const [initialized, setInitialized] = useState(false);
  const account = useSelector(selectAccount);
  const profile = useSelector(selectCurrentProfile);

  useEffect(() => {
    if (!initialized && profile && account && client) {
      const { id, department } = profile;
      const { id: accountId, accountTypeId } = account;

      client.identify(
        {
          key: id.toString(),
          custom: {
            accountId,
            accountTypeId,
            originAccountId: accountId,
            ...(department && { department }),
          },
        },
        undefined,
        () => {
          setInitialized(true);
        },
      );
    }
  }, [client, account, profile, initialized]);

  return <>{initialized ? props.children : <AppLoader />}</>;
};

export default withLDProvider<{
  children?: React.ReactNode;
  key?: Key | null;
}>({
  clientSideID: environmentConfig.launchDarkly.clientId,
  reactOptions: {
    useCamelCaseFlagKeys: false,
  },
  flags: environmentConfig.launchDarkly.defaultFlags,
})(LdIdentity);
