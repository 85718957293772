import classNames from "classnames/bind";
import { MutableRefObject, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { VideoDetails } from "../../../Assets/components/VideoAssets/VideoDetails/VideoDetails";
import { VideoPlayer } from "../../../Assets/components/VideoAssets/VideoPlayer/VideoPlayer";
import { completeVideoAsset, nextAsset, previousAsset, startTransition } from "../../redux/flow/flowSlice";
import { selectProgress } from "../../redux/flow/flowSelectors";
import { markVideoAsStarted } from "../../services/flowsService";
import styles from "./FlowVideo.module.scss";
import { datadogRum } from "@datadog/browser-rum";
import { FlowVideoAsset } from "features/Flows/types";

const cx = classNames.bind(styles);

export interface Props {
  order: number;
  video: FlowVideoAsset;
  hidden: boolean;
  resetAssetRef?: MutableRefObject<(() => void) | undefined>;
}

export const FlowVideo: React.FC<Props> = ({ order, video, hidden, resetAssetRef }) => {
  const { title, description, id } = video;
  const { flowId } = useParams();
  const dispatch = useDispatch();
  const progress = useSelector(selectProgress);
  const canNext = progress.selectedAssetIndex < progress.viewedAssetIndexes?.length - 1;
  const canPrevious = progress.selectedAssetIndex > 0;

  const onNext = useCallback(() => {
    dispatch(nextAsset({ assetOrder: order }));
  }, [dispatch, order]);

  const onPrevious = useCallback(() => {
    dispatch(previousAsset());
  }, [dispatch]);

  const onVideoStart = useCallback(() => {
    datadogRum.addAction("On flow video starting", {
      flowId: flowId,
      videoId: id,
    });

    (async () => {
      if (flowId && id) await markVideoAsStarted(+flowId, id);
      datadogRum.addAction("On flow video started", {
        flowId: flowId,
        videoId: id,
      });
    })();
  }, [flowId, id]);

  const onVideoEnd = useCallback(() => {
    (async () => {
      if (flowId && id) {
        dispatch(
          startTransition({
            assetOrder: order,
          }),
        );
        dispatch(
          completeVideoAsset({
            flowId: +flowId,
            assetId: id,
            assetOrder: order,
          }),
        );
      }
    })();
  }, [dispatch, flowId, id, order]);

  return (
    <section className={cx(styles.root, { [styles.hidden]: hidden })}>
      <VideoPlayer
        id={video.id}
        sources={video.manifests}
        closedCaptions={video.closedCaptions}
        onNext={onNext}
        onPrevious={onPrevious}
        canNext={canNext}
        canPrevious={canPrevious}
        onVideoStarted={onVideoStart}
        onVideoEnded={onVideoEnd}
        resetAssetRef={resetAssetRef}
      />
      <div className={styles.detailsWrapper}>
        <VideoDetails title={title} description={description ?? ""} />
      </div>
    </section>
  );
};
