import { z } from "zod";
import { contentCoreSchema, discoveryItemSchema, searchResultSchema } from "./schemas";

export type ContentCore = z.infer<typeof contentCoreSchema>;

export enum SortType {
  Relevant,
  Recent,
}

export enum PriorityLevel {
  None = 0,
  Normal,
  Important,
  Required,
}

export type DiscoveryItem = z.infer<typeof discoveryItemSchema>;
export type SearchResult = z.infer<typeof searchResultSchema>;

export enum FilterType {
  CheckboxArray,
}
export type FilterValue = string | number;

export interface Filter<TNames extends string = string> {
  /** How we include it in the query parameters */
  key: `${TNames}`;
  disabled?: boolean;
  items: FilterItem<TNames>[];
  type: FilterType;
  dependentFilters?: TNames[];
}

export interface FilterItem<TName extends string = string> {
  /** How we look it up in the translation */
  id: string;
  value: FilterValue;
  subfilter?: Filter<TName>;
}

export const asFilter = <T extends string>(filter: Filter<T>): Filter<T> => {
  return filter;
};

export const asFilters = <T extends string>(filters: Filter<T>[]): Filter<T>[] => {
  return filters;
};

export type FilterNames<T> = T extends Filter<infer R> ? R : T extends Filter<infer R>[] ? R : never;

export interface StringMapping {
  [key: string]: string;
}

export interface FilterSlice {
  [key: string]: FilterValue[];
}

export interface Publisher {
  id: number;
  name: string;
}

export interface Application {
  name: string;
  count: number;
}
