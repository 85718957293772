import { AxiosResponse } from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import { getMainContact } from "../../services/myData.service";
import { MainContact } from "../../types";
import { err, got, req } from "./mainContactSlice";

export function* handleFetchMainContact() {
  try {
    const myData: AxiosResponse<MainContact> = yield call(getMainContact);
    yield put(got(myData.data));
  } catch (error: any) {
    yield put(err(error?.message));
  }
}

export function* mainContactWatcherSaga() {
  yield takeLatest(req.type, handleFetchMainContact);
}
