import styles from "./ProfileFooter.module.scss";

export interface Props {
  enabled: boolean;
  onSave: () => void;
  onCancel: () => void;
  cancelText: string;
  saveText: string;
}

export const ProfileFooter: React.FC<Props> = ({ enabled, onSave, onCancel, cancelText, saveText }) => {
  return (
    <div className={styles.footer}>
      <div className={styles.footerContents}>
        <button className={styles.cancelButton} onClick={onCancel} disabled={!enabled}>
          {cancelText}
        </button>
        <button className={styles.saveButton} onClick={onSave} type="submit" disabled={!enabled}>
          {saveText}
        </button>
      </div>
    </div>
  );
};
