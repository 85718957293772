import { useMemo } from "react";
import { RStatus } from "../../types";
import { AppErrorView } from "../AppErrorView/AppErrorView";
import { AppLoader } from "../AppLoader/AppLoader";

import styles from "./LazyLoading.module.scss";

export interface Props<T> {
  status: RStatus;
  content?: T[];
  reachedEnd: boolean;
  renderItem: (item: T, index: number) => React.ReactNode;
  scrollContainer: React.ReactNode;
  errorMessage?: string;
}

export const LazyLoading = <T,>(props: Props<T>) => {
  const { status, content, reachedEnd, errorMessage, renderItem, scrollContainer } = props;

  const body = useMemo(() => {
    return content && content.length > 0 && <>{content.map(renderItem)}</>;
  }, [renderItem, content]);

  return (
    <>
      {status !== RStatus.Error && body}
      {status === RStatus.Got &&
        !reachedEnd &&
        /* Infinite scrolling reference */
        scrollContainer}
      {status !== RStatus.Error && !reachedEnd && (
        <div className={styles.loadingContainer}>
          <AppLoader />
        </div>
      )}
      {status === RStatus.Error && errorMessage && <AppErrorView message={errorMessage} />}
    </>
  );
};
