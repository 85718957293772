import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Icon } from "semantic-ui-react";
import { assetStatusSelector } from "../../redux/flow/flowSelectors";
import { ProgressStatus } from "../../types";
import { ReactComponent as OctagonExclamationIcon } from "../../../../assets/images/octagon-exclamation.svg";
import styles from "./FlowEmail.module.scss";

export interface Props {
  isBranched: boolean;
}

export type MessageMap = {
  [key in ProgressStatus | "default"]?: string;
};

export const FlowEmail: React.FC<Props> = ({ isBranched }) => {
  const { t } = useTranslation(["emails"]);
  const assetStatus = useSelector(assetStatusSelector);

  const messageMap: MessageMap = {
    [ProgressStatus.Next]: t("placeholders.emailWithNextStatus"),
    [ProgressStatus.Active]: t("placeholders.emailWithActiveStatus"),
    [ProgressStatus.Completed]: t("placeholders.emailWithCompletedStatus"),
    default: "",
  };

  return (
    <div className={styles.root}>
      <Icon className="mail" data-testid="mail" />
      {isBranched && (
        <OctagonExclamationIcon className={styles.octagonExclamationIcon} data-testid="octagonExclamationIcon" />
      )}
      <div className={styles.flowEmail} data-testid="flowEmail">
        {messageMap[assetStatus ?? "default"]}
      </div>
    </div>
  );
};
