import qs from "qs";

export const queryStringService = {
  stringify: stringify,
  parse: parse,
};

function stringify<T>(object: T): string {
  return qs.stringify(object);
}

function parse<T>(str: string | Record<string, string>): T {
  return qs.parse(str, { decoder }) as unknown as T;
}

//there are no native decoders, all values is being parsed as string by default
//https://github.com/ljharb/qs/issues/91
function decoder(str: string, _: unknown, charset: string) {
  const strWithoutPlus = str.replace(/\+/g, " ");
  if (charset === "iso-8859-1") {
    // unescape never throws, no try...catch needed:
    return strWithoutPlus.replace(/%[0-9a-f]{2}/gi, decodeURI);
  }

  if (/^(\d+|\d*\.\d+)$/.test(str)) {
    return parseFloat(str);
  }

  const keywords = {
    true: true,
    false: false,
    null: null,
    undefined,
  };
  if (str in keywords) {
    return keywords[str as keyof typeof keywords];
  }

  // utf-8
  try {
    return decodeURIComponent(strWithoutPlus);
  } catch (e) {
    return strWithoutPlus;
  }
}
