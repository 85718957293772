import axios, { AxiosHeaders, AxiosResponse } from "axios";
import environmentConfig from "../../../environmentConfig";

const initDefaultHeaders = () => {
  axios.defaults.baseURL = environmentConfig.apiUrl;
  axios.defaults.headers.common = {
    "Cache-Control": "no-cache",
    Pragma: "no-cache",
  };
};

initDefaultHeaders();

const authService = {
  registerAccessTokenFactory(getAccessToken: () => Promise<string | undefined>) {
    axios.interceptors.request.use(async config => {
      const token = await getAccessToken();

      if (token) {
        (config.headers as AxiosHeaders).set("Authorization", `Bearer ${token}`);
      }

      return config;
    });

    axios.interceptors.response.use(
      (response: AxiosResponse) => {
        const hasSqlError = response?.data?.code || response?.data?.sqlState || response?.data?.data?.errorCode;
        if (hasSqlError) {
          return Promise.reject(response.data);
        }

        return response;
      },
      error => {
        // Any status codes outside range of 2xx
        return Promise.reject(error);
      },
    );
  },
};

export default authService;
