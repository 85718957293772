import { mapToDiscoveryItem } from "../../Discover/services/searchResultsMapperService";
import { DiscoveryItem, SearchResult } from "../../Discover/types";

export function mapToGroupContentItems(searchResults: SearchResult[]): DiscoveryItem[] {
  return searchResults.map(mapToGroupContentItem);
}

export function mapToGroupContentItem(searchResult: SearchResult): DiscoveryItem {
  return {
    ...mapToDiscoveryItem(searchResult),
    completionStatus: undefined,
  } as DiscoveryItem;
}
