import { TextTruncator } from "features/Application/layout/TextTruncator/TextTruncator";
import { useEffect, useRef } from "react";
import styles from "./FlowAssetHeader.module.scss";

interface Props {
  title: string;
  children?: React.ReactNode;
}

export const FlowAssetHeader: React.FC<Props> = ({ title, children }) => {
  const titleRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    titleRef.current?.focus();
  }, [title, children]);

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <h3 ref={titleRef} tabIndex={0} className={styles.title}>
          <TextTruncator clamp={1} tooltipContent={title}>
            {title}
          </TextTruncator>
        </h3>
        <div tabIndex={0} className={styles.info}>
          {children}
        </div>
      </div>
    </div>
  );
};
