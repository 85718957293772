import { FunctionComponent, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  req,
  selectSurvey,
  selectSurveyStatus,
  selectSurveyError,
  hasAccessSelector,
} from "../../redux/survey/surveySlice";
import { SurveyAsset } from "..";
import { SendPageView } from "../../../Application/services/realTimeNotification/googleAnalytics";
import { FixedHeightAppPageWrapper } from "../../../Application/layout";
import { FetchingLayout } from "../../../Application/layout/FetchingLayout/FetchingLayout";
import { ErrorType, HttpErrorView } from "../../../Application/layout/HttpErrorView/HttpErrorView";

import styles from "./SurveyDetailsPage.module.scss";

interface SurveyDetailsPageProps {}

export const SurveyDetailsPage: FunctionComponent<SurveyDetailsPageProps> = () => {
  let { surveyId } = useParams();
  const dispatch = useDispatch();
  const survey = useSelector(selectSurvey);
  const status = useSelector(selectSurveyStatus);
  const errorMessage = useSelector(selectSurveyError);
  const hasAccess = useSelector(hasAccessSelector);

  useEffect(() => {
    if (survey?.title) {
      document.title = survey.title;
      SendPageView();
    }
  }, [survey]);

  useEffect(() => {
    if (surveyId) {
      dispatch(req({ id: Number(surveyId) }));
    }
  }, [dispatch, surveyId]);

  return (
    <FixedHeightAppPageWrapper>
      <FetchingLayout status={status} errorMessage={errorMessage}>
        <div className={styles.root}>
          {hasAccess && survey ? <SurveyAsset survey={survey} /> : <HttpErrorView code={ErrorType.Forbidden} back />}
        </div>
      </FetchingLayout>
    </FixedHeightAppPageWrapper>
  );
};

export default SurveyDetailsPage;
