import { call, put, takeLatest } from "redux-saga/effects";
import i18next from "i18next";

import { req, got, err, restricted, completePdf } from "./pdfSlice";
import { fetchPdfV4, markPdfAsWatchedV4 } from "../../services/assetsService";
import { PdfAsset, PdfAssetStandAloneDetails } from "../../types";
import HttpCodes from "../../../types/HttpCodes";
import { HttpResponse } from "../../../Application/http/http";
import { EntityType } from "../../../common/types";

function* handleResult(asset: any) {
  if (asset.isSuccess) {
    const result: PdfAssetStandAloneDetails = {
      ...asset.data!,
      type: EntityType.Pdf,
    };

    yield put(got({ current: result }));
  } else if (asset.code === HttpCodes.Forbidden || asset.code === HttpCodes.NotFound) {
    yield put(restricted());
  } else {
    yield put(err({ errorMessage: asset.message }));
  }
}

export function* handleFetchPdf(action: ReturnType<typeof req>) {
  try {
    const asset: HttpResponse<PdfAsset> = yield call(fetchPdfV4, action.payload.id);
    yield* handleResult(asset);
  } catch (error) {
    yield put(err({ errorMessage: i18next.t("assets:common.PdfNotFound") }));
  }
}

export function* handlePdfComplete(action: ReturnType<typeof completePdf>) {
  try {
    yield call(markPdfAsWatchedV4, action.payload.assetId);
  } catch (error: any) {
    yield put(err({ errorMessage: error?.message }));
  }
}

export function* pdfWatcherSaga() {
  yield takeLatest(req.type, handleFetchPdf);
  yield takeLatest(completePdf.type, handlePdfComplete);
}
