import videojs from "video.js";
import QualityLevelsItem, { QualityLevel } from "./QualityLevelsItem";

const MenuButton = videojs.getComponent("MenuButton");

export interface MenuItem extends QualityLevelsItem {}

class QualityLevelsMenu extends MenuButton {
  constructor(player: any, options: Object) {
    super(player, options);
    this.el().setAttribute("aria-label", "Quality Levels Menu");
    this.selectQualityByResolution(720);
  }

  createEl() {
    return videojs.dom.createEl("div", {
      className: "vjs-quality-selector vjs-menu-button vjs-menu-button-popup vjs-control vjs-button",
    });
  }

  triggerItemUpdate() {
    // @ts-ignore
    this.items.forEach(item => item.update());
  }

  createItems() {
    const levels = (this.player() as any).qualityLevels();
    let menuItems = [];

    for (let i = 0; i < levels.length; i++) {
      const index = i;
      let sortVal = levels[index].height;
      menuItems.push(
        new QualityLevelsItem(this.player_, {
          index,
          sortVal,
          controller: this,
          label: `${levels[index].height}p`,
        }),
      );
    }

    //Sort menu items by their resolution
    menuItems.sort((a, b) => {
      if ((a.options_ as QualityLevel).sortVal < (b.options_ as QualityLevel).sortVal) return 1;
      else return -1;
    });

    const uniqueItems = new Map(menuItems.map(item => [item.options_.label, item]));
    return [...uniqueItems.values()];
  }

  selectQualityByResolution(height: number) {
    //@ts-ignore
    const items = this.items as QualityLevelsItem[];
    const targetItem = items.find(item => item.options_.label === `${height}p`);
    if (targetItem) {
      targetItem.onSelected();
    }
  }
}

// @ts-ignore
QualityLevelsMenu.prototype.controlText_ = "Quality";

export default QualityLevelsMenu;
