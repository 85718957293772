import { useCallback, useContext, useEffect, useState } from "react";
import { Modal as SemanticModal, TransitionablePortal } from "semantic-ui-react";
import { BreakpointContext } from "../../../Application/components/context/BreakpointContext";
import { Flyout } from "../Flyout/Flyout";

export interface ModalProps {
  handleClose: () => void;
  isOpen: boolean;
  activeElement?: Element | null;
  children?: React.ReactNode;
}

export const Modal: React.FC<ModalProps> = props => {
  const { handleClose, isOpen, activeElement, children } = props;
  const [showModalBody, setShowModalBody] = useState(false);
  const { isMobileScreen } = useContext(BreakpointContext);
  // Close if the screen size changes
  useEffect(() => {
    handleClose();
  }, [isMobileScreen, handleClose]);

  // Close modals on escape press
  useEffect(() => {
    const handler = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        handleClose();
      }
    };

    if (isOpen) {
      window.addEventListener("keyup", handler);
      return () => window.removeEventListener("keyup", handler);
    }
  }, [isOpen, handleClose]);

  // Prevent body from scrolling behind modal
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.removeProperty("overflow");
    }
    return () => {
      document.body.style.removeProperty("overflow");
    };
  }, [isOpen]);

  const cleanUp = useCallback(() => {
    if (activeElement) {
      (activeElement as HTMLElement).focus();
    }
  }, [activeElement]);

  const hideModalBody = useCallback(() => {
    setShowModalBody(false);
  }, []);

  useEffect(() => {
    if (isOpen) setShowModalBody(true);
  }, [isOpen]);

  if (isMobileScreen) {
    return (
      <Flyout open={isOpen} height={100} onClosed={hideModalBody}>
        {showModalBody && children}
      </Flyout>
    );
  }

  return (
    <TransitionablePortal open={isOpen} onClose={handleClose} transition={{ animation: "scale", duration: 400 }}>
      <SemanticModal open onClose={handleClose} size="small" onUnmount={cleanUp}>
        {children}
      </SemanticModal>
    </TransitionablePortal>
  );
};
