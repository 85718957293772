import { PriorityLevel } from "../../Discover/types";
import { Props } from "../components/ProgressBarsSection/ProgressBarsSection";

import { useTranslation } from "react-i18next";

import { useMemo } from "react";

import { HomePageContentItem } from "../types";

const firstBorder = (isMobileScreen: boolean, homePageItemsProvider: (priority: Priority) => HomePageContentItem[]) =>
  !isMobileScreen &&
  (homePageItemsProvider(PriorityLevel.Important).length !== 0 ||
    homePageItemsProvider(PriorityLevel.Normal).length !== 0);

const secondBorder = (isMobileScreen: boolean, homePageItemsProvider: (priority: Priority) => HomePageContentItem[]) =>
  !isMobileScreen && homePageItemsProvider(PriorityLevel.Normal).length !== 0;

export type Priority = Exclude<PriorityLevel, PriorityLevel.None>;

export function useProgressBarsConfig(
  homePageItemsProvider: (priority: Priority) => HomePageContentItem[],
  isMobileScreen: boolean,
  className?: string,
) {
  const { t } = useTranslation(["application"]);

  const progressBarsConfig: Props[] = useMemo(
    () => [
      {
        title: t("progressBars.requiredLearning"),
        priorityLevel: PriorityLevel.Required,
        showBorder: firstBorder(isMobileScreen, homePageItemsProvider),
        className: className,
        items: homePageItemsProvider(PriorityLevel.Required),
      },
      {
        title: t("progressBars.importantForYou"),
        priorityLevel: PriorityLevel.Important,
        showBorder: secondBorder(isMobileScreen, homePageItemsProvider),
        className: className,
        items: homePageItemsProvider(PriorityLevel.Important),
      },
      {
        title: t("progressBars.recommended"),
        priorityLevel: PriorityLevel.Normal,
        showBorder: false,
        className: className,
        items: homePageItemsProvider(PriorityLevel.Normal),
      },
    ],
    [homePageItemsProvider, isMobileScreen, className, t],
  );

  return progressBarsConfig;
}
