export enum Visibility {
  None,
  VisibleToAll,
  MembersOnly,
}

export enum Awareness {
  None,
  Aware,
  Unaware,
}

export enum Enrollment {
  None,
  Automatic,
  Manual,
}

export enum Access {
  None,
  Open,
  Restricted,
}
