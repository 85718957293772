import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../Application/store/store";
import { RStatus } from "../../../Application/types";
import { Publisher } from "../../types";
interface PublishersState {
  status: RStatus;
  items: Publisher[];
  errorMessage: string | undefined;
}

const initialState: PublishersState = {
  status: RStatus.Idle,
  items: [],
  errorMessage: undefined,
};

const publishersSlice = createSlice({
  name: "publishers",
  initialState: initialState,
  reducers: {
    req(state) {
      return { ...state, status: RStatus.Pending };
    },
    got(state, action: PayloadAction<{ items: PublishersState["items"] }>) {
      return { ...state, status: RStatus.Got, items: action.payload.items };
    },
    err(state, action: PayloadAction<{ errorMessage: PublishersState["errorMessage"] }>) {
      return {
        ...state,
        status: RStatus.Error,
        errorMessage: action.payload.errorMessage,
      };
    },
  },
});

// actions
export const { req, got, err } = publishersSlice.actions;

// selectors
export const selectPublishers = (state: RootState) => state.discover.publishers.items;
export const selectPublishersStatus = (state: RootState) => state.discover.publishers.status;
export const selectPublishersError = (state: RootState) => state.discover.publishers.errorMessage;

// reducers
export default publishersSlice.reducer;
