import { FunctionComponent, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  req,
  selectAssessment,
  selectAssessmentStatus,
  selectAssessmentError,
  reset,
} from "../../redux/assessment/assessmentSlice";
import styles from "./AssessmentDetailsPage.module.scss";
import { SendPageView } from "../../../Application/services/realTimeNotification/googleAnalytics";
import { FixedHeightAppPageWrapper } from "../../../Application/layout";
import { FetchingLayout } from "../../../Application/layout/FetchingLayout/FetchingLayout";
import { ErrorType, HttpErrorView } from "../../../Application/layout/HttpErrorView/HttpErrorView";

import { hasAccessSelector } from "../../redux/asset/assetSlice";
import { AssessmentAsset } from "../AssessmentAsset/AssessmentAsset";

interface AssessmentDetailsPageProps {}

export const AssessmentDetailsPage: FunctionComponent<AssessmentDetailsPageProps> = () => {
  let { assessmentId } = useParams();
  const dispatch = useDispatch();
  const assessment = useSelector(selectAssessment);
  const status = useSelector(selectAssessmentStatus);
  const errorMessage = useSelector(selectAssessmentError);
  const hasAccess = useSelector(hasAccessSelector);

  useEffect(() => {
    if (assessment?.title) {
      document.title = assessment.title;
      SendPageView();
    }
  }, [assessment]);

  useEffect(() => {
    if (assessmentId) {
      dispatch(req({ id: Number(assessmentId) }));
    }

    return () => {
      dispatch(reset());
    };
  }, [dispatch, assessmentId]);

  return (
    <FixedHeightAppPageWrapper>
      <FetchingLayout status={status} errorMessage={errorMessage}>
        <div className={styles.root}>
          {hasAccess && assessment ? (
            <AssessmentAsset assessment={assessment} />
          ) : (
            <HttpErrorView code={ErrorType.Forbidden} back />
          )}
        </div>
      </FetchingLayout>
    </FixedHeightAppPageWrapper>
  );
};

export default AssessmentDetailsPage;
