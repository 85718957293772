import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Button } from "semantic-ui-react";
import { ReactComponent as NotFoundIcon } from "../../../../assets/images/bsi-error.svg";
import { EmptyState } from "../EmptyState/EmptyState";

import styles from "./InvalidRoutePage.module.scss";

export interface Props {
  onGoBack?: () => void;
}

export const InvalidRoutePage: React.FC<Props> = ({ onGoBack }) => {
  const navigate = useNavigate();
  const { t } = useTranslation("application");

  const onGoBackCb = onGoBack ?? (() => navigate(-1));

  return (
    <div className={styles.root} data-testid="Invalid Route">
      <EmptyState
        title={t("routing.notFoundTitle")}
        description={<div className={styles.description}>{t("routing.notFoundDescription")}</div>}
        icon={
          <div className={styles.iconContainer}>
            <NotFoundIcon />
          </div>
        }
      >
        <Button primary className={styles.button} onClick={onGoBackCb}>
          Go Back
        </Button>
      </EmptyState>
    </div>
  );
};
