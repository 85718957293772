import axios, { AxiosError, AxiosHeaders, AxiosRequestConfig, AxiosResponse, RawAxiosRequestHeaders } from "axios";

export interface HttpResponse<T> {
  data: T | null;
  isSuccess: boolean;
  message?: string;
  headers: RawAxiosRequestHeaders | AxiosHeaders | null;
  code?: number;
}

interface IHttp {
  get<T = any>(url: string, config?: AxiosRequestConfig): Promise<HttpResponse<T>>;
}

const isAxiosError = <T = any>(error: any): error is AxiosError<T> => {
  return error.response !== undefined;
};

const baseRequest = <T = any>(call: (url: string, config?: AxiosRequestConfig) => Promise<AxiosResponse<T>>) => {
  return async (url: string, config?: AxiosRequestConfig): Promise<HttpResponse<T>> => {
    try {
      const result = await call(url, config);

      return {
        isSuccess: true,
        data: result.data,
        headers: result.headers,
        code: result.status,
      };
    } catch (error) {
      if (!isAxiosError(error)) {
        throw error;
      }

      return {
        isSuccess: false,
        code: error.response?.status,
        data: null,
        headers: null,
        message: error.message,
      };
    }
  };
};

export const http: IHttp = {
  get: baseRequest(axios.get),
};
export default http;
