import { useCallback, useMemo, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { Radio } from "semantic-ui-react";
import { Checkbox } from "../../../../Application/layout/Checkbox/Checkbox";
import { SurveyQuestion as Question } from "../../../types";

import styles from "./SurveyQuestion.module.scss";
import { SurveyQuestionAnswers } from "./SurveyQuestionAnswers";
interface Props {
  question: Question;
  setSelectedAnswers?: (answers: boolean[]) => void;
  selectedAnswers: boolean[];
  otherText: string | null;
  setOtherText: (text: string) => void;
  questionOrder: number;
}

export const SurveyQuestion: React.FC<Props> = ({
  question,
  setSelectedAnswers,
  selectedAnswers,
  otherText,
  setOtherText,
  questionOrder,
}) => {
  const { t } = useTranslation("assets");

  const updateCheckbox = useCallback(
    (idx: number) => {
      const temp = selectedAnswers.slice();
      temp[idx] = !temp[idx];
      setSelectedAnswers?.(temp);
    },
    [selectedAnswers, setSelectedAnswers],
  );
  const updateRadio = useCallback(
    (idx: number) => {
      const temp = [];
      temp[idx] = true;
      setSelectedAnswers?.(temp);
    },
    [setSelectedAnswers],
  );
  const onChangeText = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setOtherText(e.target.value);
  };

  const isQuestionMultiselect = useMemo(() => question.type === "MultipleChoiceTextQuestion", [question]);

  const AnswerComponent = isQuestionMultiselect ? Checkbox : Radio;
  const answerUpdater = isQuestionMultiselect ? updateCheckbox : updateRadio;

  const updateOtherAnswer = useCallback(() => {
    answerUpdater(question.answers.length);
  }, [answerUpdater, question]);

  return (
    <div className={styles.root}>
      <div className={styles.questionTitle}>{`${questionOrder}. ${question.question} ${
        isQuestionMultiselect ? t("assessments.selectAllThatApply") : ""
      }`}</div>
      <div className={styles.answers}>
        {question.answers.map((answer, idx) => {
          const selectedAnswer = !!selectedAnswers[idx];

          return (
            <Fragment key={answer.id}>
              <SurveyQuestionAnswers
                answer={answer}
                idx={idx}
                question={question}
                updateCheckbox={updateCheckbox}
                updateRadio={updateRadio}
                selectedAnswer={selectedAnswer}
              />
            </Fragment>
          );
        })}
        {question?.otherAnswerOptions?.includeOtherAsAnswer && (
          <>
            <AnswerComponent
              className={styles.item}
              label={t("assessments.other")}
              aria-label="Other"
              checked={!!selectedAnswers[question.answers.length]}
              name={`answer${question.answers.length}`}
              onChange={updateOtherAnswer}
            />
            {question.otherAnswerOptions.includeUserTextField && !!selectedAnswers[question.answers.length] && (
              <textarea
                className={styles.text}
                data-testid="other-text"
                rows={5}
                placeholder={
                  question.otherAnswerOptions.isUserTextFieldRequired
                    ? t("assessments.enterTextRequired")
                    : t("assessments.enterText")
                }
                onChange={onChangeText}
              >
                {otherText}
              </textarea>
            )}
          </>
        )}
      </div>
    </div>
  );
};
