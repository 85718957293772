import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { Icon } from "semantic-ui-react";
import styles from "./BackLink.module.scss";

export interface Props {
  to: string;
  state?: any;
  hasText?: boolean;
}

export const BackLink: React.FC<Props> = ({ to, hasText, state }) => {
  const navigate = useNavigate();
  const { t } = useTranslation(["application"]);

  const backIcon = (
    <>
      <Icon name="angle left" size="large" color="grey" className="outline" />
      {hasText && <div className={styles.back}>{t("common.back")}</div>}
    </>
  );

  if (state?.prevPage) {
    return (
      <div onClick={() => navigate(-1)} className={styles.root} title="back">
        {backIcon}
      </div>
    );
  } else {
    return (
      <Link to={to} state={state} className={styles.root} title="back">
        {backIcon}
      </Link>
    );
  }
};
