import { EvaluatedAssessment } from "../../../../Flows/types";
import { IMapping } from "./types";
import * as libraryEventTypes from "../events/libraryEvents";
import { setEvaluatedAssessment as setEvaluatedAssessmentFlow } from "../../../../Flows/redux/flow/flowSlice";
import { setEvaluatedAssessment } from "../../../../Assets/redux/assessment/assessmentSlice";
import { SessionUnregistrationSuccess } from "../../../../Events/types";
import { unregisterFromSessionSuccess } from "../../../../Events/redux/sessions/sessionsSlice";

export default function createMapping(dispatch: any) {
  const mapping: IMapping = {};

  const evaluateAssessment = (payload: EvaluatedAssessment) => {
    dispatch(setEvaluatedAssessment(payload));
    dispatch(setEvaluatedAssessmentFlow(payload));
  };

  const handleOnUnregistrationSuccess = (payload: SessionUnregistrationSuccess) => {
    dispatch(unregisterFromSessionSuccess(payload));
  };

  mapping[libraryEventTypes.EvaluateAssessmentSuccess] = evaluateAssessment;
  mapping[libraryEventTypes.ClientUserSessionUnregistrationSuccess] = handleOnUnregistrationSuccess;

  return mapping;
}
