import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { AppPageWrapper, LazyLoadingCards } from "../../../Application/layout";
import { PageContent } from "../../../Application/layout/PageContent/PageContent";
import { PageHeader } from "../../../Application/layout/PageHeader/PageHeader";
import { Toast, ToastType } from "../../../Application/layout/Toast/Toast";
import { GroupCard } from "../GroupCard/GroupCard";
import { GroupListHeader } from "../GroupListHeader/GroupListHeader";

import { OnClickOutside } from "../../../Account/components/OnClickOutside/OnClickOutside";
import { BreakpointContext } from "../../../Application/components/context/BreakpointContext";
import { EmptyState } from "../../../Application/components/EmptyState/EmptyState";
import { useRtn } from "../../../Application/hooks/useRtn";
import { SendPageView } from "../../../Application/services/realTimeNotification/googleAnalytics";
import { RStatus } from "../../../Application/types";
import useSort from "../../../Flows/hooks/useSort";
import {
  req,
  reset,
  selectGroups,
  selectGroupsCount,
  selectGroupsEndOfResults,
  selectGroupsError,
  selectGroupsSorting,
  selectGroupsStatus,
  updateGroupInfo,
  updateSort,
} from "../../redux/groups/groupsSlice";
import { Group, GroupActionSuccess, SortType } from "../../types";

import * as groupEvents from "../../../Application/services/realTimeNotification/events/groupEvents";

import styles from "./GroupsPage.module.scss";

export const GroupsPage: React.FC = () => {
  const { t } = useTranslation(["groups"]);
  const [displayToast, setDisplayToast] = useState(false);
  const [errorText, setErrorText] = useState("");
  const { isMobileScreen } = useContext(BreakpointContext);
  const dispatch = useDispatch();
  const groups = useSelector(selectGroups);
  const groupsCount = useSelector(selectGroupsCount);
  const reachedEnd = useSelector(selectGroupsEndOfResults);
  const status = useSelector(selectGroupsStatus);
  const errorMessage = useSelector(selectGroupsError);
  const sorting = useSelector(selectGroupsSorting);

  useEffect(() => {
    document.title = "Groups";
    SendPageView();
  }, []);

  const options = useMemo(
    () => [
      { value: SortType.NameAz, text: t("nameAz") },
      { value: SortType.NameZa, text: t("nameZa") },
      { value: SortType.CreationDate, text: t("creationDate") },
      { value: SortType.Popularity, text: t("popularity") },
    ],
    [t],
  );

  const onSortChange = (newValue: string) => {
    dispatch(updateSort({ value: newValue as SortType }));
  };

  const { toggler, dropdown, setSortOpen } = useSort({
    dropdownOptions: options,
    includeTextOnToggle: false,
    onChange: onSortChange,
    defaultValue: sorting,
  });

  useEffect(() => {
    dispatch(req());

    return () => {
      dispatch(reset());
    };
  }, [dispatch]);

  const groupActionSuccess = useCallback(
    ({ GroupId }: GroupActionSuccess) => {
      dispatch(updateGroupInfo({ groupId: GroupId }));
    },
    [dispatch],
  );

  const showJoinError = useCallback(() => {
    setErrorText(t("groupJoinFailed"));
    setDisplayToast(true);
  }, [t]);

  const showLeaveError = useCallback(() => {
    setErrorText(t("groupLeaveFailed"));
    setDisplayToast(true);
  }, [t]);

  const hideToast = useCallback(() => {
    setDisplayToast(false);
  }, []);

  const openSort = useCallback(() => {
    setSortOpen(false);
  }, [setSortOpen]);

  const renderGroup = useCallback((group: Group) => {
    return <GroupCard key={group.id} {...group} to={group.id.toString()} />;
  }, []);

  useRtn([groupEvents.UserJoinSuccess, groupEvents.UserLeaveSuccess], groupActionSuccess);
  useRtn([groupEvents.UserJoinFail], showJoinError);
  useRtn([groupEvents.UserLeaveFail], showLeaveError);

  return (
    <AppPageWrapper reactStrictMode={false}>
      <PageHeader noPadding>
        <div className={styles.header}>
          <h1 className={styles.headerText}>{t("common.header")}</h1>
          {isMobileScreen && (
            <OnClickOutside handler={openSort}>
              <>
                {toggler}
                {dropdown}
              </>
            </OnClickOutside>
          )}
        </div>
      </PageHeader>
      <PageContent showLoader={status === RStatus.Pending && groups.length === 0}>
        {(groups.length > 0 || status === RStatus.Error) && (
          <>
            <GroupListHeader groupCount={groupsCount} dropdown={dropdown} />
            <LazyLoadingCards
              content={groups}
              status={status}
              reachedEnd={reachedEnd}
              reqAction={req}
              errorMessage={errorMessage}
              renderItem={renderGroup}
            />
          </>
        )}
        {status === RStatus.Got && groups.length === 0 && (
          <EmptyState title="You are not in any groups yet." description="Check back later." iconClassName="users" />
        )}
        <Toast visible={displayToast} type={ToastType.Error} body={errorText} onClose={hideToast} autoDismiss />
      </PageContent>
    </AppPageWrapper>
  );
};
