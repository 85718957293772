import classNames from "classnames/bind";
import React, { useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { BreakpointContext } from "../../../Application/components/context/BreakpointContext";
import { ScrollPoint } from "../../../Application/hooks/useScrollPoint";
import { LazyLoading } from "../../../Application/layout";
import { getParamValue } from "../../../Application/utils/routing";
import { SEARCH_TERM } from "../../constants";
import { DiscoveryItem } from "../../types";
import NavigatableContentItem from "./NavigatableContentItem";

import { ActionCreatorWithoutPayload } from "@reduxjs/toolkit";
import { OnClickOutside } from "../../../Account/components/OnClickOutside/OnClickOutside";
import { RootState } from "../../../Application/store/store";
import { RStatus } from "../../../Application/types";
import styles from "./ContentList.module.scss";

const cx = classNames.bind(styles);

interface Props {
  dropdown?: React.ReactNode;
  selectResult: (state: RootState) => DiscoveryItem[];
  selectStatus: (state: RootState) => RStatus;
  selectError: (state: RootState) => string | undefined;
  selectReachedEnd: (state: RootState) => boolean;
  fetchMore: ActionCreatorWithoutPayload<string>;
  hideTitle?: boolean;
  closeDropdown?: (e: MouseEvent | TouchEvent) => void;
  interactive?: boolean;
}

export const ContentList: React.FC<Props> = ({
  dropdown,
  closeDropdown,
  selectResult,
  selectStatus,
  selectError,
  selectReachedEnd,
  fetchMore,
  hideTitle,
  interactive,
}) => {
  const { t } = useTranslation(["discover"]);
  const { isMobileScreen } = useContext(BreakpointContext);
  const location = useLocation();

  const searchResults = useSelector(selectResult);

  const status = useSelector(selectStatus);
  const errorMessage = useSelector(selectError);
  const end = useSelector(selectReachedEnd);

  let query = "";
  if (location.search) {
    query = getParamValue(location.search, SEARCH_TERM);
  }

  const dispatch = useDispatch();

  const fetch = useCallback(() => {
    dispatch(fetchMore());
  }, [dispatch, fetchMore]);

  const handleRenderItem = useCallback(
    (item: DiscoveryItem) => {
      return <NavigatableContentItem key={item.id} item={item} interactive={item.expired ? false : interactive} />;
    },
    [interactive],
  );

  return (
    <div>
      <section className={styles.header}>
        {!hideTitle && (
          <span className={styles.title}>
            {query.length
              ? t("results.resultsFor", {
                  count: searchResults.length,
                  query,
                })
              : t("common.all")}
          </span>
        )}
        {dropdown && (
          <div className={cx({ [styles.sortByContainer]: !isMobileScreen })}>
            <label
              className={cx(styles.sortByLabel, {
                [styles.hidden]: isMobileScreen,
              })}
            >
              {t("sort.label")}
            </label>
            {closeDropdown && isMobileScreen ? (
              <OnClickOutside handler={closeDropdown}>{dropdown}</OnClickOutside>
            ) : (
              dropdown
            )}
          </div>
        )}
      </section>
      <section className={styles.body}>
        <LazyLoading
          content={searchResults}
          status={status}
          reachedEnd={end}
          scrollContainer={<ScrollPoint callback={fetch} />}
          errorMessage={errorMessage}
          renderItem={handleRenderItem}
        />
      </section>
    </div>
  );
};
