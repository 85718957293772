import { useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { ACCOUNT_REDIRECT_EMAIL_PARAM_NAME, ACCOUNT_REDIRECT_ID_PARAM_NAME } from "./constants";

const aliasKey = "alias";

type ExtraQueryParams = { email: string | null; accountId: string | null } | { alias: string };
type UseSignInParamsHook = () => [{ extraQueryParams: ExtraQueryParams } | undefined, boolean];

export const useSignInParams: UseSignInParamsHook = () => {
  const location = useLocation();
  const [autoSignInArgs, setAutoSignInArgs] = useState<{ extraQueryParams: ExtraQueryParams }>();
  const [initialized, setInitialized] = useState<boolean>(false);
  const setSearchParams = useSearchParams()[1];

  useEffect(() => {
    const params = new URLSearchParams(location.search);

    const getAndRemoveQueryParams = (...keys: string[]) => {
      const result = keys.map(x => {
        const res = params.get(x);
        params.delete(x);
        return res;
      });
      return result;
    };

    const values = getAndRemoveQueryParams(aliasKey, ACCOUNT_REDIRECT_EMAIL_PARAM_NAME, ACCOUNT_REDIRECT_ID_PARAM_NAME);
    if (values.every(x => !x)) {
      setAutoSignInArgs(undefined);
    } else {
      const [alias, redirectEmailParam, redirectAccountIdParam] = values;

      const autoSignInArgs = alias
        ? { extraQueryParams: { alias } }
        : {
            extraQueryParams: { email: redirectEmailParam, accountId: redirectAccountIdParam },
          };

      setSearchParams(params.toString(), { replace: true });
      setAutoSignInArgs(autoSignInArgs);
    }

    setInitialized(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [autoSignInArgs, initialized];
};
