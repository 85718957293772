import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { AppLoader } from "../../../Application/layout";
import { selectRedirectId } from "../../redux/flow/flowSelectors";

export const FlowRedirect: React.FC<React.PropsWithChildren> = ({ children }) => {
  const redirectId = useSelector(selectRedirectId);
  const { flowId } = useParams();
  const navigate = useNavigate();
  const shouldRedirect = redirectId && redirectId !== Number(flowId);

  useEffect(() => {
    if (shouldRedirect) {
      navigate(`/discover/flows/${redirectId}`);
    }
  }, [redirectId, shouldRedirect, navigate]);

  return shouldRedirect ? <AppLoader /> : <>{children}</>;
};
