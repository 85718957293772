import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import en_account from "./translations/en/en_account.json";
import en_application from "./translations/en/en_application.json";
import en_assets from "./translations/en/en_assets.json";
import en_discover from "./translations/en/en_discover.json";
import en_emails from "./translations/en/en_emails.json";
import en_events from "./translations/en/en_events.json";
import en_flows from "./translations/en/en_flows.json";
import en_groups from "./translations/en/en_groups.json";
import en_home from "./translations/en/en_home.json";

import de_account from "./translations/de/de_account.json";
import de_application from "./translations/de/de_application.json";
import de_assets from "./translations/de/de_assets.json";
import de_discover from "./translations/de/de_discover.json";
import de_emails from "./translations/de/de_emails.json";
import de_events from "./translations/de/de_events.json";
import de_flows from "./translations/de/de_flows.json";
import de_groups from "./translations/de/de_groups.json";
import de_home from "./translations/de/de_home.json";

(async () => {
  await i18n
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
      debug: false,
      fallbackLng: "en",
      returnNull: false,
      interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
      },
      resources: {
        en: {
          application: en_application,
          account: en_account,
          discover: en_discover,
          events: en_events,
          flows: en_flows,
          groups: en_groups,
          home: en_home,
          assets: en_assets,
          emails: en_emails,
        },
        de: {
          application: de_application,
          account: de_account,
          discover: de_discover,
          events: de_events,
          flows: de_flows,
          groups: de_groups,
          home: de_home,
          assets: de_assets,
          emails: de_emails,
        },
      },
    });
})().catch(e => console.error(e));

export default i18n;
