import EventEmitter from "events";
import { Progress, ProgressStatus } from "../types";
import { useCallback } from "react";

type Actions = { type: "ProgressUpdated"; payload: Progress } | { type: "ResetSubmitted" } | { type: "ResetPerformed" };

const createProgressEmitter = () => {
  const progressEmitter = new EventEmitter();
  let lastTimestamp = "";

  return {
    raise(action: Actions) {
      //prevent outdated updates
      if (action.type === "ProgressUpdated") {
        if (lastTimestamp >= action.payload.timestamp) {
          return;
        }
        lastTimestamp = action.payload.timestamp;
      }

      progressEmitter.emit(action.type, "payload" in action ? action.payload : undefined);
    },
    subscribe(type: Actions["type"], cb: (...args: any[]) => void) {
      progressEmitter.on(type, cb);
    },
    unsubscribe(type: Actions["type"], cb: (...args: any[]) => void) {
      progressEmitter.off(type, cb);
    },
    dispose() {
      progressEmitter.removeAllListeners();
    },
  };
};

type ProgressEmitter = ReturnType<typeof createProgressEmitter>;

const emitterBuilder = (): ((flow: number | undefined) => ProgressEmitter) => {
  let currentFlow: number | undefined = undefined;
  let emitter: ProgressEmitter = createProgressEmitter();

  return (flow: number | undefined) => {
    if (currentFlow !== flow) {
      currentFlow = flow;
      emitter.dispose();
      emitter = createProgressEmitter();
      applyResetHandler(emitter);
    }

    return emitter;
  };
};

export const getOrCreateEmitter = emitterBuilder();

export const useProgressEmitter = (flow: number | undefined) => {
  const emitter = getOrCreateEmitter(flow);
  const subscribe = useCallback(
    (type: Actions["type"], cb: (...args: any[]) => void) => {
      emitter.subscribe(type, cb);
      return () => emitter.unsubscribe(type, cb);
    },
    [emitter],
  );

  const raise = useCallback(
    (action: Actions) => {
      emitter.raise(action);
    },
    [emitter],
  );

  return [subscribe, raise] as const;
};

const applyResetHandler = (emitter: ProgressEmitter) => {
  let hasBeenSubmitted = false;
  emitter.subscribe("ResetSubmitted", () => {
    hasBeenSubmitted = true;
  });

  emitter.subscribe("ProgressUpdated", (payload: Progress) => {
    const firstItemStatus = payload.records?.[0]?.status;

    if (firstItemStatus === ProgressStatus.Active && hasBeenSubmitted) {
      hasBeenSubmitted = false;
      emitter.raise({ type: "ResetPerformed" });
    }
  });
};
