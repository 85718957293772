import classNames from "classnames/bind";
import { Icon } from "semantic-ui-react";
import { PriorityLevel } from "../../../../Discover/types";
import { useTranslation } from "react-i18next";
import styles from "./CardPriority.module.scss";
import { useMemo } from "react";

export interface PriorityProps {
  level: PriorityLevel;
  dueDate?: string | null;
  hideAccessible?: boolean;
}

const cx = classNames.bind(styles);

export const CardPriority: React.FC<PriorityProps> = ({ level, dueDate, hideAccessible = false }) => {
  const { t } = useTranslation("discover");

  const dueDateConverted = useMemo(() => (dueDate ? new Date(dueDate) : undefined), [dueDate]);

  const tabIndex = hideAccessible ? -1 : 0;
  switch (level) {
    case PriorityLevel.Required:
      return (
        <div className={cx(styles.priorityLevelWithIcon, styles.required)} data-testid="priority-required">
          <div className={styles.requiredIconWrapper}>
            <Icon name="exclamation" fitted style={{ width: "unset" }} />
          </div>
          <p
            tabIndex={tabIndex}
            className={!dueDateConverted ? styles.requiredTextWrapper : ""}
            data-testid="required-text"
          >
            {dueDateConverted
              ? `${t("priority.dueDate").toLocaleUpperCase()} ${dueDateConverted.toLocaleDateString()}`
              : t("priority.required").toLocaleUpperCase()}
          </p>
        </div>
      );
    case PriorityLevel.Important:
      return (
        <div className={cx(styles.priorityLevel, styles.important)} data-testid="priority-important">
          <p tabIndex={tabIndex}>{t("priority.important").toLocaleUpperCase()}</p>
        </div>
      );
    case PriorityLevel.Normal:
      return (
        <div className={cx(styles.priorityLevel, styles.normal)} data-testid="priority-normal">
          <p tabIndex={tabIndex}>{t("priority.normal").toLocaleUpperCase()}</p>
        </div>
      );
    default:
      return <></>;
  }
};
