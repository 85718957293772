import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { ErrorType, HttpErrorView } from "../../../Application/layout/HttpErrorView/HttpErrorView";
import { AppPageWrapper } from "../../../Application/layout";
import { BackTitle } from "../../../Application/layout/BackTitle/BackTitle";
import { FetchingLayout } from "../../../Application/layout/FetchingLayout/FetchingLayout";
import { SendPageView } from "../../../Application/services/realTimeNotification/googleAnalytics";
import { hasAccessSelector, req, selectAsset, selectAssetError, selectAssetStatus } from "../../redux/asset/assetSlice";
import { AssetVideoPlayer } from "../AssetVideoPlayer/AssetVideoPlayer";
import styles from "./AssetDetailsPage.module.scss";

export const AssetDetailsPage = () => {
  let { assetId } = useParams();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const asset = useSelector(selectAsset);
  const hasAccess = useSelector(hasAccessSelector);
  const status = useSelector(selectAssetStatus);
  const errorMessage = useSelector(selectAssetError);

  useEffect(() => {
    if (asset?.title) {
      document.title = asset.title;
      SendPageView();
    }
  }, [asset]);

  useEffect(() => {
    if (assetId) {
      dispatch(req({ id: Number(assetId) }));
    }
  }, [dispatch, assetId]);

  return (
    <AppPageWrapper>
      <FetchingLayout status={status} errorMessage={errorMessage}>
        <div className={styles.root}>
          {hasAccess && asset ? (
            <>
              <div className={styles.headerSpacing}>
                <BackTitle to={"/discover"} state={{ prevPage: state }} title={asset.title} />
              </div>
              <section className={styles.content}>
                <AssetVideoPlayer {...asset} />
              </section>
            </>
          ) : (
            <HttpErrorView code={ErrorType.Forbidden} back />
          )}
        </div>
      </FetchingLayout>
    </AppPageWrapper>
  );
};
