import { VideoStreamManifest } from "../../../../types";

export const supportedStreamingTypesMap = new Map([
  ["Dash", "application/dash+xml"],
  ["HLS", "application/x-mpegURL"],
]);
export const audioOnlyFalseFilter = "audio-only=false";

class StreamingTypesHelper {
  static getSupportedTypes = () => supportedStreamingTypesMap;
}

export const getSupportedStream = (manifests: VideoStreamManifest[]) => {
  const hls = manifests.filter(m => m.type === "Hls");
  // Thanks to https://github.com/videojs/http-streaming
  // (which is included in v7 of video.js by default)
  // we can provide the hls version for all browsers
  // and it will be polyfilled for those that don't support it
  if (hls.length > 0) {
    return {
      src: getLinkWithAmsFilters(hls[0].uri, audioOnlyFalseFilter),
      type: supportedStreamingTypesMap.get("HLS"),
    };
  }

  // In case we don't have polyfill
  const supportedTypes = StreamingTypesHelper.getSupportedTypes();
  let supportedSetting;

  for (let manifest of manifests) {
    if (supportedTypes.has(manifest.type)) {
      supportedSetting = {
        src: manifest.uri,
        type: supportedTypes.get(manifest.type),
      };
      break;
    }
  }
  return supportedSetting;
};

export const getLinkWithAmsFilters = (link: string, ...filters: string[]): string => {
  const joinedFilters = filters.join(",");
  const slicedLink = link.slice(0, link.length - 1);
  const result = `${slicedLink},`.concat(joinedFilters, ")");
  return result;
};
