import Linkify from "linkify-react";
import React, { FunctionComponent, SVGProps, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import HttpCodes from "../../../types/HttpCodes";

import { ReactComponent as FaceDisappointed } from "../../../../assets/images/face-disappointed.svg";
import { ReactComponent as HandPointRight } from "../../../../assets/images/hand-point-right.svg";
import { ReactComponent as PartyHorn } from "../../../../assets/images/party-horn.svg";
import { selectCurrentProfile } from "../../../Account/redux/profile/profileSlice";
import { Button, InvisibleButton } from "../../../Application/layout";

import styles from "./flowEnd.module.scss";
import { FlowEndMeta } from "features/Flows/types";

export interface Props {
  item: FlowEndMeta;
  onRestart: () => void;
}

const Icons: { [key in HttpCodes]?: FunctionComponent<SVGProps<SVGSVGElement>> } = {
  [HttpCodes.NotFound]: PartyHorn,
  [HttpCodes.Forbidden]: FaceDisappointed,
  [HttpCodes.Succeeded]: HandPointRight,
};

export const FlowEnd: React.FC<Props> = ({ item, onRestart }) => {
  const profile = useSelector(selectCurrentProfile);
  const { t } = useTranslation(["flows"]);
  const Icon = Icons[item.statusCode as HttpCodes] ?? PartyHorn;

  const baseData = useMemo(() => {
    switch (item.statusCode) {
      case HttpCodes.Forbidden:
        return {
          title: t("flowEnd.forbidden.title"),
          description: t("flowEnd.forbidden.description"),
        };
      case HttpCodes.Succeeded:
        return {
          title: t("flowEnd.nextFlow.title"),
          description: item.completedMessage || t("flowEnd.nextFlow.description"),
        };
      case HttpCodes.NotFound:
        return {
          title: t("flowEnd.base.title"),
          description: t("flowEnd.base.description"),
        };
      default:
        return {
          title: `${t("flowEnd.base.title")}, ${profile?.firstName}!`,
          description: item.completedMessage || t("flowEnd.base.description"),
        };
    }
  }, [t, item, profile?.firstName]);

  const linkButton = useMemo(() => {
    return item.statusCode === HttpCodes.Succeeded
      ? {
          link: `/discover/flows/${item.nextFlowId}`,
          text: "flowEnd.nextFlow.continue",
        }
      : {
          link: "/discover",
          text: "flowEnd.base.discoverMore",
        };
  }, [item]);

  return (
    <div className={styles.root} data-testid="flow-end">
      <Icon />
      <h1 className={styles.title} role="alert">
        {baseData.title}
      </h1>
      <span className={styles.message}>
        <Linkify>{baseData.description}</Linkify>
      </span>
      <Link tabIndex={-1} className={styles["discover-btn"]} to={linkButton.link}>
        <Button>{t(linkButton.text)}</Button>
      </Link>
      {item.statusCode === HttpCodes.NotFound && (
        <div className={styles["restart-course"]}>
          <InvisibleButton onClick={onRestart} ariaLabel="toggle restart" data-testid="restart course button">
            {t("restartCourse.popup.content")}
          </InvisibleButton>
        </div>
      )}
    </div>
  );
};
