import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";

import { FlowPage } from "../../../Flows/components/FlowPage/FlowPage";
import { PdfDetailsPage } from "../../../Assets/components/PdfDetailsPage/PdfDetailsPage";
import { AssetDetailsPage, AssessmentDetailsPage, SurveyDetailsPage } from "../../../Assets/components";
import { clearFilters } from "../../redux/searchResults/searchResultsSlice";
import { EventRoutes } from "../../../Events/components/EventRoutes/EventRoutes";
import { DiscoverPage } from "../DiscoverPage/DiscoverPage";
import { FlowRedirect } from "../../../Flows/components/FlowPage/FlowRedirect";

export const DiscoverPageRoutes: React.FC = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  // When navigating back to the discover page
  // ie by clicking "Discover" while the page
  // is already open, remove any previously active
  // filters.
  useEffect(() => {
    if (!location.search) {
      dispatch(clearFilters());
    }
  }, [location.search, dispatch]);

  return (
    <Routes>
      <Route
        path={"flows/:flowId"}
        element={
          <FlowRedirect>
            <FlowPage />
          </FlowRedirect>
        }
      />
      <Route path={"asset/:assetId"} element={<AssetDetailsPage />} />
      <Route path={"assessment/:assessmentId"} element={<AssessmentDetailsPage />} />
      <Route path={"survey/:surveyId"} element={<SurveyDetailsPage />} />
      <Route path={"event/*"} element={<EventRoutes />} />
      <Route path={"pdf/:pdfId"} element={<PdfDetailsPage />} />
      <Route index element={<DiscoverPage />} />
      <Route path={"*"} element={<Navigate to="/discover" replace />} />
    </Routes>
  );
};
