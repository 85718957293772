import { TextTruncator } from "features/Application/layout/TextTruncator/TextTruncator";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { BackLink } from "../../../Application/layout/BackLink/BackLink";

import { registerOnSession } from "../../redux/sessions/sessionsSlice";
import { Event, RecurrenceSession } from "../../types";
import EventSessionMetadata from "../EventSessionMetadata/EventSessionMetadata";
import { EventViewer } from "../EventViewer/EventViewer";

import styles from "./EventsPage.module.scss";

interface Props {
  event: Event;
}

export const EventsPage: React.FC<Props> = (props: Props) => {
  const { state } = useLocation();
  const dispatch = useDispatch();

  const onEventSessionRegistration = useCallback(
    (sessionId: string, registrationType: number, sessionStartDate: string) =>
      dispatch(
        registerOnSession({
          eventId: props.event.id,
          sessionId,
          registrationType,
          sessionStartDate,
        }),
      ),
    [dispatch, props.event],
  );

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <div className={styles.title}>
          <BackLink to={"/discover"} state={{ prevPage: state }} hasText={false} />
          <h3 className={styles.title} tabIndex={0}>
            <TextTruncator clamp={1} tooltipContent={props.event.title}>
              {props.event.title}
            </TextTruncator>
          </h3>
        </div>
        <div className={styles.info} tabIndex={0}>
          <EventSessionMetadata
            event={{
              singleSessionsCount: props.event.singleSessionsCount,
              recurrenceSession: props.event.recurrenceSession as RecurrenceSession,
            }}
          />
        </div>
      </div>
      <div className={styles.eventContent}>
        <EventViewer event={props.event} onRegistration={onEventSessionRegistration} />
      </div>
    </div>
  );
};
