import { Checkbox, Radio, Icon } from "semantic-ui-react";
import { useTranslation } from "react-i18next";

import { AssessmentQuestion as Question } from "../../../../Assets/types";

import styles from "./AssessmentQuestion.module.scss";
import { useCallback } from "react";

interface Props {
  answer: string;
  idx: number;
  question: Question;
  updateCheckbox: (idx: number) => void;
  updateRadio: (idx: number) => void;
  selectedAnswer: boolean;
  correctAnswer: boolean | undefined;
}

export const AssessmentQuestionAnswers: React.FC<Props> = ({
  answer,
  idx,
  question,
  updateCheckbox,
  updateRadio,
  selectedAnswer,
  correctAnswer,
}) => {
  const { t } = useTranslation("assets");

  const AnswerComponent = question.type === "MultipleChoiceText" ? Checkbox : Radio;
  const answerUpdater = question.type === "MultipleChoiceText" ? updateCheckbox : updateRadio;

  const getResultText = (isCorrect?: boolean) => t(isCorrect ? "assessments.correct" : "assessments.incorrect");

  const getAriaResultText = (isCorrect?: boolean) => (isCorrect !== undefined ? getResultText(isCorrect) : "");

  const innerAnswerUpdater = useCallback(() => {
    answerUpdater(idx);
  }, [answerUpdater, idx]);

  return (
    <div className={styles.answer}>
      <AnswerComponent
        aria-label={`${answer} ${correctAnswer ? getAriaResultText(correctAnswer) : ""}`}
        aria-checked={selectedAnswer}
        className={styles.item}
        label={answer}
        checked={selectedAnswer}
        name={`question-${question.questionOrder}-answer-${idx}`}
        onChange={innerAnswerUpdater}
      />
      {correctAnswer && (
        <>
          <Icon className="check circle outline" />
          <span className={styles.correct}>{t("assessments.correct")}</span>
        </>
      )}
    </div>
  );
};
