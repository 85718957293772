import React, { useEffect, useRef } from "react";

interface Props {
  handler: (event: MouseEvent | TouchEvent) => void;
  children: React.ReactNode;
}

export const OnClickOutside: React.FC<Props> = ({ children, handler }) => {
  const ref = useRef<HTMLDivElement>(null);

  // Borrowed some logic from: https://usehooks.com/useOnClickOutside/
  useEffect(() => {
    const listener = (event: MouseEvent | TouchEvent) => {
      // Did we click one of the ref's children?
      const el = event.target;
      if (ref.current && el instanceof Node && ref.current.contains(el)) {
        return;
      }

      // Otherwise, we handle the event
      handler(event);
    };

    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);

    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, [ref, handler]);

  return <div ref={ref}>{children}</div>;
};
