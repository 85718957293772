import { AppLoader } from "../../../Application/layout";
import { useAccountRedirectObserver } from "./useAccountRedirectObserver";

export const AccountRedirect = ({ children }: React.PropsWithChildren) => {
  const [initialized] = useAccountRedirectObserver();

  if (initialized) {
    return <>{children}</>;
  }

  return <AppLoader />;
};
