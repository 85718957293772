const prefix = "notification";

export const ProgressUpdated = `${prefix}:ProgressUpdated`;
export const FlowCompleted = `${prefix}:FlowCompleted`;
export const EvaluateAssessmentSuccess = `${prefix}:EvaluateAssessmentSuccess`;
export const ClientUserSessionRegistrationSuccess = `${prefix}:ClientUserSessionRegistrationSuccess`;
export const ClientUserSessionUnregistrationSuccess = `${prefix}:ClientUserSessionUnregistrationSuccess`;
export const ClosedCaptionsAddSuccess = `${prefix}:ClosedCaptionsAddSuccess`;
export const ClosedCaptionsAddFailure = `${prefix}:ClosedCaptionsAddFailure`;
export const ClosedCaptionsTranslateFailure = `${prefix}:ClosedCaptionsTranslateFailure`;
export const ClientFlowStarted = `${prefix}:ClientFlowStarted`;
