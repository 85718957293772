import axios from "axios";
import { Group, GroupMember, SortType } from "../types";
import { getParams } from "../../Application/utils/query";
import { SearchResult } from "../../Discover/types";

export const defaultGroupsTake = 50;

export async function fetchGroups(sortType: SortType, skip = 0, take: number = defaultGroupsTake) {
  const params = getParams(skip, take);
  addSortParams(params, sortType);
  const resp = await axios.get<Group[]>(`/api/client/groups`, { params });
  return [resp.data, resp.headers];
}

export async function getGroup(id: number) {
  const resp = await axios.get<Group>(`/api/client/groups/${id}`);
  return resp.data;
}

export async function getMembers(
  groupId: number,
  term = "",
  skip = 0,
  take = defaultGroupsTake,
): Promise<[GroupMember[], any]> {
  const params = getParams(skip, take);
  params.append("term", term);
  const resp = await axios.get<GroupMember[]>(`/api/client/groups/${groupId}/members`, { params });
  return [resp.data, resp.headers];
}

export async function getContent(id: number, skip: number, take = defaultGroupsTake): Promise<SearchResult[]> {
  const params = getParams(skip, take);

  const response = await axios.get(`/api/v4/client/groups/${id}/content`, {
    params,
  });

  return response.data;
}

function addSortParams(params: URLSearchParams, sortType: SortType) {
  switch (sortType) {
    case SortType.NameZa:
      params.append("orderBy", "name desc");
      break;
    case SortType.CreationDate:
      params.append("orderBy", "dateCreated desc");
      break;
    case SortType.Popularity:
      params.append("orderBy", "membersCount desc");
      break;
    default:
      params.append("orderBy", "name asc");
  }
}

export function joinGroup(groupId: number) {
  return axios.post(`/api/client/groups/${groupId}/join`);
}

export function leaveGroup(groupId: number) {
  return axios.post(`/api/client/groups/${groupId}/leave`);
}
