export class Routes {
  public static Discover = class {
    public static get exact() {
      return "/discover";
    }

    public static get any() {
      return "/discover/*";
    }

    public static get assessment() {
      return "/discover/assessment";
    }

    public static get survey() {
      return "/discover/survey";
    }

    public static get event() {
      return "/discover/event";
    }

    public static get flows() {
      return "/discover/flows";
    }

    public static get pdf() {
      return "/discover/pdf";
    }

    public static get asset() {
      return "/discover/asset";
    }
  };
}
