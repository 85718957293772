import classNames from "classnames/bind";
import { Icon } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import styles from "./ContentIcon.module.scss";
import { EntityType } from "../../../../common/types";

const cx = classNames.bind(styles);

interface Props {
  hideAccessible?: boolean;
  inverted?: boolean;
  type?: EntityType;
}

export const ContentIcon = (props: Props) => {
  const { hideAccessible, inverted, type } = props;
  const { t } = useTranslation(["discover"]);
  let icon, textKey;

  switch (type) {
    case EntityType.Event:
    case EntityType.UpcomingEvent:
      [icon, textKey] = ["calendar day outline", "results.event"];
      break;
    case EntityType.Group:
      [icon, textKey] = ["users outline", "results.group"];
      break;
    case EntityType.Video:
      [icon, textKey] = ["play outline", "results.video"];
      break;
    case EntityType.Pdf:
      [icon, textKey] = ["file pdf outline", "results.pdf"];
      break;
    case EntityType.Assessment:
      [icon, textKey] = ["list ol", "results.assessment"];
      break;
    case EntityType.Survey:
      [icon, textKey] = ["list ul", "results.survey"];
      break;
    case EntityType.Flow:
      [icon, textKey] = ["content", "results.flow"];
      break;
    default:
      [icon, textKey] = ["content", "results.flow"];
      break;
  }

  return (
    <div className={cx(styles.type, { [styles.inverted]: inverted })} tabIndex={hideAccessible ? -1 : 0}>
      <Icon className={icon} data-testid="content-icon-icon" />
      <span className={styles.iconText} data-testid="content-icon-span">
        {t(textKey).toLocaleUpperCase()}
      </span>
    </div>
  );
};
