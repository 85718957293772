import classNames from "classnames/bind";
import defaultUserAvatar from "../../../../assets/images/default-user-avatar.png";

import styles from "./GroupMember.module.scss";

const cx = classNames.bind(styles);

export interface Props {
  thumbnailUrl: string;
  name: string;
  jobTitle: string | null;
  isPrivate?: boolean;
}

export const GroupMember = ({ thumbnailUrl, name, jobTitle, isPrivate }: Props) => {
  return (
    <div className={styles.memberRoot} data-testid="Group Member">
      <img className={styles.memberImage} src={isPrivate ? defaultUserAvatar : thumbnailUrl} alt={name} />
      <div className={styles.memberInfo}>
        <a className={cx(styles.memberName, { [styles.private]: isPrivate })} href={`mailto:example@example.com`}>
          {!isPrivate && name}
        </a>
        <span className={cx(styles.memberTitle, { [styles.private]: isPrivate })}>
          {!isPrivate && jobTitle ? jobTitle : ""}
        </span>
      </div>
    </div>
  );
};
