import { useCallback, useEffect, useState, memo } from "react";

/**
 * Runs a function when the window scrolls to a certain point.
 * @param callback Function to run
 * @returns Element to place in your component that will listen for scrolling
 */
export default function useScrollPoint(
  callback: () => void,
  options: IntersectionObserverInit = {
    threshold: 0.9,
    root: document.body,
  },
) {
  const [mountedNode, setMountedNode] = useState<HTMLDivElement>();

  const mountedRef = useCallback((node: HTMLDivElement | null) => {
    if (node !== null) {
      setMountedNode(node);
    }
  }, []);

  useEffect(() => {
    if (!mountedNode) {
      return;
    }
    const observer = new IntersectionObserver(entries => {
      // Don't have a good way to test intersection observers right now
      /* istanbul ignore next */
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          callback();
        }
      });
    }, options);

    observer.observe(mountedNode);
    return () => observer.unobserve(mountedNode);
  }, [mountedNode, callback, options]);

  return <div ref={mountedRef} />;
}

interface Props {
  callback: () => void;
  options?: IntersectionObserverInit;
}

const ScrollPointComponent = (props: Props) => {
  const node = useScrollPoint(props.callback, props.options);
  return node;
};

export const ScrollPoint = memo(ScrollPointComponent);
