import axios from "axios";

export type UrlSourceTrackingPayload = {
  url: string;
  origin: string;
  campaign: string;
  medium: string;
  source: string;
  type: string;
};

export function trackUrl(payload: UrlSourceTrackingPayload) {
  return axios.post(`/api/utm`, payload);
}
