import classNames from "classnames/bind";
import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { EventViewer } from "../../../Events/components/EventViewer/EventViewer";
import { assetErr, nextAsset, registerOnEventSessionInFlow, skip, startTransition } from "../../redux/flow/flowSlice";

import { useTranslation } from "react-i18next";
import { Button } from "../../../Application/layout";
import { RtnStatus } from "../../../Application/types";
import { selectAreAllSessionsUnavailable } from "../../../Events/redux/sessions/sessionsSlice";
import { selectUpdateStatus } from "../../redux/flow/flowSelectors";
import { FlowExternalEventMeta, FlowProgress } from "../../types";
import styles from "./FlowEvent.module.scss";

const cx = classNames.bind(styles);

export interface Props {
  event: FlowExternalEventMeta;
  hidden: boolean;
  order: number;
  progress?: FlowProgress;
  isViewed: boolean;
}

export const FlowEvent: React.FC<Props> = ({ event, hidden, order, progress, isViewed }) => {
  const { flowId } = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation(["events", "assets"]);
  const areAllSessionUnavailable = useSelector(selectAreAllSessionsUnavailable);
  const flowUpdateStatus = useSelector(selectUpdateStatus);

  useEffect(() => {
    if (!isViewed && areAllSessionUnavailable && flowUpdateStatus === RtnStatus.Idle) {
      dispatch(startTransition({ assetOrder: order }));
      dispatch(skip({ assetId: event.id, assetType: event.type }));
    }
  }, [isViewed, event, dispatch, order, areAllSessionUnavailable, flowUpdateStatus]);

  const onRegistration = useCallback(
    (sessionId: string, registrationType: number, sessionStartDate: string) => {
      dispatch(
        registerOnEventSessionInFlow({
          flowId: Number(flowId),
          eventId: event.id,
          sessionId: sessionId,
          registrationType: registrationType,
          sessionStartDate: sessionStartDate,
        }),
      );
    },
    [dispatch, event, flowId],
  );

  const onError = useCallback(
    (code: number, message?: string) => {
      dispatch(assetErr({ asset: event, message: message, code: code }));
    },
    [dispatch, event],
  );

  const onNext = useCallback(() => {
    dispatch(nextAsset({ assetOrder: order }));
  }, [dispatch, order]);

  return (
    <div className={cx(styles.root, { [styles.hidden]: hidden })}>
      <EventViewer onError={onError} event={event} onRegistration={onRegistration} className={styles.eventViewer} />
      <div className={styles.footer}>
        <Button onClick={onNext} disabled={!(progress?.viewedAssetIndexes ?? []).includes(order)}>
          {t("common.next", {
            ns: "assets",
          })}
        </Button>
      </div>
    </div>
  );
};
