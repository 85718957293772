import classNames from "classnames/bind";
import React, { useCallback, useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Icon } from "semantic-ui-react";
import { BreakpointContext } from "../../../Application/components/context/BreakpointContext";
import { InvisibleButton } from "../../../Application/layout";
import { FlowEntityType } from "../../../common/types";
import useCollapse from "../../hooks/useCollapse";
import { setAssetIndex } from "../../redux/flow/flowSlice";
import { AssetWithProgressFields, FlowCourseItem, FlowCourseItemWithPassed, FlowProgress } from "../../types";
import { FlowProgressCourse } from "../FlowProgressCourse/FlowProgressCourse";
import styles from "./FlowContents.module.scss";

const cx = classNames.bind(styles);

export interface Props {
  courses: FlowCourseItem[];
  contentVisible: boolean;
  toggleContent: () => void;
  progress?: FlowProgress;
}

export const FlowContents: React.FC<Props> = ({ courses, contentVisible, toggleContent, progress }) => {
  const { t } = useTranslation(["flows"]);
  const dispatch = useDispatch();
  const assetCount = useMemo(
    () =>
      courses.reduce((acc, current) => {
        const items = current.items.filter(item => item.type !== FlowEntityType.FlowEnd);
        return acc + items.length;
      }, 0),
    [courses],
  );

  const { collapsed, toggleCollapse } = useCollapse();
  const [collapsedByUser, setCollapsedByUser] = useState(false);
  const { isMobileScreen, isTabletPortraitScreen } = useContext(BreakpointContext);
  const collapsedNotMobile = (collapsed && !isMobileScreen) || (!collapsedByUser && isTabletPortraitScreen);

  const onToggleCollapse = () => {
    if (!collapsedByUser) {
      setCollapsedByUser(true);
    }
    if (!isTabletPortraitScreen || collapsedByUser) {
      toggleCollapse();
    }
  };

  const dispatchAssetIndex = useCallback(
    (index: number) => {
      dispatch(setAssetIndex({ index }));
      if (isMobileScreen) {
        toggleContent();
      }
    },
    [dispatch, isMobileScreen, toggleContent],
  );

  const coursesWithPassed: FlowCourseItemWithPassed[] = useMemo(() => {
    return courses.map(c => ({
      ...c,
      coursePassed: isCoursePassed(c.items, progress?.viewedAssetIndexes),
    }));
  }, [courses, progress?.viewedAssetIndexes]);
  return (
    <section
      className={cx(styles.root, {
        [styles.collapsed]: collapsedNotMobile,
        [styles.hidden]: isMobileScreen && !contentVisible,
      })}
    >
      <div className={styles.header}>
        <div className={styles.left}>
          <InvisibleButton
            onClick={isMobileScreen ? toggleContent : onToggleCollapse}
            ariaLabel={collapsed ? t("ariaLabel.expandSidebar") : t("ariaLabel.collapseSidebar")}
          >
            {isMobileScreen ? (
              <Icon data-testid="times" className="times" fitted />
            ) : (
              <Icon data-testid="bars" className="bars" fitted />
            )}
          </InvisibleButton>
          {!collapsedNotMobile && (
            <span aria-label={t("contents")} tabIndex={0} className={styles.title}>
              {t("contents")}
            </span>
          )}
        </div>
        {!collapsedNotMobile && (
          <div
            className={styles.total}
            tabIndex={0}
            aria-label={t(assetCount > 1 ? "ariaLabel.assetsPlural" : "ariaLabel.assetsSingular", {
              count: assetCount,
            })}
          >
            ({t("assetsWithCount", { count: assetCount })})
          </div>
        )}
      </div>
      <div className={styles.assets}>
        {coursesWithPassed.map((course, i) => (
          <FlowProgressCourse
            isFirst={i === 0}
            coursePassed={course.coursePassed}
            previousCoursePassed={coursesWithPassed[i - 1] && coursesWithPassed[i - 1].coursePassed}
            key={course.header.id}
            header={course.header}
            items={course.items}
            collapsedNotMobile={collapsedNotMobile}
            progress={progress}
            onProgressItemClick={dispatchAssetIndex}
          />
        ))}
      </div>
    </section>
  );
};

function isCoursePassed(items: AssetWithProgressFields[], viewedAssetIndexes: FlowProgress["viewedAssetIndexes"] = []) {
  for (let item of items) {
    const itemPassed = viewedAssetIndexes.find(ind => ind === item.order);
    if (itemPassed === undefined) {
      return false;
    }
  }
  return true;
}
