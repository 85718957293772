export namespace HTML {
  export const isTextTruncated = (divEl: React.MutableRefObject<HTMLElement | null>) => {
    const divNode = divEl.current;
    const textNode = divNode?.firstElementChild;

    return textNode && textNode.clientHeight < textNode.scrollHeight;
  };
}

export default HTML;
