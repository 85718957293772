import i18next from "i18next";
import videojs from "video.js";

import { CustomVjsComponentNames } from "../customVjsComponentNames";
import SettingsMenuButton from "./SettingsMenuButton";
import "./settingsMenu.scss";

const Plugin = videojs.getPlugin("plugin") as any;

export default class SettingsPlugin extends Plugin {
  constructor(player: any, options: Object) {
    super(player, options);

    videojs.registerComponent(CustomVjsComponentNames.settingsMenuItem, SettingsMenuButton);

    player.on(["loadedmetadata"], () => {
      let settingsMenu = player.controlBar.getChild(CustomVjsComponentNames.settingsMenuItem);
      if (settingsMenu) {
        player.controlBar.removeChild(settingsMenu);
      }
      let settingsButton = player.controlBar.addChild(CustomVjsComponentNames.settingsMenuItem, {
        text: i18next.t("assets:controls.settings"),
        // @ts-ignore
        ...options.controlBar.settingsMenuButton,
      });
      settingsButton.addClass("vjs-settings-menu");
    });
  }
}
