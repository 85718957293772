import axios from "axios";

import { getParams } from "../../Application/utils/query";
import withAbortSignal from "../../common/decorators/withAbortSignal";
import { FilterSlice, SearchResult, SortType } from "../types";

async function fetchSearchResultsCore(
  term: string,
  filters: FilterSlice,
  sort = SortType.Relevant,
  skip = 0,
  take: number = 10,
  signal: AbortSignal | undefined = undefined,
): Promise<SearchResult[]> {
  const params = getParams(skip, take);
  params.append("term", term || "");

  addSortParams(params, sort);

  for (const key in filters) {
    if (filters[key].length > 0) {
      filters[key].forEach(f => {
        params.append(key, f.toString());
      });
    }
  }

  const path = "/api/v4/client/discovery";

  const response = await axios.get(path, { params, signal: signal });
  return response.data;
}

export const fetchSearchResultsDiscover = withAbortSignal(fetchSearchResultsCore);
export const fetchSearchResultsSearch = withAbortSignal(fetchSearchResultsCore);

function addSortParams(params: URLSearchParams, sortType: SortType) {
  if (sortType === SortType.Recent) {
    params.append("sortOrder", "desc");
    params.append("sortBy", "issuedDate");
  }
}
