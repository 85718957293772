import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../Application/store/store";
import { RStatus } from "../../../Application/types";
import { MainContact } from "../../types";

interface MainContactState {
  status: RStatus;
  current: MainContact | null;
  errorMessage: string | undefined;
}

const initialState: MainContactState = {
  status: RStatus.Idle,
  current: null,
  errorMessage: undefined,
};

const mainContactSlice = createSlice({
  name: "mainContact",
  initialState: initialState,
  reducers: {
    req(state) {
      state.status = RStatus.Pending;
    },
    got(state, action: PayloadAction<MainContactState["current"]>) {
      state.status = RStatus.Got;
      state.current = action.payload;
    },
    err(state, action: PayloadAction<MainContactState["errorMessage"]>) {
      state.status = RStatus.Error;
      state.errorMessage = action.payload;
    },
  },
});

// actions
export const { req, got, err } = mainContactSlice.actions;

// selectors
export const selectMainContact = (state: RootState) => state.account.mainContact.current;
export const selectMainContactStatus = (state: RootState) => state.account.mainContact.status;
export const selectMainContactError = (state: RootState) => state.account.mainContact.errorMessage;

// reducers
export default mainContactSlice.reducer;
