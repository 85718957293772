import { LazyLoading, Props as LazyLoadingProps } from "./LazyLoading";
import { ActionCreatorWithoutPayload } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";

import styles from "./LazyLoadingCards.module.scss";
import React, { useCallback } from "react";
import useScrollPoint from "../../hooks/useScrollPoint";

interface LazyLoadWithCallbackProps<T> extends LazyLoadingProps<T> {
  reqAction: ActionCreatorWithoutPayload<string>;
}

type Props<T> = Omit<LazyLoadWithCallbackProps<T>, "scrollContainer">;

export const LazyLoadingCards = <T,>(props: Props<T>) => {
  const dispatch = useDispatch();

  const mainElement = useScrollPoint(
    useCallback(() => {
      dispatch(props.reqAction());
    }, [props, dispatch]),
  );
  return (
    <div className={styles.root}>
      <LazyLoading {...props} scrollContainer={mainElement} />
    </div>
  );
};
