import React, { memo } from "react";
import { Icon } from "semantic-ui-react";
import { InvisibleButton } from "../../../Application/layout";

import styles from "./SortToggler.module.scss";
import { useTranslation } from "react-i18next";

interface Props {
  toggleSort: () => void;
  includeTextOnToggle: boolean;
}

export const SortToggler = (props: Props) => {
  const { toggleSort, includeTextOnToggle } = props;
  const { t } = useTranslation(["discover"]);

  return (
    <InvisibleButton onClick={toggleSort} ariaLabel={t("sort.label")} data-testid="sort button">
      <span className={styles.container}>
        <Icon name="sort amount down" />
        {includeTextOnToggle && <label className={styles.sortLabel}>{t("sort.label")}</label>}
      </span>
    </InvisibleButton>
  );
};

export default memo(SortToggler);
