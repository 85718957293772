import { useEffect, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import { queryStringService } from "../../Application/utils/queryStringService";

export const useQueryObserver = <T>(observable: T) => {
  const mounted = useRef(false);

  const [searchParams, setSearchParams] = useSearchParams();

  const initialValues = useRef(queryStringService.parse<DeepPartial<T>>(searchParams.toString()));

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
      return;
    }
    const currentSearch = queryStringService.stringify(observable);

    setSearchParams(currentSearch, { replace: true });
  }, [observable, setSearchParams]);

  return [initialValues.current];
};
