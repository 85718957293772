import EventEmitter from "events";
import { Dispatch } from "@reduxjs/toolkit";
import { AsyncOperationType, endAsyncOperation } from "../../slices/asyncOperationSlice";

const rtnHandler = (eventEmitter: EventEmitter, asyncActionsProvider: () => AsyncOperationType, dispatch: Dispatch) => {
  const canHandle = (event: string, payload: any) => {
    const eventIds = asyncActionsProvider()[event];
    if (!payload?.id || !eventIds) {
      return { canHandle: true, isAsyncOperationFound: false };
    }
    const isAsyncOperationFound = new Set<number>(eventIds).has(payload.id);
    return { canHandle: isAsyncOperationFound, isAsyncOperationFound };
  };

  const handleCore = (event: string, payload: any, isAsyncOperationFound: boolean) => {
    eventEmitter.emit(event, payload);
    isAsyncOperationFound && dispatch(endAsyncOperation({ id: payload.id, action: event }));
  };

  return {
    handle: (event: string, payload: string) => {
      const parsedPayload = payload ? JSON.parse(payload) : null;
      const result = canHandle(event, parsedPayload);
      result.canHandle && handleCore(event, parsedPayload, result.isAsyncOperationFound);
    },
  };
};

export default rtnHandler;
