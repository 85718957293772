import classNames from "classnames/bind";
import { Dispatch, SetStateAction, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Icon } from "semantic-ui-react";
import defaultUserAvatar from "../../../../../assets/images/default-user-avatar.png";

import { selectProfileOriginalImage } from "../../../redux/profile/profileSlice";
import { OnClickOutside } from "../../OnClickOutside/OnClickOutside";
import styles from "./AccountMenuDropdown.module.scss";

const cx = classNames.bind(styles);

export interface Props {
  opened: boolean;
  setOpened: Dispatch<SetStateAction<boolean>>;
  children: React.ReactNode;
}

export const AccountMenuDropdown: React.FC<Props> = ({ children, opened, setOpened }) => {
  const { t } = useTranslation("account");

  const [imageError, setImageError] = useState(false);
  const imageUrl = useSelector(selectProfileOriginalImage);

  const toggle = () => {
    setOpened(s => !s);
  };

  const handleOpened = useCallback(() => {
    setOpened(false);
  }, [setOpened]);

  return (
    <div className={styles.root}>
      <OnClickOutside handler={handleOpened}>
        <button className={styles.toggler} onClick={toggle}>
          <div className={styles.avatar}>
            <img
              src={imageUrl && !imageError ? imageUrl : defaultUserAvatar}
              className={styles.avatarImg}
              alt="avatar"
              onLoad={() => setImageError(false)}
              onError={() => setImageError(true)}
            />
          </div>
          <span className={styles.label}>{t("common.myAccount")}</span>
          <Icon name={opened ? "angle up" : "angle down"} />
        </button>
        <div className={cx(styles.inner, { [styles.opened]: opened })}>{children}</div>
      </OnClickOutside>
    </div>
  );
};
