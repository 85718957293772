import { all, call, put, select, takeLatest } from "redux-saga/effects";
import { ClientUserSessionRegistrationSuccess } from "../../../Application/services/realTimeNotification/events/libraryEvents";
import { beginAsyncOperation } from "../../../Application/slices/asyncOperationSlice";
import { RStatus } from "../../../Application/types";
import {
  fetchEventReminders,
  fetchExternalEventSessionsById,
  remindMe,
  registerOnEventSessionV4,
  unregisterFromEventSessionV4,
} from "../../services/eventsService";
import { EventReminder, EventSession, SessionRegistrationRequest } from "../../types";
import {
  req,
  EventSessionsState,
  got,
  err,
  updateStatus,
  outOfResults,
  unregisterFromSession,
  fetchMore,
  registerOnSession,
  remindMe as remindMeAction,
  selectSessionsState,
} from "./sessionsSlice";

export function* handleFetchNewSessions(action: ReturnType<typeof req>) {
  try {
    const state: EventSessionsState = yield select(selectSessionsState);
    const { activePage, top, fromDateUtc } = state;
    const skip = Math.max(0, activePage - 1) * top;

    const [sessions, reminders]: [EventSession[], EventReminder[]] = yield all([
      call(fetchExternalEventSessionsById, action.payload.id, fromDateUtc, skip, top),
      call(fetchEventReminders, action.payload.id),
    ]);

    yield call(testForOutOfResults, sessions, top);
    yield put(got({ items: sessions, reminders }));
  } catch (error: any) {
    yield handleError(error);
  }
}

export function* handleFetchMoreSessions(action: ReturnType<typeof req>) {
  try {
    const state: EventSessionsState = yield select(selectSessionsState);
    const { activePage, top, fromDateUtc, eventId, reachedEnd, items, reminders } = state;
    if (reachedEnd && items) {
      yield put(updateStatus({ status: RStatus.Got }));
      return;
    }
    const skip = (activePage - 1) * top;
    const sessions: EventSession[] = yield call(fetchExternalEventSessionsById, eventId, fromDateUtc, skip, top);

    yield call(testForOutOfResults, sessions, top);
    yield put(got({ items: items.concat(sessions), reminders }));
  } catch (error: any) {
    yield handleError(error);
  }
}

export function* testForOutOfResults(sessions: EventSession[], top: number) {
  if (sessions.length < top) {
    yield put(outOfResults());
  }
}

export function* handleRegisterOnSession(action: ReturnType<typeof registerOnSession>) {
  try {
    const request: SessionRegistrationRequest = { ...action.payload };
    yield put(
      beginAsyncOperation({
        id: action.payload.eventId,
        action: ClientUserSessionRegistrationSuccess,
      }),
    );
    yield call(registerOnEventSessionV4, request);
  } catch (error: any) {
    yield handleError(error);
  }
}

export function* handleUnregisterFromSession(action: ReturnType<typeof unregisterFromSession>) {
  try {
    yield call(
      unregisterFromEventSessionV4,
      action.payload.eventId,
      action.payload.sessionId,
      action.payload.sessionStartDateTime,
    );
  } catch (error: any) {
    yield handleError(error);
  }
}

export function* handleRemindMe(action: ReturnType<typeof remindMeAction>) {
  try {
    yield call(remindMe, action.payload);
  } catch (error) {
    yield handleError(error);
  }
}

const handleError = (error: any) => put(err({ errorMessage: error?.message, code: error.response?.status }));

export function* eventSessionsWatcherSaga() {
  yield takeLatest(req.type, handleFetchNewSessions);
  yield takeLatest(fetchMore.type, handleFetchMoreSessions);
  yield takeLatest(registerOnSession.type, handleRegisterOnSession);
  yield takeLatest(unregisterFromSession.type, handleUnregisterFromSession);
  yield takeLatest(remindMeAction.type, handleRemindMe);
}
