import { eventChannel, Buffer, END } from "redux-saga";
import RtnEventsEmitter from "./rtnEventsEmitter";

export interface RtnChannelEvent {
  event: string;
  payload: any;
}

export function createRtnChannel(
  events: string[],
  timeout?: number,
  buffer: Buffer<RtnChannelEvent> | undefined = undefined,
) {
  return eventChannel<RtnChannelEvent>(emitter => {
    const unsubs: (() => void)[] = [];
    for (const event of events) {
      const handler = (payload: any) =>
        emitter({
          event,
          payload,
        });

      RtnEventsEmitter.subscribe(event, handler);
      unsubs.push(() => RtnEventsEmitter.unsubscribe(event, handler));
    }

    if (timeout) {
      setTimeout(() => {
        emitter(END);
      }, timeout);
    }

    return () => {
      unsubs.forEach(unsub => unsub());
    };
  }, buffer);
}
