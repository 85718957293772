import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { EmptyState } from "../../../Application/components/EmptyState/EmptyState";
import { AppErrorView } from "../../../Application/layout";
import { RStatus } from "../../../Application/types";
import { ContentList } from "../../../Discover/components/ContentList/ContentList";
import {
  fetchContentMore,
  selectContent,
  selectContentError,
  selectContentResultsReachedEnd,
  selectContentStatus,
} from "../../redux/group/groupSlice";

interface Props {
  interactive: boolean;
}

export const GroupContent: React.FC<Props> = ({ interactive }) => {
  const content = useSelector(selectContent);
  const contentStatus = useSelector(selectContentStatus);
  const contentError = useSelector(selectContentError);

  const { t } = useTranslation("groups");

  return (
    <>
      {contentStatus === RStatus.Got && content.length > 0 && (
        <ContentList
          selectResult={selectContent}
          selectStatus={selectContentStatus}
          selectError={selectContentError}
          selectReachedEnd={selectContentResultsReachedEnd}
          fetchMore={fetchContentMore}
          hideTitle={true}
          interactive={interactive}
        />
      )}
      {contentStatus === RStatus.Got && content.length === 0 && (
        <EmptyState title={t("noContentInGroup")} description={t("checkBackLater")} iconClassName="folder open" />
      )}
      {contentStatus === RStatus.Error && <AppErrorView message={contentError} />}
    </>
  );
};
