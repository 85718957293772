import React from "react";
import styles from "./FixedHeightAppPageWrapper.module.scss";

interface Props {
  reactStrictMode?: boolean;
  children: React.ReactNode;
}

export const FixedHeightAppPageWrapper: React.FC<Props> = ({ children, reactStrictMode = true }) => {
  if (reactStrictMode) {
    return (
      <React.StrictMode>
        <div className={styles.root}>{children}</div>
      </React.StrictMode>
    );
  }
  return <div className={styles.root}>{children}</div>;
};
