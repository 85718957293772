import { ProgressRecord, ProgressStatus } from "../../../types";

export interface RecordDisabledOptions {
  index: number;
  firstRecordWithDelayIndex?: number;
  record: ProgressRecord;
}

export const checkIsRecordAfterDelayed = ({ index, firstRecordWithDelayIndex }: RecordDisabledOptions) => {
  if (!firstRecordWithDelayIndex) {
    return false;
  }

  return index > firstRecordWithDelayIndex;
};

export const checkIsRecordWithNextStatus = ({
  record: {
    status,
    bag: { availableAt },
  },
}: RecordDisabledOptions) => {
  return status === ProgressStatus.Next && !availableAt;
};

export const checkIsProgressRecordDisabled = (options: RecordDisabledOptions) => {
  const rules = [checkIsRecordWithNextStatus, checkIsRecordAfterDelayed];

  for (const checkRule of rules) {
    if (checkRule(options)) {
      return true;
    }
  }

  return false;
};
