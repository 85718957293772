import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../../Application/store/store";

// selectors

export const selectFlow = (state: RootState) => state.flows.flow.current;
export const selectSectionHeaders = (state: RootState) => state.flows.flow.current?.flow.sectionHeaders;
export const selectSectionProgress = (state: RootState) => state.flows.flow.current?.progress;
export const selectFlowStatus = (state: RootState) => state.flows.flow.status;
export const selectFlowError = (state: RootState) => state.flows.flow.error;
export const selectProgress = (state: RootState) => state.flows.flow.progress;
export const selectAssets = (state: RootState) => state.flows.flow.assets;
export const selectEvaluatedAssessment = (state: RootState) => state.flows.flow.evaluatedAssessment;
export const selectAssessmentCurrentStep = (state: RootState) => state.flows.flow.currentAssessmentStep;
export const selectAssetsMap = (state: RootState) => state.flows.flow.assetsMap;
export const selectCurrentProgressRecords = (state: RootState) => state.flows.flow.current?.progress.records;
export const selectProgressSelectedAssetIndex = (state: RootState) => state.flows.flow.progress.selectedAssetIndex;
export const selectUpdateStatus = (state: RootState) => state.flows.flow.updateStatus;
export const selectRedirectId = (state: RootState) => state.flows.flow.redirectId;

export const assetStatusSelector = createSelector(
  [selectCurrentProgressRecords, selectProgressSelectedAssetIndex],
  (progressRecords, assetIndex) => progressRecords?.find(record => Number(record.order) === assetIndex)?.status,
);
