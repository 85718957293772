import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { userInTeams } from "../../../../tools/teams/teamsService";
import { AppLoader } from "../../../Application/layout";
import { userManager } from "../../config/userManager";

export const SignoutCallback = () => {
  const navigate = useNavigate();

  useEffect(() => {
    async function processSignout() {
      const response = await userManager.signoutRedirectCallback(window.location.href);

      if (userInTeams()) {
        window.location.assign(`${window.envtConfig.teamsAppUrl}home-both?isFirstTime=true`);
      } else {
        const redirectLocation = response.userState ?? "/";
        navigate(redirectLocation, { replace: true });
      }
    }

    processSignout();
  }, [navigate]);

  return <AppLoader />;
};
