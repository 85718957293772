import classNames from "classnames/bind";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Icon } from "semantic-ui-react";

import { Button } from "../../../Application/layout/Button/Button";
import { isoStringToDate } from "../../../Application/utils/dateTime";
import { joinGroup, leaveGroup } from "../../services/groupsService";
import { Group } from "../../types";
import { Access } from "../../types/enums";
import { UserIcon } from "./UserIcon";

import { ReactComponent as LockIcon } from "../../../../assets/images/lock.svg";
import { ReactComponent as UnlockIcon } from "../../../../assets/images/unlock.svg";

import { groupActionButtonShouldBeAvailable } from "../../common/methods";
import styles from "./GroupCard.module.scss";
import { CardImage } from "features/Application/components/Card/CardImage/CardImage";

const cx = classNames.bind(styles);

interface GroupMember {
  imageUrl: string | null;
}

export interface Props extends Group {
  to?: string;
  isAccessRequested?: boolean;
  action?: () => void;
  members?: GroupMember[];
}

export const GroupCard: React.FC<Props> = props => {
  const {
    id: groupId,
    name,
    description,
    imageUrl,
    isOpen,
    isAware,
    isVisibleToAll,
    membersCount,
    joinedDate,
    to,
    isAccessRequested,
    action,
    members = [],
    isWithAutoEnroll,
  } = props;
  const [groupActionInProgress, setGroupActionInProgress] = useState(false);
  const { t } = useTranslation(["groups"]);
  const requestAccessText = isAccessRequested ? t("accessRequested") : t("requestAccess");
  const isJoined = useMemo(() => joinedDate !== null, [joinedDate]);

  const publicText = isOpen === Access.Open ? t("join") : requestAccessText;
  const btnText = joinedDate !== null ? t("leave") : publicText;

  const actionButtonShouldBeAvailable = useMemo(() => {
    return groupActionButtonShouldBeAvailable({
      isWithAutoEnroll,
      isAware,
      isVisibleToAll,
      isOpen,
    });
  }, [isAware, isVisibleToAll, isWithAutoEnroll, isOpen]);

  const performGroupAction = useCallback(() => {
    (async () => {
      // go to group overview
      setGroupActionInProgress(true);
      try {
        if (isJoined) {
          await leaveGroup(groupId);
        } else {
          await joinGroup(groupId);
        }
      } catch (err) {
        setGroupActionInProgress(false);
      }
    })();
  }, [groupId, isJoined]);

  useEffect(() => {
    setGroupActionInProgress(false);
  }, [joinedDate]);

  return (
    <div className={styles.root}>
      <div className={styles.cardImageContainer}>
        <CardImage
          url={imageUrl}
          alt={`${name} thumbnail`}
          link={to ? { to, ariaLabel: `Open group ${name}` } : undefined}
          action={action}
          playable={false}
          fixedRatio
        >
          <>
            {!imageUrl && (
              <div className={styles.noImage}>
                <Icon className={cx("users", styles.usersIcon)} />
              </div>
            )}
            {joinedDate && <div className={styles.joined}>{`${t("joined")} ${isoStringToDate(joinedDate)}`}</div>}
          </>
        </CardImage>
      </div>
      <div className={styles.cardContent}>
        {to ? (
          <Link to={to} className={styles.title}>
            {name}
          </Link>
        ) : (
          <div className={styles.title}>{name}</div>
        )}
        <div className={styles.description}>
          <span className={styles.descriptionText}>{description}</span>
        </div>
        <div className={styles.membersSection}>
          <div className={cx({ noMargin: members.length === 0 }, styles.memberPhotos)}>
            {members.slice(0, 4).map((member, i) => {
              const key = i;
              return <UserIcon key={key} imageUrl={member.imageUrl} altText="group member image" />;
            })}
          </div>
          <div className={styles.memberCount}>{t("memberCount", { count: membersCount })}</div>
        </div>
        <div className={styles.accessSection}>
          <div className={styles.accessType}>
            <div className={styles.accessIcon}>{isOpen === Access.Open ? <UnlockIcon /> : <LockIcon />}</div>
            <div className={styles.accessText}>{isOpen === Access.Open ? t("public") : t("private")}</div>
          </div>
          <div className={styles.button}>
            {actionButtonShouldBeAvailable && (
              <Button
                onClick={performGroupAction}
                disabled={isAccessRequested ?? groupActionInProgress}
                fullWidth
                data-testid="group action button"
                loading={groupActionInProgress}
              >
                {btnText}
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
