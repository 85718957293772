export const maxFlows = 4;
export const animationDuration = 500;

export enum Direction {
  Unset,
  Left,
  Right,
}

export type AnimationTypes = "fade right" | "fade left";

export interface AnimationState {
  // Controls when flows are mounted/unmounted
  // Set to -1 when we want to hide the flow but don't want to
  // reveal the next one immediately
  activeFlow: number;
  // Stores which flow we are going to after the "Hide"
  // transition is complete
  desiredActiveFlow: number;
  // Maintains current index in "1 of 4" section
  activeFlowContent: number;
  // Direction we're transitioning. Used to set the animationType
  // after onHide is called
  direction: Direction;
  // The semantic animation string. Needs to change between
  // the "Hide" and "Show" stages for the animation to look natural
  animationType: AnimationTypes;
}

export const initialAnimationState: AnimationState = {
  activeFlow: 0,
  desiredActiveFlow: 0,
  activeFlowContent: 0,
  direction: Direction.Unset,
  animationType: "fade left",
};

type ReducerOptions = "increment" | "decrement" | "hidecomplete";

export const reducer: React.Reducer<AnimationState, { type: ReducerOptions; payload?: any }> = (
  state,
  action,
): AnimationState => {
  let newDesiredFlow: number;
  switch (action.type) {
    case "increment":
      // Don't transition in the case of only 1 flow
      if (action.payload.flowCount <= 1) return state;

      if (state.desiredActiveFlow === action.payload.flowCount - 1 || state.desiredActiveFlow === maxFlows - 1) {
        newDesiredFlow = 0;
      } else {
        newDesiredFlow = state.desiredActiveFlow + 1;
      }

      return {
        ...state,
        desiredActiveFlow: newDesiredFlow,
        direction: Direction.Right,
        animationType: "fade right",
        activeFlow: -1,
      };
    case "decrement":
      if (action.payload.flowCount <= 1) return state;

      if (state.desiredActiveFlow === 0) {
        newDesiredFlow = Math.min(action.payload.flowCount - 1, maxFlows - 1);
      } else {
        newDesiredFlow = state.desiredActiveFlow - 1;
      }
      return {
        ...state,
        desiredActiveFlow: newDesiredFlow,
        direction: Direction.Left,
        animationType: "fade left",
        activeFlow: -1,
      };
    // This function is called twice from the component,
    // so make sure it doesn't flip/flop on animationType
    case "hidecomplete":
      if (state.direction === Direction.Unset) return state;
      return {
        ...state,
        animationType: state.direction === Direction.Left ? "fade right" : "fade left",
        direction: Direction.Unset,
        activeFlow: state.desiredActiveFlow,
        activeFlowContent: state.desiredActiveFlow,
      };
  }
};
