import { trackUrl } from "features/Auth/services/urlTrackingService";
import { isEmpty } from "lodash";
import { useEffect } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { ORIGIN_WEB, UTM_CAMPAIGN, UTM_MEDIUM, UTM_SOURCE, UTM_TYPE } from "./constants";

export type UrlTrackingParams = {
  campaign: string;
  medium: string;
  source: string;
  type: string;
  anyTrackingParamExist: boolean;
};

export const TrackSource = ({ children }: React.PropsWithChildren) => {
  const location = useLocation();
  const setSearchParams = useSearchParams()[1];

  useEffect(() => {
    try {
      const trackingParams = getTrackingParamsFromUrl(location.search);
      if (trackingParams.anyTrackingParamExist) {
        const params = deleteTrackingParamsFromUrl(location.search);
        setSearchParams(params.toString(), { replace: true });
        const url = new URL(window.location.href);
        url.search = params.toString();
        trackUrl({
          url: url.toString(),
          origin: ORIGIN_WEB,
          campaign: decodeURIComponent(trackingParams.campaign),
          medium: trackingParams.medium,
          source: trackingParams.source,
          type: trackingParams.type,
        });
      }
    } catch {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <>{children}</>;
};

export const getTrackingParamsFromUrl = (searchParams: string): UrlTrackingParams => {
  const params = new URLSearchParams(searchParams);

  let campaign = "";
  let medium = "";
  let source = "";
  let type = "";

  for (const [key, value] of params.entries()) {
    switch (key.toLowerCase()) {
      case UTM_CAMPAIGN.toLowerCase():
        campaign = value;
        break;
      case UTM_MEDIUM.toLowerCase():
        medium = value;
        break;
      case UTM_SOURCE.toLowerCase():
        source = value;
        break;
      case UTM_TYPE.toLowerCase():
        type = value;
        break;
      default:
        break;
    }
  }

  const anyParamExist = !isEmpty(campaign) || !isEmpty(medium) || !isEmpty(source);

  return {
    campaign,
    medium,
    source,
    type,
    anyTrackingParamExist: anyParamExist,
  };
};

export const deleteTrackingParamsFromUrl = (searchParams: string): URLSearchParams => {
  const params = new URLSearchParams(searchParams);

  for (const key of [UTM_CAMPAIGN, UTM_MEDIUM, UTM_SOURCE, UTM_TYPE].map(param => param.toLowerCase())) {
    for (const paramKey of params.keys()) {
      if (paramKey.toLowerCase() === key) {
        params.delete(paramKey);
      }
    }
  }

  return params;
};
