import i18next from "i18next";
import videojs from "video.js";

import Player from "video.js/dist/types/player";
import { CustomVjsComponentNames } from "../customVjsComponentNames";
import QualityLevelsItem, { QualityLevel } from "./QualityLevelsItem";
import QualityLevelsMenu from "./QualityLevelsMenu";

const Plugin = videojs.getPlugin("plugin") as any;

export default class QualityLevelsPlugin extends Plugin {
  constructor(player: Player, options: Object) {
    //@ts-ignore
    super(player, options);

    videojs.registerComponent(CustomVjsComponentNames.qualityLevelsItem, QualityLevelsItem);
    videojs.registerComponent(CustomVjsComponentNames.qualityLevelsMenu, QualityLevelsMenu);

    player.on(["loadedmetadata"], () => {
      const qualityLevels = (player as any).qualityLevels() as QualityLevels;
      qualityLevels?.levels_.forEach(level => {
        level.label = level.height + "p";
      });
      const controlBar = player.getChild("controlBar")!;
      let qualityLevelsMenu = controlBar.getChild(CustomVjsComponentNames.qualityLevelsMenu);
      if (qualityLevelsMenu) {
        controlBar.removeChild(qualityLevelsMenu);
      }
      controlBar.addChild(CustomVjsComponentNames.qualityLevelsMenu, {
        className: "vjs-settings-button",
        text: i18next.t("assets:controls.settings"),
        ...options,
      });
    });
  }
}

export interface QualityLevels {
  levels_: QualityLevel[];
  selectedIndex_: number;
  length: number;
  trigger: (trigger: { type: string; selectedIndex: number }) => void;
  [index: number]: QualityLevel;
  on: (event: string, handler: () => void) => void;
}
