import axios from "axios";
import dayjs from "dayjs";
import { MainContact } from "../types";

export interface MyData {
  gdprLink: string;
  requestedTime: string;
}

export async function fetchMyData(): Promise<MyData> {
  return axios({
    method: "get",
    url: "/api/dataexport/v1/gdpr/my-data",
    responseType: "blob",
  })
    .then(response => response.data)
    .then(data => createObjectUrl(data))
    .then(gdprLink => {
      return {
        gdprLink,
        requestedTime: dayjs().toISOString(),
      };
    });
}

export const createObjectUrl = (data: any): string => {
  const blob = new Blob([data], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });
  return window.URL.createObjectURL(blob);
};

export const getRemainingMinutes = (time?: string | null): number | null => {
  if (!time) return null;

  try {
    const MINUTES = Number(process.env.GDPR_REQUEST_TIMEOUT) || 60;
    const minutesPassed = dayjs().diff(dayjs(time), "minutes");

    return MINUTES - minutesPassed > 0 ? MINUTES - minutesPassed : null;
  } catch (_) {
    return null;
  }
};

export const getMainContact = () => {
  return axios.get<MainContact>("/api/client/accounts/contacts/main");
};
