import styles from "../PrivacyPolicyBanner.module.scss";

const PRIVACY_POLICY_LINK = "https://legal.brainstorminc.com/#privacy-policy";

const LINK_PROPS: React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement> = {
  className: styles.link,
  href: PRIVACY_POLICY_LINK,
  target: "_blank",
  rel: "noreferrer",
};

export interface Props {
  language: string;
}

/**
 * Sort of a pseudo-translation file for our privacy policy
 * @returns
 */
export const PrivacyBannerBody: React.FC<Props> = ({ language }) => {
  switch (language) {
    case "de":
      return (
        <>
          DE This website stores cookies on your computer. These cookies are used to collect information about how you
          interact with our website and allow us to remember you. We use this information in order to improve and
          customize your browsing experience and for analytics and metrics about our visitors both on this website and
          other media. To find out more about the cookies we use, see our <a {...LINK_PROPS}>Privacy Policy</a>.
        </>
      );
    case "en":
    default:
      return (
        <>
          This website stores cookies on your computer. These cookies are used to collect information about how you
          interact with our website and allow us to remember you. We use this information in order to improve and
          customize your browsing experience and for analytics and metrics about our visitors both on this website and
          other media. To find out more about the cookies we use, see our <a {...LINK_PROPS}>Privacy Policy</a>.
        </>
      );
  }
};
