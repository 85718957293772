import { useState, useEffect, useMemo } from "react";
import throttle from "lodash/throttle";

import { BreakpointValues } from "./breakpointValues";

export { BreakpointValues };

export type Breakpoints =
  | BreakpointValues.small
  | BreakpointValues.tabletPortrait
  | BreakpointValues.tabletLandscape
  | BreakpointValues.large;

// @see https://betterprogramming.pub/usebreakpoint-hook-get-media-query-breakpoints-in-react-3f1779b73568
const getDeviceConfig = (width: number) => {
  if (width <= 809) {
    return BreakpointValues.small;
  }
  if (width <= 1079) {
    return BreakpointValues.tabletPortrait;
  }
  if (width <= 1439) {
    return BreakpointValues.tabletLandscape;
  }
  return BreakpointValues.large;
};

const useBreakpoint = () => {
  const [brkPnt, setBrkPnt] = useState<Breakpoints>(() => getDeviceConfig(window.innerWidth));

  useEffect(() => {
    const calcInnerWidth = throttle(function () {
      setBrkPnt(getDeviceConfig(window.innerWidth));
    }, 200);
    window.addEventListener("resize", calcInnerWidth);
    return () => window.removeEventListener("resize", calcInnerWidth);
  }, []);
  return useMemo(
    () => ({
      breakpoint: brkPnt,
      isLargeScreen: brkPnt === BreakpointValues.large,
      isTabletScreen: brkPnt === BreakpointValues.tabletPortrait || brkPnt === BreakpointValues.tabletLandscape,
      isTabletLandscapeScreen: brkPnt === BreakpointValues.tabletLandscape,
      isTabletPortraitScreen: brkPnt === BreakpointValues.tabletPortrait,
      isMobileScreen: brkPnt === BreakpointValues.small,
    }),
    [brkPnt],
  );
};

export default useBreakpoint;
