import { ReactComponent as UserIconNoImg } from "../../../../assets/images/user.svg";

import styles from "./UserIcon.module.scss";

export interface Props {
  imageUrl: string | null;
  altText?: string;
}

export const UserIcon: React.FC<Props> = ({ imageUrl, altText }) => {
  return (
    <div className={styles.circle}>
      {imageUrl ? (
        <img className={styles.circle} src={imageUrl} alt={altText} />
      ) : (
        <UserIconNoImg data-testid="user-default-icon" />
      )}
    </div>
  );
};
