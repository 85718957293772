import { z } from "zod";
import { RegistrationOptions } from "../components/EventViewer/SessionRegistration/RegistrationOptions/SessionRegistrationOptions";
import {
  eventSchema,
  eventSessionSchema,
  eventAppointmentSchema,
  recurrenceSessionSchema,
  eventReminderSchema,
} from "./schemas";

export type Event = z.infer<typeof eventSchema>;

export type EventSession = z.infer<typeof eventSessionSchema>;

export type RecurrenceSession = z.infer<typeof recurrenceSessionSchema>;

export type GetEventAppointmentResponse = z.infer<typeof eventAppointmentSchema>;

export type EventReminder = z.infer<typeof eventReminderSchema>;

export enum PeriodType {
  Day = 0,
  Week,
  Month,
  Year,
}

export enum PeriodicRule {
  MonthlyOnExactDayInMonth = 0,
  MonthlyLastDayOfWeekInMonth = 1,
  MonthlyFirstDayOfWeekInMonth = 2,
  MonthlySecondDayOfWeekInMonth = 3,
  MonthlyThirdDayOfWeekInMonth = 4,
  MonthlyFourthDayOfWeekInMonth = 5,

  YearlyOnExactDayAndMonth = 10,
  YearlyLastDayOfWeekInMonth = 11,
  YearlyFirstDayOfWeekInMonth = 12,
  YearlySecondDayOfWeekInMonth = 13,
  YearlyThirdDayOfWeekInMonth = 14,
  YearlyFourthDayOfWeekInMonth = 15,
}

export enum ScheduleType {
  None = 0,
  Minutes,
  Hours,
  Days,
}

export interface GetEventAppointmentRequest {
  eventId: number;
  sessionId: string;
  sessionStartDate: string;
}

export interface TrackActivityRequest {
  sessionId: string;
  sessionStartDate: string;
  attendedDate: string;
}

export interface RemindMeRequest {
  sessionId: string;
  sessionStartDate: string;
  shouldRemind: boolean;
}

export interface EventAppointment {
  link: string;
  startsAt: number;
  endsAt: number;
  timeZone: string;
}

export interface SessionRegistrationRequest {
  eventId: number;
  sessionId: string;
  registrationType: number;
  sessionStartDate: string;
}

export type SessionRegistrationFlowRequest = SessionRegistrationRequest & {
  flowId: number;
};

export interface SessionRegistrationSuccess {
  accountId: number;
  actorId: number;
  externalEventId: number;
  sessionId: string;
  sessionStartDate: string;
  returnContentType: RegistrationOptions;
  calendarFileContent: string;
  teamsDialogLink: string;
}

export interface SessionUnregistrationSuccess {
  accountId: number;
  actorId: number;
  sessionId: string;
  sessionStartDate: string;
}
