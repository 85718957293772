import { Component } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "redux";

import initializeHubClient from "../../services/realTimeNotification/initializeHubClient";
import { setSignalRState } from "../../slices/signalRSlice";
import { RootState } from "../../store/store";

export class NotificationProvider extends Component<PropsFromRedux> {
  private realTimeNotificationClient;
  constructor(props: PropsFromRedux) {
    super(props);
    this.realTimeNotificationClient = initializeHubClient(
      this.props.dispatch,
      () => this.props.asyncOperations,
      this.props.setSignalRState,
    );
  }

  componentDidMount() {
    return this.realTimeNotificationClient.connect();
  }

  componentWillUnmount() {
    return this.realTimeNotificationClient.disconnect();
  }

  render() {
    return null;
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setSignalRState: (isConnected: boolean) => dispatch(setSignalRState(isConnected)),
  dispatch,
});

const mapStateToProps = (state: RootState) => {
  return {
    asyncOperations: state.asyncOperations,
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(NotificationProvider);
