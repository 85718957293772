import { useTranslation } from "react-i18next";
import { AssessmentQuestion as Question } from "../../../../Assets/types";

import styles from "./AssessmentQuestion.module.scss";
import { useCallback, Fragment } from "react";
import { AssessmentQuestionAnswers } from "./AssessmentQuestionAnswers";

interface Props {
  question: Question;
  isQuestionCorrect?: boolean;
  setSelectedAnswers?: (answers: boolean[]) => void;
  selectedAnswers: boolean[];
  correctAnswers?: boolean[] | null;
}

export const AssessmentQuestion: React.FC<Props> = ({
  question,
  isQuestionCorrect,
  setSelectedAnswers,
  selectedAnswers,
  correctAnswers,
}) => {
  const { t } = useTranslation("assets");
  const updateCheckbox = useCallback(
    (idx: number) => {
      const temp = selectedAnswers.slice();
      temp[idx] = !temp[idx];
      setSelectedAnswers?.(temp);
    },
    [selectedAnswers, setSelectedAnswers],
  );
  const updateRadio = useCallback(
    (idx: number) => {
      const temp = [];
      temp[idx] = true;
      setSelectedAnswers?.(temp);
    },
    [setSelectedAnswers],
  );

  const getResultText = (isCorrect?: boolean) => t(isCorrect ? "assessments.correct" : "assessments.incorrect");

  const getAriaResultText = (isCorrect?: boolean) => (isCorrect !== undefined ? getResultText(isCorrect) : "");

  const questionTitle = `${question.questionOrder + 1}. ${question.text} ${
    question.requireAllCorrectAnswers ? t("assessments.selectAllCorrectAnswers") : ""
  }`;

  return (
    <div className={styles.root}>
      <div
        id="question-title"
        aria-label={`${questionTitle} ${getAriaResultText(isQuestionCorrect)}`}
        aria-labelledby="correct-text"
        className={styles.questionTitle}
      >
        {questionTitle}
      </div>
      <div className={styles.answers} role="group" aria-labelledby="question-title">
        {question.answers.map((answer, idx) => {
          const correctAnswer = correctAnswers?.[idx];
          const selectedAnswer = !!selectedAnswers[idx];

          return (
            <Fragment key={answer}>
              <AssessmentQuestionAnswers
                answer={answer}
                idx={idx}
                question={question}
                updateCheckbox={updateCheckbox}
                updateRadio={updateRadio}
                selectedAnswer={selectedAnswer}
                correctAnswer={correctAnswer}
              />
            </Fragment>
          );
        })}
      </div>
    </div>
  );
};
