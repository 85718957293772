import { TFunction } from "i18next";
import { ReactComponent as OctagonExclamationIcon } from "../../../../../assets/images/octagon-exclamation.svg";
import { checkDateIsBeforeNow } from "../../../../Application/utils/date";
import { FlowEntity } from "../../../../common/types";
import { getDelayStringComponent } from "../../../helpers/flowHelper";
import { ProgressRecordBag } from "../../../types";

enum BagHintProperty {
  isDelayed = "isDelayed",
  isBranched = "isBranched",
}

export interface BagHintData {
  iconClassName: string;
  popupContent: React.ReactNode;
  isOutlineDisabled?: boolean;
  isSkipped?: boolean;
  customIcon?: React.ReactNode;
}

type BagHintsMap = {
  [key in BagHintProperty]: BagHintData;
};

const getDelayedHintData = (t: TFunction<string[]>, entityType: FlowEntity, availableAt?: string) => {
  const isTimeExpired = availableAt && checkDateIsBeforeNow(availableAt);
  if (isTimeExpired) {
    return {
      iconClassName: "",
      popupContent: "",
      isSkipped: true,
    };
  }

  return {
    iconClassName: "clock",
    popupContent: getDelayStringComponent(t, entityType, availableAt),
    isOutlineDisabled: true,
  };
};

const getBranchedHintData = (t: TFunction<string[]>) => ({
  iconClassName: "",
  popupContent: t("hints.branch.popup.content"),
  customIcon: <OctagonExclamationIcon />,
});

export const getBagHintsData = (t: TFunction<string[]>, entityType: FlowEntity, bag: ProgressRecordBag) => {
  const bagHintsData: BagHintData[] = [];

  const bagHintsMap: BagHintsMap = {
    isDelayed: getDelayedHintData(t, entityType, bag.availableAt),
    isBranched: getBranchedHintData(t),
  };
  const bagHintProperties = Object.keys(bagHintsMap) as BagHintProperty[];

  bagHintProperties.forEach(bagHintProperty => {
    if (bag[bagHintProperty]) {
      bagHintsData.push(bagHintsMap[bagHintProperty]);
    }
  });

  return bagHintsData.filter(({ isSkipped }) => !isSkipped);
};
