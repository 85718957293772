import { call, put, takeLatest } from "redux-saga/effects";
import { req, got, err, restricted } from "./eventSlice";
import { fetchEvent } from "../../services/eventsService";
import { Event } from "../../types";
import HttpCodes from "../../../types/HttpCodes";
import { HttpResponse } from "../../../Application/http/http";

function* handleResult(event: HttpResponse<Event>) {
  if (event.isSuccess) {
    yield put(got({ current: { ...event.data! } }));
  } else if (event.code === HttpCodes.Forbidden || event.code === HttpCodes.NotFound) {
    yield put(restricted());
  } else {
    throw new Error(event.message);
  }
}

export function* handleFetchEvent(action: ReturnType<typeof req>) {
  try {
    const event: HttpResponse<Event> = yield call(fetchEvent, action.payload.id);
    yield* handleResult(event);
  } catch (error: any) {
    yield put(err({ errorMessage: error?.message }));
  }
}

export function* eventWatcherSaga() {
  yield takeLatest(req.type, handleFetchEvent);
}
