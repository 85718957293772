import { useTranslation } from "react-i18next";
import { PageHeader } from "../../../../Application/layout/PageHeader/PageHeader";

import styles from "./Header.module.scss";

interface Props {
  controls?: React.ReactNode;
}

export const Header: React.FC<Props> = ({ controls }) => {
  const { t } = useTranslation(["discover"]);
  return (
    <PageHeader noPadding>
      <div className={styles.header}>
        <h1 className={styles.headerText}>{t("common.discover")}</h1>
        <div className={styles.controls}>{controls}</div>
      </div>
    </PageHeader>
  );
};
