import { call, put, takeLatest } from "redux-saga/effects";
import { HomeItem, HomePageContentItem } from "../../types";

import { req, got, err, ItemsByPriorityMap } from "./homePageContentSlice";
import { HomeItemsV4Response, fetchHomeItemsV4 } from "../../services/homePageDataService";
import { mapCompletionStatus } from "../../../common/mapperServices/mapCompletionStatus";
import { PriorityLevel } from "../../../Discover/types";

const mapItems = (items: HomeItemsV4Response): ItemsByPriorityMap => {
  const itemMapper = (item: HomeItem): HomePageContentItem => {
    return {
      id: parseInt(item.id),
      title: item.title,
      entityType: item.entityType,
      priority: item.priority,
      applications: item.applications,
      dueDate: item.dueDate,
      thumbnailUrl: item.bag?.thumbnailUrl,
      description: item.description,
      completionStatus: mapCompletionStatus(item),
      expired: item.expired,
    };
  };

  return [PriorityLevel.Normal, PriorityLevel.Important, PriorityLevel.Required].reduce(
    (obj, level) => ({
      [level]: items[level]?.map(itemMapper) ?? [],
      ...obj,
    }),
    {} as ItemsByPriorityMap,
  );
};

export function* handleFetch() {
  try {
    const homeItems: HomeItemsV4Response = yield call(fetchHomeItemsV4);
    const mappedResultItems = mapItems(homeItems);

    yield put(got(mappedResultItems));
  } catch (error: any) {
    yield put(err(error?.message));
  }
}

export function* handleFetchHomePageContent() {
  yield call(handleFetch);
}

export function* homePageContentWatcherSaga() {
  yield takeLatest(req.type, handleFetchHomePageContent);
}
