import classNames from "classnames/bind";
import { isUndefined } from "lodash";
import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { CardMeta, Icon } from "semantic-ui-react";
import { TeamsLink } from "../../../Application/components/TeamsLink/TeamsLink";
import { TextTruncator } from "../../../Application/layout/TextTruncator/TextTruncator";
import { PriorityLevel } from "../../../Discover/types";
import { CompletionStatus } from "../../../Flows/types/enums";
import { toItemLink } from "../../services/homePageMapperService";
import { HomePageContentItem } from "../../types";
import { ContentIcon } from "../ContentIcon/ContentIcon";

import styles from "./CategoryItem.module.scss";
import { CardPriority } from "features/Application/components/Card/CardPriority/CardPriority";
import { CardProgress } from "features/Application/components/Card/CardProgress/CardProgress";

export interface Props {
  item: HomePageContentItem;
  completionStatus?: CompletionStatus;
}

export const CategoryItem: React.FC<Props> = ({ item, completionStatus }) => {
  const location = useLocation();
  const linkTo = useMemo(() => toItemLink(item.id, item.entityType), [item]);
  const cx = classNames.bind(styles);

  const softwareApplications = useMemo(() => item.applications ?? [], [item.applications]);
  const meta = useMemo(() => [softwareApplications.join(" • ")], [softwareApplications]);

  return (
    <div className={styles.root}>
      {item.expired && (
        <div className={styles.expiredWarning}>
          <div className={styles.expiredWarningTitle}>
            <Icon className="exclamation triangle" />
            <p>Content Unavailable</p>
          </div>
          <div className={styles.expiredWarningDescription}>
            <p>Contact your administrator to restore your access to the content in this Pack.</p>
          </div>
        </div>
      )}
      <div
        className={cx(styles.content, {
          [styles.expiredBody]: item.expired,
        })}
      >
        <div className={styles.header}>
          <ContentIcon entityType={item.entityType} />
          {item.dueDate && <CardPriority level={PriorityLevel.Required} dueDate={item.dueDate} />}
        </div>
        {item.expired ? (
          <TextTruncator tooltipContent={item.title} clamp={1} aria-label={item.title} tabIndex={0}>
            <div className={styles.title}>{item.title}</div>
          </TextTruncator>
        ) : (
          <TeamsLink to={linkTo} state={location.state} accessibleName={item.title}>
            <TextTruncator tooltipContent={item.title} clamp={1} aria-label={item.title} tabIndex={0}>
              <div className={styles.title}>{item.title}</div>
            </TextTruncator>
          </TeamsLink>
        )}
        <div className={styles.meta}>
          <CardMeta>
            {meta.map(m => (
              <TextTruncator
                key={item.id}
                tooltipContent={m.toLocaleUpperCase()}
                clamp={1}
                aria-label={m.toLocaleUpperCase()}
                tabIndex={0}
              >
                {m.toLocaleUpperCase()}
              </TextTruncator>
            ))}
          </CardMeta>
        </div>
        {!isUndefined(completionStatus) && (
          <CardProgress
            contentType={item.entityType}
            completionStatus={completionStatus}
            className={styles.progress}
            wideBars
          />
        )}
      </div>
    </div>
  );
};
