import dayjs from "dayjs";
import * as dateUtils from "../../Application/utils/date";
import { PeriodicRule } from "../types";

enum DayOfWeekNumber {
  First = "first",
  Second = "second",
  Third = "third",
  Fourth = "fourth",
}

const dayOfWeeks = [
  DayOfWeekNumber.First,
  DayOfWeekNumber.Second,
  DayOfWeekNumber.Third,
  DayOfWeekNumber.Fourth,
  // the elements of this array are taken by week number,
  // but the week number can equal 4 for dates greater than 28
  DayOfWeekNumber.Fourth,
];
const weekMonthlyMap = {
  [DayOfWeekNumber.First]: PeriodicRule.MonthlyFirstDayOfWeekInMonth,
  [DayOfWeekNumber.Second]: PeriodicRule.MonthlySecondDayOfWeekInMonth,
  [DayOfWeekNumber.Third]: PeriodicRule.MonthlyThirdDayOfWeekInMonth,
  [DayOfWeekNumber.Fourth]: PeriodicRule.MonthlyFourthDayOfWeekInMonth,
};
const weekYearlyMap = {
  [DayOfWeekNumber.First]: PeriodicRule.YearlyFirstDayOfWeekInMonth,
  [DayOfWeekNumber.Second]: PeriodicRule.YearlySecondDayOfWeekInMonth,
  [DayOfWeekNumber.Third]: PeriodicRule.YearlyThirdDayOfWeekInMonth,
  [DayOfWeekNumber.Fourth]: PeriodicRule.YearlyFourthDayOfWeekInMonth,
};

const eventSessionUtils = {
  convertPeriodicRuleToString: (date: string, rule: PeriodicRule): string => {
    const dayOfWeekNumber = dayOfWeeks[dateUtils.getWeekNumber(date)];
    const weekDay = dateUtils.getWeekDayName(date);
    const month = dateUtils.getMonthName(date);
    const day = dayjs(date).date();

    switch (rule) {
      // Monthly
      case PeriodicRule.MonthlyOnExactDayInMonth:
        return `on day ${day}`;
      case PeriodicRule.MonthlyLastDayOfWeekInMonth:
        return `on the last ${weekDay}`;
      case weekMonthlyMap[dayOfWeekNumber]:
        return `on the ${dayOfWeekNumber} ${weekDay}`;

      // Yearly
      case PeriodicRule.YearlyOnExactDayAndMonth:
        return `on ${month} ${day}`;
      case PeriodicRule.YearlyLastDayOfWeekInMonth:
        return `on the last ${weekDay} of ${month}`;
      case weekYearlyMap[dayOfWeekNumber]:
        return `on the ${dayOfWeekNumber} ${weekDay} of ${month}`;

      default:
        return "";
    }
  },
};

export default eventSessionUtils;
