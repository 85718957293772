import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { Dropdown as SemanticDropdown, DropdownItemProps, DropdownProps, Icon } from "semantic-ui-react";
import { InvisibleButton } from "../../../Application/layout/InvisibleButton/InvisibleButton";
import { Flyout } from "../../../Discover/layout/Flyout/Flyout";

import classNames from "classnames/bind";

import styles from "./CustomDropdown.module.scss";

const cx = classNames.bind(styles);

export interface Props {
  isMobile: boolean;
  isVisible: boolean;
  options: DropdownItemProps[];
  defaultValue?: any;
  toggleVisible: () => void;
  onChange?: (newValue: string) => void;
}

export const CustomDropdown: React.FC<Props> = ({
  isMobile,
  isVisible,
  options,
  defaultValue,
  toggleVisible,
  onChange,
}) => {
  const { t } = useTranslation(["discover"]);
  const [dropdownSelection, setDropdownSelection] = useState<string>(defaultValue ?? (options[0].value as string));

  const handleDropdownChange = useCallback(
    (_: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
      const value = data.value as string;
      setDropdownSelection(value);
      onChange?.(value);
    },
    [onChange],
  );

  const handleOptionSelect = (value: string) => {
    setDropdownSelection(value);
    onChange?.(value);
    toggleVisible();
  };

  if (isMobile) {
    return (
      <Flyout height={30} open={isVisible} absoluteSize={false}>
        <div className={styles.mobileSortbySection} data-testid="mobile sort">
          <div className={styles.sortByHeader}>
            <span tabIndex={0}>
              <span className={cx(styles.iconColor, styles.pushed)}>
                <Icon name="sort amount down" />
              </span>
              {t("sort.label")}
            </span>
            <span className={styles.headerIconContainer}>
              <InvisibleButton onClick={toggleVisible} ariaLabel="Close Sort">
                <span className={styles.iconColor} data-testid="dropdown close button">
                  <Icon className={cx("thin", styles.heavy)} name="times" fitted size="large" />
                </span>
              </InvisibleButton>
            </span>
          </div>
          <div role="listbox" title="Sort">
            {options.map(option => (
              <div
                className={cx(styles.option, {
                  [styles.selected]: dropdownSelection === option.value,
                })}
                key={`Dropdown option ${option.value}`}
                onClick={() => handleOptionSelect(option.value as string)}
                role="option"
                aria-selected={dropdownSelection === option.value ? "true" : "false"}
                tabIndex={0}
                onKeyUp={(e: React.KeyboardEvent) => {
                  if (e.key === "Enter") handleOptionSelect(option.value as string);
                }}
              >
                {option.text}{" "}
                {dropdownSelection === option.value ? (
                  <span className={styles.iconContainer}>
                    <Icon name="check" />
                  </span>
                ) : null}
              </div>
            ))}
          </div>
        </div>
      </Flyout>
    );
  } else {
    return (
      <SemanticDropdown
        selection
        compact
        aria-label="Sort by options"
        value={dropdownSelection}
        options={options}
        onChange={handleDropdownChange}
      />
    );
  }
};

export default React.memo(CustomDropdown);
