import { UserManager, UserManagerSettings, WebStorageStateStore } from "oidc-client-ts";
import environmentConfig from "../../../environmentConfig";

const host = `${window.location.protocol}//${window.location.host}`;

const settings: UserManagerSettings = {
  client_id: environmentConfig.stsClientId,
  redirect_uri: `${host}/auth-callback`,
  post_logout_redirect_uri: `${host}/signout-callback`,
  response_type: "code",
  scope: "openid profile account gateway.fullAccess",
  authority: environmentConfig.stsUrl,
  automaticSilentRenew: true,
  silent_redirect_uri: `${host}/silent-renew.html`,
  filterProtocolClaims: true,
  loadUserInfo: true,
  monitorSession: true,
  checkSessionIntervalInSeconds: 15,
  userStore: new WebStorageStateStore({ store: window.localStorage }),
};

export const userManager = new UserManager(settings);

export const accessTokenFactory = async () => {
  const user = await userManager.getUser();
  return user?.access_token;
};
