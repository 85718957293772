import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames/bind";
import styles from "./Message.module.scss";
import { Color, Button } from "../../layout/Button/Button";
import { faTriangleExclamation } from "@fortawesome/pro-solid-svg-icons";

const cx = classNames.bind(styles);

type Type = "error";

const icons: { [key in Type]: JSX.Element } = {
  error: <FontAwesomeIcon icon={faTriangleExclamation} />,
};

const buttons: { [key in Type]: Color } = {
  error: "red",
};

interface Props {
  message: React.ReactNode;
  type: Type;
  action?: {
    text: string;
    onClick: () => void;
  };
}

export const Message = (props: Props) => {
  const { message, type, action } = props;
  return (
    <div className={cx(styles.root, type)}>
      <div className={cx(styles.stripe, type)} />
      <div className={cx(styles.content)}>
        <div className={cx(styles.icon, type)}>{icons[type]}</div>
        <div className={styles.message}>{message}</div>
        {action && (
          <Button className={styles.action} onClick={action.onClick} basic color={buttons[type]}>
            <div className={styles["action_text"]}>{action.text}</div>
          </Button>
        )}
      </div>
    </div>
  );
};
