import { SortDirection } from "../types";

export const getParams = (skip?: number, take?: number) => {
  const params = new URLSearchParams();
  if (skip) {
    params.append("skip", skip.toString());
  }
  if (take) {
    params.append("top", take.toString());
  }
  return params;
};

export const addSortParams = (params: URLSearchParams, orderBy: string, sortDirection: SortDirection) => {
  params.append("orderBy", `${orderBy}+${sortDirection}`);
  return params;
};
