import React, { memo, useContext } from "react";

import styles from "./FiltersToggler.module.scss";
import { Icon } from "semantic-ui-react";
import { BreakpointContext } from "../../../Application/components/context/BreakpointContext";
import { useTranslation } from "react-i18next";
import { FILTERS_TOGGLER_ID } from "./contants";

interface Props {
  filtersCount: number;
  toggleFilters: () => void;
}

export const FiltersToggler = (props: Props) => {
  const { filtersCount, toggleFilters } = props;
  const { t } = useTranslation(["discover"]);
  const breakpoint = useContext(BreakpointContext);

  return (
    <div className={styles.filterButtonContainer}>
      <button
        className={styles.filterButton}
        id={FILTERS_TOGGLER_ID}
        onClick={toggleFilters}
        aria-labelledby="Mobile filter header"
      >
        <Icon name="filter" />
        {breakpoint.isMobileScreen && filtersCount > 0 && (
          <div className={styles.filterCountContainer} data-testid="filter count">
            <label className={styles.filterCount}>{filtersCount}</label>
          </div>
        )}
        <label className={styles.filterLabel} id="Mobile filter header">
          {t("common.filterResultsMobile")}
        </label>
      </button>
    </div>
  );
};

export default memo(FiltersToggler);
