import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../Application/store/store";
import { RStatus } from "../../../Application/types";
import { VideoPreviewAsset } from "../../types";

interface AssetState {
  hasAccess?: boolean;
  status: RStatus;
  current?: VideoPreviewAsset | null;
  errorMessage: string | undefined;
}

const initialState: AssetState = {
  hasAccess: true,
  status: RStatus.Idle,
  current: undefined,
  errorMessage: undefined,
};

const assetSlice = createSlice({
  name: "asset",
  initialState: initialState,
  reducers: {
    req(state, _: PayloadAction<{ id: number }>) {
      return { ...state, status: RStatus.Pending, hasAccess: true };
    },
    got(state, action: PayloadAction<{ current: AssetState["current"] }>) {
      return {
        ...state,
        status: RStatus.Got,
        current: action.payload.current,
      };
    },
    err(state, action: PayloadAction<{ errorMessage: AssetState["errorMessage"] }>) {
      return {
        ...state,
        status: RStatus.Error,
        errorMessage: action.payload.errorMessage,
      };
    },
    restricted(state) {
      state.hasAccess = false;
      state.status = RStatus.Got;
    },
    reset() {
      return initialState;
    },
  },
});

// actions
export const { req, got, err, restricted, reset } = assetSlice.actions;

// selectors
export const selectAsset = (state: RootState) => state.assets.asset.current;
export const selectAssetStatus = (state: RootState) => state.assets.asset.status;
export const selectAssetError = (state: RootState) => state.assets.asset.errorMessage;
export const hasAccessSelector = (state: RootState) => state.assets.asset.hasAccess;
// reducers
export default assetSlice.reducer;
