import { combineReducers, configureStore } from "@reduxjs/toolkit";
import homeUpcomingEvents from "features/Home/redux/homePageContent/homeUpcomingEventsSlice";
import { debounce } from "lodash";
import createSagaMiddleware from "redux-saga";
import accountReducer from "../../Account/redux/account/accountSlice";
import mainContactReducer from "../../Account/redux/mainContact/mainContactSlice";
import myDataReducer from "../../Account/redux/myData/myDataSlice";
import profileReducer from "../../Account/redux/profile/profileSlice";
import asyncOperationSlice from "../../Application/slices/asyncOperationSlice";
import assessmentSlice from "../../Assets/redux/assessment/assessmentSlice";
import assetSlice from "../../Assets/redux/asset/assetSlice";
import pdfSlice from "../../Assets/redux/pdf/pdfSlice";
import surveySlice from "../../Assets/redux/survey/surveySlice";
import publishersReducer from "../../Discover/redux/publishers/publishersSlice";
import searchResultsReducer from "../../Discover/redux/searchResults/searchResultsSlice";
import eventSlice from "../../Events/redux/event/eventSlice";
import redirectSlice from "../../Events/redux/event/redirectSlice";
import sessionsSlice from "../../Events/redux/sessions/sessionsSlice";
import flowReducer from "../../Flows/redux/flow/flowSlice";
import groupReducer from "../../Groups/redux/group/groupSlice";
import groupsReducer from "../../Groups/redux/groups/groupsSlice";
import homePageContentReducer from "../../Home/redux/homePageContent/homePageContentSlice";
import bannerReducer from "../redux/banner/bannerSlice";
import { saveState } from "../services/localStorageService";
import appConfigReducer from "../slices/appConfigSlice";
import signalRReducer from "../slices/signalRSlice";
import rootSaga from "./rootSaga";

const assetsFeatureReducer = combineReducers({
  asset: assetSlice,
  assessment: assessmentSlice,
  survey: surveySlice,
  pdf: pdfSlice,
});

const flowsFeatureReducer = combineReducers({
  flow: flowReducer,
  // add reducers here
});

const discoverFeatureReducer = combineReducers({
  publishers: publishersReducer,
  searchResults: searchResultsReducer,
  // add reducers here
});

const groupsFeatureReducer = combineReducers({
  group: groupReducer,
  groups: groupsReducer,
  // add reducers here
});

const eventsFeatureReducer = combineReducers({
  event: eventSlice,
  sessions: sessionsSlice,
  redirect: redirectSlice,
});

const accountFeatureReducer = combineReducers({
  mainContact: mainContactReducer,
  profile: profileReducer,
  account: accountReducer,
  myData: myDataReducer,
});

const homePageFeatureReducer = combineReducers({
  homePageContent: homePageContentReducer,
  homeUpcomingEvents: homeUpcomingEvents,
});

const applicationFeatureReducer = combineReducers({
  banner: bannerReducer,
});

export const reducer = combineReducers({
  // Leave this comment for hygen inserting feature
  assets: assetsFeatureReducer,
  // home: homeFeatureReducer,
  // video: videoFeatureReducer,
  groups: groupsFeatureReducer,
  events: eventsFeatureReducer,
  discover: discoverFeatureReducer,
  account: accountFeatureReducer,
  flows: flowsFeatureReducer,
  homePage: homePageFeatureReducer,
  asyncOperations: asyncOperationSlice,
  application: applicationFeatureReducer,
  signalR: signalRReducer,
  appConfig: appConfigReducer,
});

export const setupStore = (preloadedState?: DeepPartial<RootState>) => {
  const sagaMiddleware = createSagaMiddleware();

  return {
    store: configureStore({
      reducer: reducer,
      preloadedState: preloadedState as any,
      middleware: getDefaultMiddleware => {
        return getDefaultMiddleware({
          thunk: false,
          serializableCheck: {
            ignoredActions: ["profile/setImage", "flow/got", "flow/finishProgressUpdate"],
            ignoredPaths: ["account.profile", "flows.flow.current.progress.timestamp"],
          },
        }).concat(sagaMiddleware);
      },
    }),
    saga: sagaMiddleware,
  };
};

export const { store, saga } = setupStore();

saga.run(rootSaga);

store.subscribe(
  debounce(() => {
    const account = store.getState()?.account;
    if (account.profile.current && account?.myData?.current?.requestedTime) {
      saveState(
        `BSI_GDPR_REQUESTED_TIME_FOR_${account.profile.current?.firstName}_${account.profile.current?.lastName}`,
        account?.myData?.current?.requestedTime,
      );
    }
  }, 250),
);

export type RootState = ReturnType<typeof reducer>;
export type AppDispatch = typeof store.dispatch;
