import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../Application/store/store";
import { RStatus } from "../../../Application/types";
import { PdfAssetStandAloneDetails } from "../../types";

interface PdfState {
  hasAccess?: boolean;
  status: RStatus;
  current?: PdfAssetStandAloneDetails;
  errorMessage?: string | null;
}

const initialState: PdfState = {
  hasAccess: true,
  status: RStatus.Idle,
  current: undefined,
  errorMessage: undefined,
};
const name = "pdf";

const pdfSlice = createSlice({
  name,
  initialState,
  reducers: {
    req(state, _: PayloadAction<{ id: number }>) {
      return { ...state, status: RStatus.Pending, hasAccess: true };
    },
    got(state, action: PayloadAction<{ current: PdfState["current"] }>) {
      return {
        ...state,
        status: RStatus.Got,
        current: action.payload.current,
      };
    },
    err(state, action: PayloadAction<{ errorMessage: PdfState["errorMessage"] }>) {
      return {
        ...state,
        status: RStatus.Error,
        errorMessage: action.payload.errorMessage,
      };
    },
    completePdf(
      state,
      _: PayloadAction<{
        assetId: number;
      }>,
    ) {
      return state;
    },
    restricted(state) {
      state.hasAccess = false;
      state.status = RStatus.Got;
    },
    reset() {
      return initialState;
    },
  },
});

// actions
export const { req, got, err, completePdf, restricted, reset } = {
  ...pdfSlice.actions,
};

// selectors
export const selectPdf = (state: RootState) => state.assets.pdf.current;
export const selectPdfStatus = (state: RootState) => state.assets.pdf.status;
export const selectPdfError = (state: RootState) => state.assets.pdf.errorMessage;
export const hasAccessSelector = (state: RootState) => state.assets.pdf.hasAccess;

// reducers
export default pdfSlice.reducer;
