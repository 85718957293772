import { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

/**
 * Runs a function if the URL no longer contains the specified page name
 * @param callback The function to run.
 * @param page The page(s) that the function should NOT run on. Leave empty if the function should run on any page change.
 */
export const usePageChange = (callback: () => void, page?: string | string[]) => {
  const location = useLocation();
  const [currentPage, setCurrentPage] = useState(location.pathname);

  const pageChangeHandler = useCallback(() => {
    if (Array.isArray(page) || typeof page === "string") {
      let foundMatch = false;
      let pages = Array.isArray(page) ? page : [page];
      for (let p of pages) {
        if (location.pathname.includes(p)) {
          foundMatch = true;
          break;
        }
      }

      if (!foundMatch) callback();
    }
    // If no page provided
    else if (location.pathname !== currentPage) {
      setCurrentPage(location.pathname);
      callback();
    }
  }, [location, page, currentPage, callback]);

  useEffect(() => {
    pageChangeHandler();
    // Ensures that this function runs on unmounting components
    return () => pageChangeHandler();
  }, [pageChangeHandler]);
};
