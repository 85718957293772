import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { userInTeams } from "../../../../tools/teams/teamsService";
import { AccountCompany } from "../AccountCompany/AccountCompany";
import styles from "./AccountMenu.module.scss";
import { AccountMenuDropdown } from "./AccountMenuDropdown/AccountMenuDropdown";
import { AccountMenuItem } from "./AccountMenuItem/AccountMenuItem";

interface Props {
  onSignout: () => void;
}

export const AccountMenu: React.FC<Props> = ({ onSignout }) => {
  const { t } = useTranslation(["account"]);
  const navigate = useNavigate();
  const [opened, setOpened] = useState(false);
  const showLanguagePage: boolean = process.env.NODE_ENV !== "production";

  const handleNavigateToProfile = useCallback(() => {
    navigate("/profile");
    setOpened(false);
  }, [navigate]);

  const handleNavigateToLang = useCallback(() => {
    navigate("/lang");
    setOpened(false);
  }, [navigate]);

  const handleSignOut = useCallback(() => {
    onSignout();
  }, [onSignout]);

  return (
    <div className={styles.root}>
      <AccountMenuDropdown opened={opened} setOpened={setOpened}>
        <div className={styles.menu}>
          <section className={styles.company}>
            <AccountCompany />
          </section>
          {!userInTeams() && (
            <section className={styles.menufirst}>
              <AccountMenuItem
                active
                iconClass="user circle"
                onClick={handleNavigateToProfile}
                text={t("menu.myProfile")}
              />

              {showLanguagePage && (
                <AccountMenuItem
                  active
                  iconClass="language"
                  onClick={handleNavigateToLang}
                  text={t("menu.switchLang")}
                />
              )}
              {/* NOTE: Put settings link item here */}
            </section>
          )}
          <section className={styles.menusecond}>
            <AccountMenuItem active iconClass="sign out" onClick={handleSignOut} text={t("menu.logOut")} />
          </section>
        </div>
      </AccountMenuDropdown>
    </div>
  );
};
