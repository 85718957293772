import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { AssessmentFooter, AssessmentViewer } from "..";
import { Button } from "../../../Application/layout";
import {
  completeAssessmentAsset,
  resetEvaluatedAssessment,
  selectEvaluatedAssessment,
} from "../../redux/assessment/assessmentSlice";
import { AssessmentAsset as Assessment, QuestionsAnswers } from "../../types";

import { markAssessmentAsStartedV4 } from "../../services/assetsService";
import styles from "./AssessmentAsset.module.scss";

interface Props {
  assessment: Assessment;
}

export const AssessmentAsset: React.FC<Props> = ({ assessment }) => {
  const { t } = useTranslation("assets");
  const dispatch = useDispatch();
  const evaluatedAssessment = useSelector(selectEvaluatedAssessment);

  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [answers, setAnswers] = useState<QuestionsAnswers>({});

  useEffect(() => {
    markAssessmentAsStartedV4(assessment.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const nextQuestion = useCallback(() => {
    setCurrentQuestion(prev => prev + 1);
  }, []);

  const onCompleteAssessment = useCallback(
    (data: QuestionsAnswers) => {
      dispatch(
        completeAssessmentAsset({
          assetId: assessment.id,
          data: data,
        }),
      );
    },
    [assessment.id, dispatch],
  );

  const onRetakeAssessment = useCallback(() => {
    setCurrentQuestion(0);
    setAnswers({});
    dispatch(resetEvaluatedAssessment());
  }, [dispatch]);

  return (
    <div className={styles.root}>
      <AssessmentViewer
        assessment={assessment}
        currentQuestion={currentQuestion}
        onNextQuestion={nextQuestion}
        answers={answers}
        updateAnswers={setAnswers}
        complete={onCompleteAssessment}
        evaluatedAssessment={evaluatedAssessment}
      >
        <AssessmentFooter>
          <Button onClick={onRetakeAssessment}>{t("assessments.retake")}</Button>
        </AssessmentFooter>
      </AssessmentViewer>
    </div>
  );
};
