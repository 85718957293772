import classNames from "classnames/bind";
import { useCallback, useMemo, useState } from "react";
import { Icon, Placeholder, PlaceholderImage } from "semantic-ui-react";
import { InvisibleButton } from "../../../layout/InvisibleButton/InvisibleButton";
import { HoverableLink } from "../../../../Flows/components/HoverableLink/HoverableLink";
import styles from "./CardImage.module.scss";

const cx = classNames.bind(styles);

export interface Props {
  url: string | null;
  children?: React.ReactNode;
  alt: string;
  fixedRatio?: boolean;
  // Does the card have a fixed height?
  setHeight?: boolean;
  /* Navigation link */
  link?: {
    to: string;
    ariaLabel: string;
  };
  /* Action to perform on user select. If link is set, action will be ignored. */
  action?: () => void;
  hideAccessible?: boolean;
  playable?: boolean;
  className?: string;
  interactive?: boolean;
  loading?: boolean;
}

export const CardImage: React.FC<Props> = ({
  url,
  children,
  alt,
  link,
  action,
  fixedRatio,
  setHeight,
  hideAccessible,
  playable,
  className,
  loading,
  interactive = true,
}) => {
  const [hovering, setHovering] = useState(false);

  const playIcon = useMemo(() => {
    if (playable) {
      return <Icon className={cx(styles.playIcon, { [styles.active]: hovering })} name="play" inverted size="huge" />;
    }
    return null;
  }, [playable, hovering]);

  const containerClassname = cx(styles.imageContainer, className, {
    [styles.fixedRatio]: fixedRatio,
    [styles.setHeight]: setHeight,
  });

  const isHovering = useCallback(() => {
    setHovering(true);
  }, []);

  const isNotHovering = useCallback(() => {
    setHovering(false);
  }, []);

  const body = loading ? (
    <Placeholder style={{ height: "100%" }}>
      <PlaceholderImage />
    </Placeholder>
  ) : (
    <>
      {url && <img className={styles.image} src={url} alt={alt} />}
      {interactive && link && (
        <HoverableLink to={link.to} ariaLabel={link.ariaLabel} onMouseEnter={isHovering} onMouseLeave={isNotHovering}>
          <>
            <div
              className={cx(styles.overlay, {
                [styles.focused]: hovering && playIcon !== null,
              })}
            />
            {playIcon}
          </>
        </HoverableLink>
      )}
      {interactive && !link && action && (
        <div onMouseEnter={() => setHovering(true)} onMouseLeave={() => setHovering(false)}>
          <InvisibleButton onClick={action} disabled={hideAccessible} ariaLabel={alt}>
            <>
              <div
                className={cx(styles.overlay, {
                  [styles.focused]: hovering && playIcon !== null,
                })}
              />
              {playIcon}
            </>
          </InvisibleButton>
        </div>
      )}
      {children}
    </>
  );

  return <div className={containerClassname}>{body}</div>;
};
