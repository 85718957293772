const userAgentContains = (value: string): boolean => window.navigator.userAgent.includes(value);

const initialState = {
  isUserInTeams: window.name === "extension-tab-frame" || userAgentContains("Teams") || userAgentContains("Electron"),
  isUserInTeamsWeb: window.name === "embedded-page-container",
  urlQueryParams: "utm_source=API&utm_medium=Teams&utm_campaign=App",
};

export const userInTeams = () => initialState.isUserInTeams || initialState.isUserInTeamsWeb;

export default initialState;
