import { z } from "zod";

import { groupSchema, memberSchema } from "./schemas";

export type Group = z.infer<typeof groupSchema>;

export type GroupMember = z.infer<typeof memberSchema>;

export enum SortType {
  NameAz = "nameAz",
  NameZa = "nameZa",
  CreationDate = "creationDate",
  Popularity = "popularity",
}

export type GroupActionSuccess = {
  OperationId: string;
  MessageId: string;
  GroupId: number;
  UserId: number;
};
