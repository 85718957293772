import { Message } from "semantic-ui-react";

export interface Props {
  message?: string | null;
}

export const AppErrorView: React.FC<Props> = ({ message }) => {
  return (
    <Message color="red" data-testid="error">
      {message}
    </Message>
  );
};
