import { call, put, takeLatest, select } from "redux-saga/effects";

import { req, got, err, updateSort, GroupsState, selectGroupsState, updateGroupInfo, replace } from "./groupsSlice";
import { Group } from "../../types";
import { fetchGroups, getGroup } from "../../services/groupsService";
import { CustomHttpHeaders } from "../../../Application/types/enums";

const PAGE_SIZE = 21;
export const UPDATE_ERROR_MESSAGE = "Error updating group info.";

export function* handleFetchGroups() {
  const state: GroupsState = yield select(selectGroupsState);
  const { items, sortType } = state;
  try {
    const [groups, headers]: [Group[], any] = yield call(fetchGroups, sortType, items.length, PAGE_SIZE);
    yield put(
      got({
        items: groups,
        count: parseInt(headers[CustomHttpHeaders.recordsCount]),
      }),
    );
  } catch (error: any) {
    yield put(err({ errorMessage: error?.message }));
  }
}

export function* handleFetchGroupInfo(action: ReturnType<typeof updateGroupInfo>) {
  const state: GroupsState = yield select(selectGroupsState);
  const { items: groups } = state;
  try {
    const updatedGroupInfo: Group = yield call(getGroup, action.payload.groupId);
    let newGroups = groups.slice();
    const indexOfFoundGroup = groups.findIndex(g => g.id === action.payload.groupId);
    // Group not found, append to groups
    if (indexOfFoundGroup === -1) {
      newGroups.push(updatedGroupInfo);
    } else {
      newGroups[indexOfFoundGroup] = updatedGroupInfo;
    }
    yield put(
      replace({
        items: newGroups,
        count: newGroups.length,
      }),
    );
  } catch (error: any) {
    yield put(err({ errorMessage: UPDATE_ERROR_MESSAGE }));
  }
}

export function* groupsWatcherSaga() {
  yield takeLatest(req.type, handleFetchGroups);
  yield takeLatest(updateSort.type, handleFetchGroups);
  yield takeLatest(updateGroupInfo.type, handleFetchGroupInfo);
}
