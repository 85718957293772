import { FC, ReactNode, useCallback } from "react";
import { Link, LinkProps, useResolvedPath } from "react-router-dom";
import { Icon } from "semantic-ui-react";
import teamsService, { userInTeams } from "../../../../tools/teams/teamsService";
import { InvisibleButton } from "../../layout";

import styles from "./TeamsLink.module.scss";

interface Props extends LinkProps {
  accessibleName: string;
  children?: ReactNode;
}

export const TeamsLink: FC<Props> = ({ accessibleName, children, to, ...linkProps }) => {
  const resolver = useResolvedPath(to);

  const openInNewWindow = useCallback(() => {
    const url = new URL(window.envtConfig.baseUrl + resolver.pathname + "?" + teamsService.urlQueryParams);
    window.open(url);
  }, [resolver.pathname]);

  if (userInTeams()) {
    return (
      <InvisibleButton ariaLabel={accessibleName} onClick={openInNewWindow}>
        <span className={styles.linkTitle}>
          <Icon name="external" />
          {children}
        </span>
      </InvisibleButton>
    );
  }
  return (
    <Link to={to} {...linkProps}>
      {children}
    </Link>
  );
};
