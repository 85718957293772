import classNames from "classnames/bind";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import styles from "./Button.module.scss";

const cx = classNames.bind(styles);

export type Color = "red";

export interface Props {
  children?: ReactNode;
  onClick?: () => void;
  "data-testid"?: string;
  disabled?: boolean;
  fullWidth?: boolean;
  secondaryButton?: boolean;
  loading?: boolean;
  className?: string;
  color?: Color;
  basic?: boolean;
}

export const Button: React.FC<Props> = props => {
  const { onClick, children, disabled, fullWidth, secondaryButton, loading, className, color, basic } = props;
  const { t } = useTranslation("application");
  return (
    <button
      data-testid={props["data-testid"]}
      className={cx(styles.button, className, color, { fullWidth, secondaryButton, loading, basic })}
      onClick={onClick}
      disabled={disabled}
    >
      {loading ? t("common.loading") : children}
    </button>
  );
};
