import { datadogRum } from "@datadog/browser-rum";
import { AuthProvider, User } from "oidc-react";
import { useCallback, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { accessTokenFactory, userManager } from "../../config/userManager";
import authService from "../../services/authService";
import { AccountRedirect } from "./AccountRedirect";
import { LoggedInApp } from "./LoggedInApp";
import { useSignInParams } from "./useSignInParams";

authService.registerAccessTokenFactory(accessTokenFactory);

export const AppAuth = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [autoSignInArgs, signInParamsInitialized] = useSignInParams();

  const onSignIn = useCallback(
    (user: User | null) => {
      let redirectLocation = "/";

      if (user) {
        redirectLocation = user.state as string;
        const { sub: id, OriginAccountId: accountId, name: email } = user.profile;
        datadogRum.setUser({ id, accountId, email });
      }

      navigate(redirectLocation, { replace: true });
    },
    [navigate],
  );

  const onBeforeSignIn = useCallback(() => location, [location]);

  useEffect(() => {
    const redirectToSignIn = async () => {
      await userManager.removeUser();
      await userManager.signinRedirect({ state: location });
    };

    userManager.events.addUserSignedOut(redirectToSignIn);

    return () => {
      userManager.events.removeUserSignedOut(redirectToSignIn);
    };
  }, [location]);

  if (signInParamsInitialized) {
    return (
      <AuthProvider
        userManager={userManager}
        // @ts-ignore
        onBeforeSignIn={onBeforeSignIn}
        onSignIn={onSignIn}
        // @ts-ignore
        autoSignInArgs={autoSignInArgs}
      >
        <AccountRedirect>
          <LoggedInApp />
        </AccountRedirect>
      </AuthProvider>
    );
  }

  return null;
};
