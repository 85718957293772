import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import dayjs from "dayjs";
import React, { ReactElement, useEffect, useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Button, InvisibleButton } from "../../../Application/layout";

import { useDispatch, useSelector } from "react-redux";
import { RStatus } from "../../../Application/types";
import { init, req } from "../../redux/myData/myDataSlice";
import { selectMyData, selectMyDataStatus } from "features/Account/redux/myData/selectors";
import { selectCurrentProfile } from "../../redux/profile/profileSlice";
import { getRemainingMinutes } from "../../services/myData.service";
import { DeleteMyDataModal } from "./DeleteMyDataModal/DeleteMyDataModal";
import styles from "./MyData.module.scss";
import { faDownload, faTriangleExclamation } from "@fortawesome/pro-solid-svg-icons";

interface Props {}

export const MyData: React.FC<Props> = () => {
  const { t } = useTranslation("account");
  const dispatch = useDispatch();
  const [modalOpen, setModalOpen] = useState(false);

  const myData = useSelector(selectMyData);
  const status = useSelector(selectMyDataStatus);
  const profile = useSelector(selectCurrentProfile);

  const buttonDisabled = useMemo(() => {
    return !profile || status === RStatus.Pending || Number(getRemainingMinutes(myData?.requestedTime)) > 0;
  }, [myData, profile, status]);

  const buttonLoading = useMemo(() => {
    return !profile || status === RStatus.Pending;
  }, [profile, status]);

  useEffect(() => {
    dispatch(init({ profile }));
  }, [dispatch, profile]);

  const requestMyData = () => {
    dispatch(req());
  };

  const makeDownloadFileName = (): string => {
    return `${t("myData.myDataTitle")} ${dayjs(myData?.requestedTime).format("MM/DD/YYYY hh:mm A")}.xlsx`;
  };

  const makeDownloadButtonLabel = (): ReactElement => {
    return (
      <>
        <FontAwesomeIcon icon={faDownload} />
        &nbsp;
        {makeDownloadFileName()}
      </>
    );
  };

  const makeRequestButtonLabel = (): string => {
    if (myData?.requestedTime) {
      const remaining = getRemainingMinutes(myData?.requestedTime);

      if (remaining) {
        return `${t("myData.requestAgain", {
          remaining,
        })}`;
      }
    }

    return t("myData.requestButtonTitle");
  };

  const makeErrorText = (): ReactElement => {
    return (
      <>
        <FontAwesomeIcon icon={faTriangleExclamation} />
        &nbsp;
        <em>{t("myData.failedText")}</em>
      </>
    );
  };

  const download = () => {
    if (myData) {
      const link = document.createElement("a");
      link.href = myData.gdprLink!;
      link.download = makeDownloadFileName();
      link.click();
    }
  };

  return (
    <div className={styles.root}>
      <div>
        <h3 className={styles.heading}>{t("myData.privacyTitle")}</h3>
        <p className={styles.privacyText}>
          <Trans t={t} i18nKey="myData.privacyDescription">
            As outlined in our{" "}
            <a href="https://legal.brainstorminc.com/#privacy-policy" target="_blank" rel="noreferrer">
              Terms & Conditions
            </a>
            , BrainStorm collects personal data to personalize your learning, develop reports for your BrainStorm
            administrator(s), make enhancements to the platform, etc.
          </Trans>
        </p>
      </div>

      <div>
        <h3 className={styles.heading}>{t("myData.requestDataTitle")}</h3>
        <p className={styles.requestDataText}>{t("myData.requestDataDescription")}</p>

        <Button
          data-testid="gdpr-request-button"
          className={styles.requestDataButton}
          onClick={requestMyData}
          disabled={buttonDisabled}
          loading={buttonLoading}
        >
          {makeRequestButtonLabel()}
        </Button>

        {status === RStatus.Error && (
          <p data-testid="gdpr-request-error" className={styles.error}>
            {makeErrorText()}
          </p>
        )}

        {status === RStatus.Got && myData?.gdprLink && (
          <InvisibleButton
            data-testid="gdpr-request-link"
            className={styles.downloadLink}
            onClick={download}
            ariaLabel="Download"
          >
            {makeDownloadButtonLabel()}
          </InvisibleButton>
        )}
      </div>

      <div>
        <h3 className={styles.heading}>{t("myData.requestDeleteHeading")}</h3>
        <p className={styles.requestDataText}>{t("myData.requestDeleteDescription")}</p>
        <Button className={styles.requestDataButton} onClick={() => setModalOpen(o => !o)}>
          {t("myData.requestDeleteButton")}
        </Button>
      </div>

      <DeleteMyDataModal open={modalOpen} setOpen={setModalOpen} />
    </div>
  );
};
