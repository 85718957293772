import { isString } from "lodash";
import qs from "qs";

export const serializeArrayAndFilterNullable = <T>(params: T) =>
  qs.stringify(params, {
    indices: false,
    filter: (_, value) => {
      if (isString(value)) {
        return value.length > 0 ? value : undefined;
      }
      return value ?? undefined;
    },
    format: "RFC1738",
  });
