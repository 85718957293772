import React from "react";
import styles from "./InvisibleButton.module.scss";

interface Props {
  ariaLabel?: string;
  "data-testid"?: string;
  tabIndex?: number;
  children?: React.ReactNode;
  onClick?: () => void;
  disabled?: boolean;
  className?: string;
}

export const InvisibleButton = React.forwardRef<HTMLButtonElement, Props>((props, ref) => {
  const { ariaLabel, children, onClick, disabled, tabIndex, className = "" } = props;

  return (
    <button
      aria-label={ariaLabel}
      data-testid={props["data-testid"]}
      className={`${styles.root} ${className}`}
      onClick={onClick}
      disabled={disabled}
      ref={ref}
      tabIndex={tabIndex}
    >
      {children}
    </button>
  );
});
