import { useMemo } from "react";
import { RStatus } from "../../Application/types";
import { ContentState } from "../redux/homePageContent/homePageContentSlice";
import { HomePageContentItem } from "../types";

type Result = [HomePageContentItem[], boolean, boolean, boolean, (string | undefined)[]];

export const useHomeItems = (state: ContentState): Result => {
  const { status, items, errorMessage } = state;

  const mappedItems = useMemo(
    () =>
      Object.entries(items)
        .map(item => item[1])
        .flat(1),
    [items],
  );

  const noItems = status === RStatus.Got && !mappedItems.length;

  const isLoading = status === RStatus.Pending;

  const isLoaded = status === RStatus.Got;

  const errors = status === RStatus.Error ? [errorMessage] : [];

  return [mappedItems, isLoading, isLoaded, noItems, errors];
};
