import { Icon, SemanticICONS } from "semantic-ui-react";
import classNames from "classnames/bind";
import styles from "./AccountMenuItem.module.scss";

const cx = classNames.bind(styles);

interface Props {
  iconClass: SemanticICONS | string;
  text: string;
  onClick: () => void;
  active?: boolean;
}

export const AccountMenuItem: React.FC<Props> = ({ text, iconClass, onClick, active }) => {
  return (
    <button
      className={cx(styles.root, {
        [styles.active]: active,
      })}
      onClick={onClick}
    >
      <span className={styles.iconWrapper}>
        <Icon className={iconClass} />
      </span>
      <span className={styles.text}>{text}</span>
    </button>
  );
};
