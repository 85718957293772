import { useState, useCallback, useContext } from "react";
import { BreakpointContext } from "../../../Application/components/context/BreakpointContext";

export const useSortController = () => {
  const [showSort, setShowSort] = useState(false);
  const breakpoint = useContext(BreakpointContext);

  const toggleSort = useCallback(() => {
    setShowSort(!showSort);
  }, [showSort]);

  return { showSort, setShowSort, toggleSort, isMobile: breakpoint.isMobileScreen };
};
